import Web3Modal from 'web3modal';

export const web3Modal =
  typeof window !== 'undefined'
    ? new Web3Modal({
        network: 'mainnet',
        cacheProvider: true, // optional
        providerOptions: {}, // required
      })
    : undefined;

export const disconnectWalletEthereum = async () => {
  if (web3Modal) {
    await web3Modal.clearCachedProvider();
  }
};
