import { get, isFunction, template as t } from 'lodash';
import { Enum_Marketplace_Enum } from '../graphql/nfts.generated';

export const getEllipsisTxt = (str: string | undefined, n = 6) => {
  if (str) {
    return `${str.slice(0, n)}...${str.slice(str.length - n)}`;
  }
  return '';
};

const chainMarketplaceMap = {
  [Enum_Marketplace_Enum.Opensea]: 'ethereum',
  [Enum_Marketplace_Enum.Foundation]: 'ethereum',
  [Enum_Marketplace_Enum.Objkt]: 'tezos',
  [Enum_Marketplace_Enum.Magiceden]: 'solana',
};

export const getChainName = (marketplace: string) =>
  get(chainMarketplaceMap, marketplace, 'ethereum');

const marketplaceTokenURLMap = {
  opensea: t('https://opensea.io/assets/ethereum/${token_address}/${token_id}'),
  foundation: t(
    'https://foundation.app/collection/${token_address}/${token_id}'
  ),
  objkt: t('https://objkt.com/asset/${token_address}/${token_id}'),
  magiceden: t('https://magiceden.io/item-details/${token_address}'),
};

export const getNFTUrlForMarketplace = (
  marketplace: string,
  params: object
) => {
  const compiled = marketplaceTokenURLMap[marketplace];
  if (isFunction(compiled)) {
    return compiled(params);
  } else {
    return '';
  }
};
