import { sendBuyNow } from '@f8n/foundationkit-core';
import { message, Steps, Typography } from 'antd';
import { ethers } from 'ethers';
import { Dispatch, SetStateAction, useState } from 'react';
import { NFT } from '../../../../../types';
import { NFTCheckoutStatusEnum } from '../../../../constants/nft.constant';
import { createSeaPort } from '../../../../helpers/opensea.helper';
import {
  disconnectWalletEthereum,
  web3Modal,
} from '../../../../helpers/web3-modal.helper';
import { PopUp } from '../../../utilities/pop-up';
import { Enum_Marketplace_Enum } from '../../user-nfts.generated';
import { NFTCheckout } from './nft-checkout';
import { NFTCheckoutStatus } from './nft-checkout-status';
import { NFTDetailsView } from './nft-details-view';
import { NFTErrorLogsView } from './nft-error-logs-view';
import { NFTTransactionView } from './nft-transaction-view';
export interface NFTBuyPopUpProps {
  showBuyNftPopUp: boolean;
  setShowBuyNftPopUp: Dispatch<SetStateAction<boolean>>;
  nft: NFT;
}

export function NFTBuyPopUp({
  setShowBuyNftPopUp,
  showBuyNftPopUp,
  nft,
}: NFTBuyPopUpProps) {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [status, setStatus] = useState<NFTCheckoutStatusEnum>(
    NFTCheckoutStatusEnum.PENDING
  );
  const [showTransaction, setShowTransaction] = useState<boolean>(false);
  const [transactionId, setTransactionId] = useState<string>();
  const [showErrorLogs, setShowErrorLogs] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [buyersAddress, setBuyersAddress] = useState<string>();

  const resetState = () => {
    setShowBuyNftPopUp(false);
    setCurrentStep(0);
    setLoading(true);
    setShowTransaction(false);
    setShowErrorLogs(false);
    setStatus(NFTCheckoutStatusEnum.PENDING);
    setTransactionId(undefined);
    setErrorMessage('');
    setBuyersAddress(undefined);
  };
  const onBuyClick = async () => {
    await disconnectWalletEthereum();
    const instance = await web3Modal?.connect();
    const web3Provider = new ethers.providers.Web3Provider(instance);
    const web3Signer = web3Provider.getSigner();
    const account = web3Provider.provider['selectedAddress'];
    setBuyersAddress(account);
    setCurrentStep(2);
    if (nft?.marketplace === Enum_Marketplace_Enum.Opensea) {
      const orderVersion = nft?.order_type;
      try {
        const seaport = createSeaPort(web3Provider);
        if (orderVersion === 'OrderV2') {
          const orderV2 = await seaport.api.getOrder({
            assetContractAddress: nft?.token_address as string,
            tokenIds: [nft?.token_id as string],
            side: 'ask',
            protocol: 'seaport',
          });
          const transactionHash = await seaport.fulfillOrder({
            order: orderV2,
            accountAddress: account as string,
          });
          setLoading(false);
          setStatus(NFTCheckoutStatusEnum.SUCCESS);
          setTransactionId(transactionHash);
        } else {
          message.info(
            'This NFT is listed for sale using the deprecated Opensea Smart Contract. Please contact the owner to list the NFT using the updated Opensea Smart Contract.'
          );
        }
      } catch (err) {
        setLoading(false);
        setStatus(NFTCheckoutStatusEnum.FAILED);
        console.error(err);
        setErrorMessage(
          typeof err === 'string'
            ? err.toString()
            : (err as DOMException).message
        );
      }
    } else if (nft?.marketplace === Enum_Marketplace_Enum.Foundation) {
      try {
        const transaction = await sendBuyNow({
          signer: web3Signer,
          contractAddress: nft.token_address as string,
          tokenId: parseInt(nft.token_id as string),
          referrer: '0x23fe4f3595823d23f6a20bf992ac59a5b21ea22b',
        });
        setTransactionId(transaction.hash);
        setLoading(false);
        setStatus(NFTCheckoutStatusEnum.SUCCESS);
      } catch (err) {
        console.error(err);
        setErrorMessage(
          typeof err === 'string'
            ? err.toString()
            : (err as DOMException).message
        );
        setLoading(false);
        setStatus(NFTCheckoutStatusEnum.FAILED);
      }
    }
  };

  const commonProps = {
    title: (
      <Typography.Title className="text-center" level={4}>
        {showTransaction
          ? 'View Transaction'
          : showErrorLogs
          ? 'View Error Logs'
          : 'Buy NFT'}
      </Typography.Title>
    ),
    maskClosable: false,
    destroyOnClose: true,
    footer: null,
    visible: showBuyNftPopUp,
  };

  return (
    <PopUp
      modalProps={{
        ...commonProps,
        width: 700,
        onCancel: resetState,
      }}
      drawerProps={{
        ...commonProps,
        onClose: resetState,
        placement: 'bottom',
        height: '100%',
      }}
    >
      {!showTransaction && !showErrorLogs && (
        <>
          <Steps
            current={currentStep}
            onChange={(value: number) => setCurrentStep(value)}
            type="navigation"
            responsive
          >
            <Steps.Step title="View NFT Details" disabled />
            <Steps.Step title="Checkout" disabled />
            <Steps.Step
              title="Completed"
              disabled
              status={
                status === NFTCheckoutStatusEnum.FAILED
                  ? 'error'
                  : status === NFTCheckoutStatusEnum.SUCCESS
                  ? 'finish'
                  : status === NFTCheckoutStatusEnum.PENDING &&
                    currentStep === 2
                  ? 'process'
                  : 'wait'
              }
            />
          </Steps>
          {currentStep === 0 && (
            <NFTDetailsView setCurrentStep={setCurrentStep} nft={nft} />
          )}
          {currentStep === 1 && (
            <NFTCheckout onBuyClick={onBuyClick} nft={nft} />
          )}
          {currentStep === 2 && (
            <NFTCheckoutStatus
              loading={loading}
              status={status}
              closeModal={resetState}
              setShowTransaction={setShowTransaction}
              setShowErrorLogs={setShowErrorLogs}
              nft={nft}
              buyersAddress={buyersAddress}
              transactionId={transactionId}
            />
          )}
        </>
      )}
      {showTransaction && (
        <NFTTransactionView
          closeModal={resetState}
          nft={nft}
          transactionId={transactionId}
        />
      )}
      {showErrorLogs && <NFTErrorLogsView errorMessage={errorMessage} />}
    </PopUp>
  );
}
