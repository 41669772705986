import { toPairs } from 'lodash';
import {
  ArtstationIcon,
  BehanceIcon,
  BookmarkIcon,
  BrowserPodcastsIcon,
  ChatRoomIcon,
  DiscordIcon,
  FacebookIcon,
  FigmaIcon,
  FiverrIcon,
  GithubIcon,
  GooglePlusIcon,
  HomeIcon,
  InstagramIcon,
  LineIcon,
  LinkedinIcon,
  MailIcon,
  MySpaceIcon,
  NotionIcon,
  PinterestIcon,
  RedditIcon,
  SnapChatIcon,
  SoundCloudIcon,
  SpotifyIcon,
  StumbleUponIcon,
  TelegramIcon,
  TiktokIcon,
  TinderIcon,
  TumblrIcon,
  TwitchIcon,
  TwitterIcon,
  WeChatIcon,
  WhatsappIcon,
  YoutubeIcon,
} from '../components/icons';

export const socialMediaIconsObject = {
  bookmark: BookmarkIcon,
  'browser podcasts': BrowserPodcastsIcon,
  chatroom: ChatRoomIcon,
  fiverr: FiverrIcon,
  home: HomeIcon,
  mail: MailIcon,
  notion: NotionIcon,
  'sound cloud': SoundCloudIcon,
  tumblr: TumblrIcon,
  twitter: TwitterIcon,
  discord: DiscordIcon,
  telegram: TelegramIcon,
  facebook: FacebookIcon,
  tiktok: TiktokIcon,
  youtube: YoutubeIcon,
  twitch: TwitchIcon,
  instagram: InstagramIcon,
  whatsapp: WhatsappIcon,
  snapchat: SnapChatIcon,
  linkedin: LinkedinIcon,
  pinterest: PinterestIcon,
  behance: BehanceIcon,
  artstation: ArtstationIcon,
  spotify: SpotifyIcon,
  github: GithubIcon,
  'google plus': GooglePlusIcon,
  myspace: MySpaceIcon,
  reddit: RedditIcon,
  StumbleUpon: StumbleUponIcon,
  tinder: TinderIcon,
  wechat: WeChatIcon,
  line: LineIcon,
  figma: FigmaIcon,
};

export const socialMediaIcons = toPairs(socialMediaIconsObject).map(
  ([name, component]) => ({
    name,
    component,
  })
);

export const AvatarImageFallback = '/assets/profile.png';
