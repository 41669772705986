import { Enum_Blockchain_Name_Enum } from '../generated/main-sdk';

export const ChainImageMap = {
  [Enum_Blockchain_Name_Enum.Ethereum]:
    'assets/chain-icons/ethereum-eth-logo.svg',
  [Enum_Blockchain_Name_Enum.Tezos]: 'assets/chain-icons/tezos-xtz-logo.svg',
  [Enum_Blockchain_Name_Enum.Solana]: 'assets/chain-icons/solana-sol-logo.svg',
};

export enum NFTCheckoutStatusEnum {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export const findChain = (marketPlace: string | null | undefined) => {
  if (
    marketPlace?.toLowerCase() === 'opensea' ||
    marketPlace?.toLowerCase() === 'foundation'
  ) {
    return Enum_Blockchain_Name_Enum.Ethereum;
  } else if (marketPlace?.toLowerCase() === 'objkt') {
    return Enum_Blockchain_Name_Enum.Tezos;
  }
};
