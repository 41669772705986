import { Typography } from 'antd';

export interface NFTErrorLogsViewProps {
  errorMessage: string | undefined;
}

export function NFTErrorLogsView({ errorMessage }: NFTErrorLogsViewProps) {
  return (
    <div className="flex flex-col items-center px-5 pt-5 gap-6">
      <Typography.Title level={4} className="mb-0">
        Your transactions failed to process due to the following error:
      </Typography.Title>
      <div className="w-3/4">
        <Typography.Text className="mb-0" type="danger">
          <strong>{errorMessage}</strong>
        </Typography.Text>
      </div>
    </div>
  );
}
