export enum MarketPlaceEnum {
  Foundation = 'FOUNDATION',
  Magiceden = 'MAGICEDEN',
  Objkt = 'OBJKT',
  Opensea = 'OPENSEA',
}

export enum ChainIdEnum {
  mainnet = '0x1',
  eth = '0x1',
  ropsten = '0x3',
  rinkeby = '0x4',
  kovan = '0x2a',
  goerli = '0x5',
  local = '0x539',
  avax = '0xa86a',
  smart = '0x38',
}

export enum SaleKindEnum {
  Auction = 'auction',
  Buy = 'buy',
  Offer = 'offer',
  Sell = 'sell',
  None = 'none',
}
