import { Drawer, DrawerProps, Grid, Modal, ModalProps } from 'antd';
import { ReactNode } from 'react';

interface PopUpProps {
  modalProps?: ModalProps;
  drawerProps?: DrawerProps;
  children: ReactNode;
}
const { useBreakpoint } = Grid;
export function PopUp({ drawerProps, modalProps, children }: PopUpProps) {
  const { md } = useBreakpoint();
  return md ? (
    <Modal {...modalProps}>{children}</Modal>
  ) : (
    <Drawer {...drawerProps}>{children}</Drawer>
  );
}
