import { Button, Result, Spin, Typography } from 'antd';
import { useRouter } from 'next/router';
import { Dispatch, SetStateAction, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { NFT } from '../../../../../types';
import { NFTCheckoutStatusEnum } from '../../../../constants/nft.constant';
import { useStore } from '../../../../contexts/store.context';
import { useStoreName } from '../../../../hooks/store-name.hook';

/* eslint-disable-next-line */
export interface NFTCheckoutStatusProps {
  loading: boolean;
  status: NFTCheckoutStatusEnum;
  closeModal: () => void;
  setShowTransaction: Dispatch<SetStateAction<boolean>>;
  setShowErrorLogs: Dispatch<SetStateAction<boolean>>;
  nft: NFT;
  transactionId: string | undefined;
  buyersAddress: string | undefined;
}

export function NFTCheckoutStatus({
  loading,
  status,
  closeModal,
  setShowTransaction,
  setShowErrorLogs,
  nft,
  transactionId,
  buyersAddress,
}: NFTCheckoutStatusProps) {
  const store = useStoreName();

  const { usdPrice } = useStore();
  const { push } = useRouter();

  useEffect(() => {
    // console.log('status', status);
    // console.log('nft', nft);
    if (
      !loading &&
      status === NFTCheckoutStatusEnum.SUCCESS
      // ||status === NFTCheckoutStatusEnum.FAILED)
    ) {
      const price =
        parseFloat(nft?.current_price as string) *
        (usdPrice?.ethereum?.usd as number);

      ReactGA.gtag('event', 'purchase', {
        transaction_id: transactionId,
        affiliation: nft?.marketplace,
        value: price,
        currency: 'USD',
        tax: 0,
        shipping: 0,
        coupon: '',
        // ownerAddress: nft?.owner_address?.split('0x')[1],
        // buyerAddress: buyersAddress?.split('0x')[1],
        // contractAddress: String(nft?.token_address?.split('0x')[1]),
        // item_id: nft?.token_id,
        items: [
          {
            item_id: nft?.token_id,
            item_name: nft?.name,
            affiliation: nft?.marketplace,
            // coupon: '',
            currency: 'USD',
            discount: 0,
            index: 0,
            // item_brand: '',
            item_category: nft?.owner_address?.split('0x')[1],
            item_category2: buyersAddress?.split('0x')[1],
            item_category3: String(nft?.token_address?.split('0x')[1]),
            // item_category4: '',
            // item_category5: '',
            // item_list_id: '',
            // item_list_name: '',
            // item_variant: '',
            // location_id: '',
            price: price,
            quantity: 1,

            // status: status,
            // ownerAddress: nft?.owner_address?.split('0x')[1],
            // buyerAddress: buyersAddress?.split('0x')[1],
            // contractAddress: String(nft?.token_address?.split('0x')[1]),
            // price: nft?.current_price,
            // price_USD:
            //   parseFloat(nft?.current_price as string) *
            //   (usdPrice?.ethereum?.usd as number),
            // type: 'Buy now',
          },
        ],
      });
    }
  }, [status, nft, loading, usdPrice, transactionId, buyersAddress]);

  return (
    <div className="flex flex-col items-center px-5 pt-5 gap-4 w-full">
      {loading && (
        <div className="flex flex-col gap-2 justify-center items-center w-full h-96">
          <Spin />
          <Typography.Text
            className="text-base text-center text-gray-400"
            strong
          >
            Please wait while we process your request
          </Typography.Text>
        </div>
      )}
      {status === NFTCheckoutStatusEnum.SUCCESS && (
        <Result
          status="success"
          title="Successfully Purchased NFT"
          extra={[
            <Button
              type="primary"
              key={`shopping-cart-checkout-${nft?.token_id}`}
              className="rounded-md"
              size="large"
              onClick={() => setShowTransaction(true)}
            >
              View Transaction
            </Button>,
            <Button
              type="primary"
              key={`shopping-button-checkout-${nft?.token_id}`}
              className="rounded-md"
              size="large"
              onClick={() => {
                closeModal();
                push(`/${store}`);
              }}
            >
              Continue Shopping
            </Button>,
          ]}
        />
      )}
      {status === NFTCheckoutStatusEnum.FAILED && (
        <Result
          status="error"
          title="Failed to Purchased NFT"
          extra={[
            <Button
              type="primary"
              key={`shopping-cart-error-${nft?.token_id}`}
              className="rounded-md"
              size="large"
              onClick={() => {
                setShowErrorLogs(true);
              }}
            >
              View Error Logs
            </Button>,
            <Button
              type="primary"
              key={`shopping-button-error-${nft?.token_id}`}
              className="rounded-md"
              size="large"
              onClick={() => {
                closeModal();
                push(`/${store}`);
              }}
            >
              Close
            </Button>,
          ]}
        />
      )}
    </div>
  );
}
