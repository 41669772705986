import { Button, Divider, Typography } from 'antd';
import ReactGA from 'react-ga4';
import { APP_ENVIRONMENT } from '../../../../../config';
import { NFT } from '../../../../../types';
import { useStore } from '../../../../contexts/store.context';
import { EthereumLogo } from '../../../icons/chain-icons';
import { NFTViewer } from '../../nft-viewer';

export interface NFTCheckoutProps {
  onBuyClick: () => Promise<void>;
  nft: NFT;
}

export function NFTCheckout({ onBuyClick, nft }: NFTCheckoutProps) {
  const { usdPrice } = useStore();
  const price = parseInt(nft?.current_price as string) / 1e18;
  const handleFakeBuy = () => {
    // console.log('Clicked');
    ReactGA.gtag('event', 'purchase', {
      transaction_id: '1232',
      affiliation: nft?.marketplace,
      value: price,
      currency: 'USD',
      tax: 0,
      shipping: 0,
      // coupon: '',
      // ownerAddress: nft?.owner_address?.split('0x')[1],
      // buyerAddress: buyersAddress?.split('0x')[1],
      // contractAddress: String(nft?.token_address?.split('0x')[1]),
      // item_id: nft?.token_id,
      items: [
        {
          item_id: nft?.token_id,
          item_name: nft?.name,
          affiliation: nft?.marketplace,
          // coupon: '',
          currency: 'USD',
          discount: 0,
          index: 0,
          // item_brand: '',
          item_category: nft?.owner_address?.split('0x')[1],
          item_category2: 'buyersAddress',
          item_category3: String(nft?.token_address?.split('0x')[1]),
          // item_category4: '',
          // item_category5: '',
          // item_list_id: '',
          // item_list_name: '',
          // item_variant: '',
          // location_id: '',
          price: price,
          quantity: 1,

          // status: status,
          // ownerAddress: nft?.owner_address?.split('0x')[1],
          // buyerAddress: buyersAddress?.split('0x')[1],
          // contractAddress: String(nft?.token_address?.split('0x')[1]),
          // price: nft?.current_price,
          // price_USD:
          //   parseFloat(nft?.current_price as string) *
          //   (usdPrice?.ethereum?.usd as number),
          // type: 'Buy now',
        },
      ],
    });
  };
  return (
    <div className="flex flex-col items-center px-5 pt-5 gap-4 w-full">
      <div className="flex justify-between items-center w-full">
        <Typography.Text className="text-base font-medium mb-0">
          Item
        </Typography.Text>
        <Typography.Text className="text-base font-medium mb-0">
          Sub Total
        </Typography.Text>
      </div>
      <Divider orientation="center" orientationMargin={5} className="m-0" />
      <div className="flex justify-between items-center w-full">
        <NFTViewer nft={nft} width={96} height={96} />
        <div className="flex flex-col items-start justify-center text-left">
          {/* <Typography.Text className="font-medium">Artist Name</Typography.Text> */}
          <Typography.Text className="font-medium">{nft.name}</Typography.Text>
          <Typography.Text>Creator Fee: 5%</Typography.Text>
        </div>
        <div className="flex flex-col items-center justify-center">
          <div className="flex justify-center items-center gap-2">
            <EthereumLogo className="w-3" />
            <Typography.Text className="font-medium">
              {nft.current_price || price} ( $
              {String((usdPrice?.ethereum?.usd as number) * price).slice(0, 5)})
            </Typography.Text>
          </div>
          <Typography.Text>price in ETH</Typography.Text>
        </div>
      </div>
      <Divider orientation="center" orientationMargin={5} className="m-0" />
      <div className="flex justify-between items-center w-full">
        <Typography.Text className="text-base font-medium mb-0">
          Total
        </Typography.Text>
        <div className="flex flex-col items-center justify-center">
          <div className="flex justify-center items-center gap-2">
            <EthereumLogo className="w-3" />
            <Typography.Text className="font-medium">
              {nft.current_price || price} ( $
              {String((usdPrice?.ethereum?.usd as number) * price).slice(0, 5)})
            </Typography.Text>
          </div>
          <Typography.Text>price in ETH</Typography.Text>
        </div>
      </div>
      <div className="w-full">
        <Button
          type="primary"
          size="large"
          className="rounded-md float-right w-24"
          onClick={onBuyClick}
        >
          Buy
        </Button>
        {APP_ENVIRONMENT !== 'production' && (
          <Button onClick={handleFakeBuy}>Fake Buy</Button>
        )}
      </div>
    </div>
  );
}
