import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import Link from 'next/link';
import { NFT } from '../../../../../types';
import { NFTViewer } from '../../nft-viewer';

export interface NFTTransactionViewProps {
  closeModal: () => void;
  nft: NFT;
  transactionId: string | undefined;
}

export function NFTTransactionView({
  closeModal,
  nft,
  transactionId,
}: NFTTransactionViewProps) {
  return (
    <div className="flex flex-col items-center px-5 pt-5 gap-6">
      <Typography.Title level={2} className="mb-0">
        <span role="img" aria-label="party_popper">
          🎉
        </span>{' '}
        Congratulation you have bought
      </Typography.Title>
      <NFTViewer nft={nft} width={286} height={300} />
      <Typography.Title level={4} className="mb-0">
        {nft.name}
      </Typography.Title>
      <div className="flex flex-col justify-center items-start">
        <div className="flex break-all justify-start">
          <div className="w-[126px]">
            <Typography.Text className="mb-0">
              <strong>Status:</strong>{' '}
            </Typography.Text>
          </div>
          <Typography.Text className="mb-0 text-left" strong type="success">
            <CheckCircleOutlined /> Completed
          </Typography.Text>
        </div>
        <div className="flex break-all">
          <div className="w-48">
            <Typography.Text className="mb-0">
              <strong>Transaction Hash:</strong>{' '}
            </Typography.Text>
          </div>
          <div>
            <a
              href={`https://etherscan.io/tx/${transactionId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {transactionId}
            </a>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-4">
        <Button className="rounded-md" onClick={closeModal}>
          Close
        </Button>
        <Link href={'http://hoji.xyz'} passHref>
          <Button
            className="rounded-md"
            type="primary"
            typeof="a"
            target={'_blank'}
            rel={'noopener noreferrer'}
          >
            Lets create your own storefront
          </Button>
        </Link>
      </div>
    </div>
  );
}
