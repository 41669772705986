import { CSSProperties } from 'react';

interface EthereumLogoProps {
  className?: string;
  style?: CSSProperties;
}
export function EthereumLogo({ style, className }: EthereumLogoProps) {
  return (
    <svg
      style={style}
      className={className}
      height="30"
      viewBox="0 0 20 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_434_450)">
        <path
          d="M9.20791 0L9.00684 0.683454V20.5157L9.20791 20.7164L18.4138 15.2748L9.20791 0Z"
          fill="#6FCED6"
        />
        <path
          d="M9.20688 0L0.000976562 15.2748L9.20688 20.7165V11.0905V0Z"
          fill="#6FCED6"
        />
        <path
          d="M9.20706 22.4594L9.09375 22.5975V29.6622L9.20706 29.9931L18.4185 17.0205L9.20706 22.4594Z"
          fill="#6FCED6"
        />
        <path
          d="M9.20688 29.9932V22.4594L0.000976562 17.0205L9.20688 29.9932Z"
          fill="#6FCED6"
        />
        <path
          d="M9.20703 20.7167L18.4128 15.2752L9.20703 11.0908V20.7167Z"
          fill="#6FCED6"
        />
        <path
          d="M0.000976562 15.2751L9.20673 20.7167V11.0908L0.000976562 15.2751Z"
          fill="#6FCED6"
        />
      </g>
      <defs>
        <clipPath id="clip0_434_450">
          <rect width="18.42" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
