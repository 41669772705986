import axios from 'axios';
import { Network, OpenSeaSDK } from 'opensea-js';
import { OPENSEA_API_KEY } from '../../config';
import { OpenSeaOrdersResponse } from '../../types';
import { createAPIClient } from './api-client.helper';

export const createSeaPort = (web3Provider) => {
  return new OpenSeaSDK(web3Provider.provider, {
    networkName: Network.Main,
    apiKey: OPENSEA_API_KEY,
  });
};

export const createOpenSeaClientV1 = () =>
  axios.create({
    baseURL: 'https://api.opensea.io/api/v1',
    headers: {
      'X-API-KEY': OPENSEA_API_KEY,
      Accept: 'application/json',
    },
  });

export const createOpenSeaClientV2 = () =>
  axios.create({
    baseURL: 'https://api.opensea.io/v2/',
    headers: {
      'X-API-KEY': OPENSEA_API_KEY,
      Accept: 'application/json',
    },
  });

export const createOpenSeaSDK = () => {
  const apiClient = createAPIClient();
  return {
    getOffers: async (args: {
      token_address: string;
      token_id: string;
      cursor?: string;
    }) => {
      const offersUrl = `opensea/${args.token_address}/${args.token_id}/offers`;
      return apiClient
        .get<OpenSeaOrdersResponse>(offersUrl, {
          params: {
            cursor: args.cursor,
          },
        })
        .then((res) => res.data)
        .catch((e) => {
          console.error(e);
          return {
            next: args.cursor,
            previous: null,
            orders: [],
          } as OpenSeaOrdersResponse;
        });
    },
  };
};
