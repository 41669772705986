import * as Types from '../../generated/base-types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  jsonb: any;
  timestamptz: any;
  uuid: any;
};

export type Boolean_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _cast?: InputMaybe<Boolean_Cast_Exp>;
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type ConnectChainInput = {
  account_address: Scalars['String'];
  account_id: Scalars['uuid'];
  signature: Scalars['String'];
};

export type ConnectChainOutput = {
  __typename?: 'ConnectChainOutput';
  account_id: Scalars['String'];
  account_provider_id: Scalars['String'];
};

export type GetNftsInput = {
  username: Scalars['String'];
};

export type GetNftsOutput = {
  __typename?: 'GetNftsOutput';
  status?: Maybe<Scalars['Boolean']>;
};

export type GetNonceInput = {
  account_address: Scalars['String'];
  auth_provider: Scalars['String'];
  public_key: Scalars['String'];
};

export type GetNonceOutput = {
  __typename?: 'GetNonceOutput';
  nonce: Scalars['String'];
};

export type RefreshCreatedNftInput = {
  username: Scalars['String'];
};

export type RefreshCreatedNftOutput = {
  __typename?: 'RefreshCreatedNFTOutput';
  status?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type VerifySignatureInput = {
  account_address: Scalars['String'];
  signature: Scalars['String'];
};

export type VerifySignatureOutput = {
  __typename?: 'VerifySignatureOutput';
  account_id: Scalars['String'];
  jwt_token: Scalars['String'];
  new_account: Scalars['Boolean'];
};

/** columns and relationships of "account_providers" */
export type Account_Providers = {
  __typename?: 'account_providers';
  /** An object relationship */
  account?: Maybe<Accounts>;
  account_id?: Maybe<Scalars['uuid']>;
  auth_provider: Enum_Providers_Enum;
  auth_provider_unique_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  metadata?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  provider: Enum_Providers;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  wallet_providers: Array<Wallet_Providers>;
  /** An aggregate relationship */
  wallet_providers_aggregate: Wallet_Providers_Aggregate;
};

/** columns and relationships of "account_providers" */
export type Account_ProvidersMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "account_providers" */
export type Account_ProvidersWallet_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Providers_Order_By>>;
  where?: InputMaybe<Wallet_Providers_Bool_Exp>;
};

/** columns and relationships of "account_providers" */
export type Account_ProvidersWallet_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Providers_Order_By>>;
  where?: InputMaybe<Wallet_Providers_Bool_Exp>;
};

/** aggregated selection of "account_providers" */
export type Account_Providers_Aggregate = {
  __typename?: 'account_providers_aggregate';
  aggregate?: Maybe<Account_Providers_Aggregate_Fields>;
  nodes: Array<Account_Providers>;
};

/** aggregate fields of "account_providers" */
export type Account_Providers_Aggregate_Fields = {
  __typename?: 'account_providers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Account_Providers_Max_Fields>;
  min?: Maybe<Account_Providers_Min_Fields>;
};

/** aggregate fields of "account_providers" */
export type Account_Providers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Account_Providers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "account_providers" */
export type Account_Providers_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Account_Providers_Max_Order_By>;
  min?: InputMaybe<Account_Providers_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Account_Providers_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "account_providers" */
export type Account_Providers_Arr_Rel_Insert_Input = {
  data: Array<Account_Providers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Account_Providers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "account_providers". All fields are combined with a logical 'AND'. */
export type Account_Providers_Bool_Exp = {
  _and?: InputMaybe<Array<Account_Providers_Bool_Exp>>;
  _not?: InputMaybe<Account_Providers_Bool_Exp>;
  _or?: InputMaybe<Array<Account_Providers_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  account_id?: InputMaybe<Uuid_Comparison_Exp>;
  auth_provider?: InputMaybe<Enum_Providers_Enum_Comparison_Exp>;
  auth_provider_unique_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  provider?: InputMaybe<Enum_Providers_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  wallet_providers?: InputMaybe<Wallet_Providers_Bool_Exp>;
};

/** unique or primary key constraints on table "account_providers" */
export enum Account_Providers_Constraint {
  /** unique or primary key constraint on columns "auth_provider_unique_id" */
  AccountProvidersAuthProviderUniqueIdKey = 'account_providers_auth_provider_unique_id_key',
  /** unique or primary key constraint on columns "id" */
  AccountProvidersPkey = 'account_providers_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Account_Providers_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Account_Providers_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Account_Providers_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "account_providers" */
export type Account_Providers_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  account_id?: InputMaybe<Scalars['uuid']>;
  auth_provider?: InputMaybe<Enum_Providers_Enum>;
  auth_provider_unique_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  provider?: InputMaybe<Enum_Providers_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  wallet_providers?: InputMaybe<Wallet_Providers_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Account_Providers_Max_Fields = {
  __typename?: 'account_providers_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  auth_provider_unique_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "account_providers" */
export type Account_Providers_Max_Order_By = {
  account_id?: InputMaybe<Order_By>;
  auth_provider_unique_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Account_Providers_Min_Fields = {
  __typename?: 'account_providers_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  auth_provider_unique_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "account_providers" */
export type Account_Providers_Min_Order_By = {
  account_id?: InputMaybe<Order_By>;
  auth_provider_unique_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "account_providers" */
export type Account_Providers_Mutation_Response = {
  __typename?: 'account_providers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Account_Providers>;
};

/** input type for inserting object relation for remote table "account_providers" */
export type Account_Providers_Obj_Rel_Insert_Input = {
  data: Account_Providers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Account_Providers_On_Conflict>;
};

/** on_conflict condition type for table "account_providers" */
export type Account_Providers_On_Conflict = {
  constraint: Account_Providers_Constraint;
  update_columns?: Array<Account_Providers_Update_Column>;
  where?: InputMaybe<Account_Providers_Bool_Exp>;
};

/** Ordering options when selecting data from "account_providers". */
export type Account_Providers_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  account_id?: InputMaybe<Order_By>;
  auth_provider?: InputMaybe<Order_By>;
  auth_provider_unique_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  provider?: InputMaybe<Enum_Providers_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  wallet_providers_aggregate?: InputMaybe<Wallet_Providers_Aggregate_Order_By>;
};

/** primary key columns input for table: account_providers */
export type Account_Providers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Account_Providers_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "account_providers" */
export enum Account_Providers_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AuthProvider = 'auth_provider',
  /** column name */
  AuthProviderUniqueId = 'auth_provider_unique_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "account_providers" */
export type Account_Providers_Set_Input = {
  account_id?: InputMaybe<Scalars['uuid']>;
  auth_provider?: InputMaybe<Enum_Providers_Enum>;
  auth_provider_unique_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "account_providers" */
export enum Account_Providers_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AuthProvider = 'auth_provider',
  /** column name */
  AuthProviderUniqueId = 'auth_provider_unique_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** columns and relationships of "accounts" */
export type Accounts = {
  __typename?: 'accounts';
  /** An array relationship */
  account_providers: Array<Account_Providers>;
  /** An aggregate relationship */
  account_providers_aggregate: Account_Providers_Aggregate;
  created_at: Scalars['timestamptz'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An array relationship */
  storage_objects: Array<Storage_Objects>;
  /** An aggregate relationship */
  storage_objects_aggregate: Storage_Objects_Aggregate;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  /** An array relationship */
  user_linked_nft_tokens: Array<User_Linked_Nft_Token>;
  /** An aggregate relationship */
  user_linked_nft_tokens_aggregate: User_Linked_Nft_Token_Aggregate;
  /** An array relationship */
  user_links: Array<User_Links>;
  /** An aggregate relationship */
  user_links_aggregate: User_Links_Aggregate;
  /** An array relationship */
  wallet_providers: Array<Wallet_Providers>;
  /** An aggregate relationship */
  wallet_providers_aggregate: Wallet_Providers_Aggregate;
};

/** columns and relationships of "accounts" */
export type AccountsAccount_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Account_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Providers_Order_By>>;
  where?: InputMaybe<Account_Providers_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsAccount_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Providers_Order_By>>;
  where?: InputMaybe<Account_Providers_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsStorage_ObjectsArgs = {
  distinct_on?: InputMaybe<Array<Storage_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Storage_Objects_Order_By>>;
  where?: InputMaybe<Storage_Objects_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsStorage_Objects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Storage_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Storage_Objects_Order_By>>;
  where?: InputMaybe<Storage_Objects_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsUser_Linked_Nft_TokensArgs = {
  distinct_on?: InputMaybe<Array<User_Linked_Nft_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Linked_Nft_Token_Order_By>>;
  where?: InputMaybe<User_Linked_Nft_Token_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsUser_Linked_Nft_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Linked_Nft_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Linked_Nft_Token_Order_By>>;
  where?: InputMaybe<User_Linked_Nft_Token_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsUser_LinksArgs = {
  distinct_on?: InputMaybe<Array<User_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Links_Order_By>>;
  where?: InputMaybe<User_Links_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsUser_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Links_Order_By>>;
  where?: InputMaybe<User_Links_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsWallet_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Providers_Order_By>>;
  where?: InputMaybe<Wallet_Providers_Bool_Exp>;
};

/** columns and relationships of "accounts" */
export type AccountsWallet_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Providers_Order_By>>;
  where?: InputMaybe<Wallet_Providers_Bool_Exp>;
};

/** aggregated selection of "accounts" */
export type Accounts_Aggregate = {
  __typename?: 'accounts_aggregate';
  aggregate?: Maybe<Accounts_Aggregate_Fields>;
  nodes: Array<Accounts>;
};

/** aggregate fields of "accounts" */
export type Accounts_Aggregate_Fields = {
  __typename?: 'accounts_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Accounts_Max_Fields>;
  min?: Maybe<Accounts_Min_Fields>;
};

/** aggregate fields of "accounts" */
export type Accounts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Accounts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "accounts". All fields are combined with a logical 'AND'. */
export type Accounts_Bool_Exp = {
  _and?: InputMaybe<Array<Accounts_Bool_Exp>>;
  _not?: InputMaybe<Accounts_Bool_Exp>;
  _or?: InputMaybe<Array<Accounts_Bool_Exp>>;
  account_providers?: InputMaybe<Account_Providers_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  storage_objects?: InputMaybe<Storage_Objects_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_linked_nft_tokens?: InputMaybe<User_Linked_Nft_Token_Bool_Exp>;
  user_links?: InputMaybe<User_Links_Bool_Exp>;
  wallet_providers?: InputMaybe<Wallet_Providers_Bool_Exp>;
};

/** unique or primary key constraints on table "accounts" */
export enum Accounts_Constraint {
  /** unique or primary key constraint on columns "email" */
  AccountsEmailKey = 'accounts_email_key',
  /** unique or primary key constraint on columns "id" */
  AccountsPkey = 'accounts_pkey',
}

/** input type for inserting data into table "accounts" */
export type Accounts_Insert_Input = {
  account_providers?: InputMaybe<Account_Providers_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  storage_objects?: InputMaybe<Storage_Objects_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_linked_nft_tokens?: InputMaybe<User_Linked_Nft_Token_Arr_Rel_Insert_Input>;
  user_links?: InputMaybe<User_Links_Arr_Rel_Insert_Input>;
  wallet_providers?: InputMaybe<Wallet_Providers_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Accounts_Max_Fields = {
  __typename?: 'accounts_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Accounts_Min_Fields = {
  __typename?: 'accounts_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "accounts" */
export type Accounts_Mutation_Response = {
  __typename?: 'accounts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Accounts>;
};

/** input type for inserting object relation for remote table "accounts" */
export type Accounts_Obj_Rel_Insert_Input = {
  data: Accounts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Accounts_On_Conflict>;
};

/** on_conflict condition type for table "accounts" */
export type Accounts_On_Conflict = {
  constraint: Accounts_Constraint;
  update_columns?: Array<Accounts_Update_Column>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};

/** Ordering options when selecting data from "accounts". */
export type Accounts_Order_By = {
  account_providers_aggregate?: InputMaybe<Account_Providers_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  storage_objects_aggregate?: InputMaybe<Storage_Objects_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_linked_nft_tokens_aggregate?: InputMaybe<User_Linked_Nft_Token_Aggregate_Order_By>;
  user_links_aggregate?: InputMaybe<User_Links_Aggregate_Order_By>;
  wallet_providers_aggregate?: InputMaybe<Wallet_Providers_Aggregate_Order_By>;
};

/** primary key columns input for table: accounts */
export type Accounts_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "accounts" */
export enum Accounts_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "accounts" */
export type Accounts_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "accounts" */
export enum Accounts_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** columns and relationships of "combined_nft_tokens" */
export type Combined_Nft_Tokens = {
  __typename?: 'combined_nft_tokens';
  asset_org_url?: Maybe<Scalars['String']>;
  asset_thumbnail_url?: Maybe<Scalars['String']>;
  asset_type?: Maybe<Scalars['String']>;
  asset_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current_price?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  marketplace?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['jsonb']>;
  name?: Maybe<Scalars['String']>;
  order_type?: Maybe<Scalars['String']>;
  owned_by?: Maybe<Scalars['uuid']>;
  owner_address?: Maybe<Scalars['String']>;
  sale_kind?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  token_address?: Maybe<Scalars['String']>;
  token_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
  visible?: Maybe<Scalars['Boolean']>;
  wallet_address?: Maybe<Scalars['String']>;
  wallet_provider_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "combined_nft_tokens" */
export type Combined_Nft_TokensMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "combined_nft_tokens" */
export type Combined_Nft_Tokens_Aggregate = {
  __typename?: 'combined_nft_tokens_aggregate';
  aggregate?: Maybe<Combined_Nft_Tokens_Aggregate_Fields>;
  nodes: Array<Combined_Nft_Tokens>;
};

/** aggregate fields of "combined_nft_tokens" */
export type Combined_Nft_Tokens_Aggregate_Fields = {
  __typename?: 'combined_nft_tokens_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Combined_Nft_Tokens_Max_Fields>;
  min?: Maybe<Combined_Nft_Tokens_Min_Fields>;
};

/** aggregate fields of "combined_nft_tokens" */
export type Combined_Nft_Tokens_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Combined_Nft_Tokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "combined_nft_tokens". All fields are combined with a logical 'AND'. */
export type Combined_Nft_Tokens_Bool_Exp = {
  _and?: InputMaybe<Array<Combined_Nft_Tokens_Bool_Exp>>;
  _not?: InputMaybe<Combined_Nft_Tokens_Bool_Exp>;
  _or?: InputMaybe<Array<Combined_Nft_Tokens_Bool_Exp>>;
  asset_org_url?: InputMaybe<String_Comparison_Exp>;
  asset_thumbnail_url?: InputMaybe<String_Comparison_Exp>;
  asset_type?: InputMaybe<String_Comparison_Exp>;
  asset_url?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  current_price?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  marketplace?: InputMaybe<String_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order_type?: InputMaybe<String_Comparison_Exp>;
  owned_by?: InputMaybe<Uuid_Comparison_Exp>;
  owner_address?: InputMaybe<String_Comparison_Exp>;
  sale_kind?: InputMaybe<String_Comparison_Exp>;
  symbol?: InputMaybe<String_Comparison_Exp>;
  token_address?: InputMaybe<String_Comparison_Exp>;
  token_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
  visible?: InputMaybe<Boolean_Comparison_Exp>;
  wallet_address?: InputMaybe<String_Comparison_Exp>;
  wallet_provider_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Combined_Nft_Tokens_Max_Fields = {
  __typename?: 'combined_nft_tokens_max_fields';
  asset_org_url?: Maybe<Scalars['String']>;
  asset_thumbnail_url?: Maybe<Scalars['String']>;
  asset_type?: Maybe<Scalars['String']>;
  asset_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current_price?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  marketplace?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order_type?: Maybe<Scalars['String']>;
  owned_by?: Maybe<Scalars['uuid']>;
  owner_address?: Maybe<Scalars['String']>;
  sale_kind?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  token_address?: Maybe<Scalars['String']>;
  token_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
  wallet_address?: Maybe<Scalars['String']>;
  wallet_provider_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Combined_Nft_Tokens_Min_Fields = {
  __typename?: 'combined_nft_tokens_min_fields';
  asset_org_url?: Maybe<Scalars['String']>;
  asset_thumbnail_url?: Maybe<Scalars['String']>;
  asset_type?: Maybe<Scalars['String']>;
  asset_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current_price?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  marketplace?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order_type?: Maybe<Scalars['String']>;
  owned_by?: Maybe<Scalars['uuid']>;
  owner_address?: Maybe<Scalars['String']>;
  sale_kind?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  token_address?: Maybe<Scalars['String']>;
  token_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
  wallet_address?: Maybe<Scalars['String']>;
  wallet_provider_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "combined_nft_tokens". */
export type Combined_Nft_Tokens_Order_By = {
  asset_org_url?: InputMaybe<Order_By>;
  asset_thumbnail_url?: InputMaybe<Order_By>;
  asset_type?: InputMaybe<Order_By>;
  asset_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_price?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  marketplace?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order_type?: InputMaybe<Order_By>;
  owned_by?: InputMaybe<Order_By>;
  owner_address?: InputMaybe<Order_By>;
  sale_kind?: InputMaybe<Order_By>;
  symbol?: InputMaybe<Order_By>;
  token_address?: InputMaybe<Order_By>;
  token_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
  visible?: InputMaybe<Order_By>;
  wallet_address?: InputMaybe<Order_By>;
  wallet_provider_id?: InputMaybe<Order_By>;
};

/** select columns of table "combined_nft_tokens" */
export enum Combined_Nft_Tokens_Select_Column {
  /** column name */
  AssetOrgUrl = 'asset_org_url',
  /** column name */
  AssetThumbnailUrl = 'asset_thumbnail_url',
  /** column name */
  AssetType = 'asset_type',
  /** column name */
  AssetUrl = 'asset_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentPrice = 'current_price',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Marketplace = 'marketplace',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  OrderType = 'order_type',
  /** column name */
  OwnedBy = 'owned_by',
  /** column name */
  OwnerAddress = 'owner_address',
  /** column name */
  SaleKind = 'sale_kind',
  /** column name */
  Symbol = 'symbol',
  /** column name */
  TokenAddress = 'token_address',
  /** column name */
  TokenId = 'token_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username',
  /** column name */
  Visible = 'visible',
  /** column name */
  WalletAddress = 'wallet_address',
  /** column name */
  WalletProviderId = 'wallet_provider_id',
}

/** columns and relationships of "created_nft_tokens" */
export type Created_Nft_Tokens = {
  __typename?: 'created_nft_tokens';
  asset_org_url?: Maybe<Scalars['String']>;
  asset_thumbnail_url?: Maybe<Scalars['String']>;
  asset_type?: Maybe<Scalars['String']>;
  asset_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current_price?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  marketplace?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['jsonb']>;
  name?: Maybe<Scalars['String']>;
  order_type?: Maybe<Scalars['String']>;
  owned_by?: Maybe<Scalars['uuid']>;
  owner_address?: Maybe<Scalars['String']>;
  sale_kind?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  token_address?: Maybe<Scalars['String']>;
  token_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
  visible?: Maybe<Scalars['Boolean']>;
  wallet_address?: Maybe<Scalars['String']>;
  wallet_provider_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "created_nft_tokens" */
export type Created_Nft_TokensMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "created_nft_tokens" */
export type Created_Nft_Tokens_Aggregate = {
  __typename?: 'created_nft_tokens_aggregate';
  aggregate?: Maybe<Created_Nft_Tokens_Aggregate_Fields>;
  nodes: Array<Created_Nft_Tokens>;
};

/** aggregate fields of "created_nft_tokens" */
export type Created_Nft_Tokens_Aggregate_Fields = {
  __typename?: 'created_nft_tokens_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Created_Nft_Tokens_Max_Fields>;
  min?: Maybe<Created_Nft_Tokens_Min_Fields>;
};

/** aggregate fields of "created_nft_tokens" */
export type Created_Nft_Tokens_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Created_Nft_Tokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "created_nft_tokens". All fields are combined with a logical 'AND'. */
export type Created_Nft_Tokens_Bool_Exp = {
  _and?: InputMaybe<Array<Created_Nft_Tokens_Bool_Exp>>;
  _not?: InputMaybe<Created_Nft_Tokens_Bool_Exp>;
  _or?: InputMaybe<Array<Created_Nft_Tokens_Bool_Exp>>;
  asset_org_url?: InputMaybe<String_Comparison_Exp>;
  asset_thumbnail_url?: InputMaybe<String_Comparison_Exp>;
  asset_type?: InputMaybe<String_Comparison_Exp>;
  asset_url?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  current_price?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  marketplace?: InputMaybe<String_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order_type?: InputMaybe<String_Comparison_Exp>;
  owned_by?: InputMaybe<Uuid_Comparison_Exp>;
  owner_address?: InputMaybe<String_Comparison_Exp>;
  sale_kind?: InputMaybe<String_Comparison_Exp>;
  symbol?: InputMaybe<String_Comparison_Exp>;
  token_address?: InputMaybe<String_Comparison_Exp>;
  token_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
  visible?: InputMaybe<Boolean_Comparison_Exp>;
  wallet_address?: InputMaybe<String_Comparison_Exp>;
  wallet_provider_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Created_Nft_Tokens_Max_Fields = {
  __typename?: 'created_nft_tokens_max_fields';
  asset_org_url?: Maybe<Scalars['String']>;
  asset_thumbnail_url?: Maybe<Scalars['String']>;
  asset_type?: Maybe<Scalars['String']>;
  asset_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current_price?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  marketplace?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order_type?: Maybe<Scalars['String']>;
  owned_by?: Maybe<Scalars['uuid']>;
  owner_address?: Maybe<Scalars['String']>;
  sale_kind?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  token_address?: Maybe<Scalars['String']>;
  token_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
  wallet_address?: Maybe<Scalars['String']>;
  wallet_provider_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Created_Nft_Tokens_Min_Fields = {
  __typename?: 'created_nft_tokens_min_fields';
  asset_org_url?: Maybe<Scalars['String']>;
  asset_thumbnail_url?: Maybe<Scalars['String']>;
  asset_type?: Maybe<Scalars['String']>;
  asset_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current_price?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  marketplace?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order_type?: Maybe<Scalars['String']>;
  owned_by?: Maybe<Scalars['uuid']>;
  owner_address?: Maybe<Scalars['String']>;
  sale_kind?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  token_address?: Maybe<Scalars['String']>;
  token_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
  wallet_address?: Maybe<Scalars['String']>;
  wallet_provider_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "created_nft_tokens". */
export type Created_Nft_Tokens_Order_By = {
  asset_org_url?: InputMaybe<Order_By>;
  asset_thumbnail_url?: InputMaybe<Order_By>;
  asset_type?: InputMaybe<Order_By>;
  asset_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_price?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  marketplace?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order_type?: InputMaybe<Order_By>;
  owned_by?: InputMaybe<Order_By>;
  owner_address?: InputMaybe<Order_By>;
  sale_kind?: InputMaybe<Order_By>;
  symbol?: InputMaybe<Order_By>;
  token_address?: InputMaybe<Order_By>;
  token_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
  visible?: InputMaybe<Order_By>;
  wallet_address?: InputMaybe<Order_By>;
  wallet_provider_id?: InputMaybe<Order_By>;
};

/** select columns of table "created_nft_tokens" */
export enum Created_Nft_Tokens_Select_Column {
  /** column name */
  AssetOrgUrl = 'asset_org_url',
  /** column name */
  AssetThumbnailUrl = 'asset_thumbnail_url',
  /** column name */
  AssetType = 'asset_type',
  /** column name */
  AssetUrl = 'asset_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentPrice = 'current_price',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Marketplace = 'marketplace',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  OrderType = 'order_type',
  /** column name */
  OwnedBy = 'owned_by',
  /** column name */
  OwnerAddress = 'owner_address',
  /** column name */
  SaleKind = 'sale_kind',
  /** column name */
  Symbol = 'symbol',
  /** column name */
  TokenAddress = 'token_address',
  /** column name */
  TokenId = 'token_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username',
  /** column name */
  Visible = 'visible',
  /** column name */
  WalletAddress = 'wallet_address',
  /** column name */
  WalletProviderId = 'wallet_provider_id',
}

/** columns and relationships of "enum_blockchain_name" */
export type Enum_Blockchain_Name = {
  __typename?: 'enum_blockchain_name';
  comment: Scalars['String'];
  value: Scalars['String'];
  /** An array relationship */
  wallet_providers: Array<Wallet_Providers>;
  /** An aggregate relationship */
  wallet_providers_aggregate: Wallet_Providers_Aggregate;
};

/** columns and relationships of "enum_blockchain_name" */
export type Enum_Blockchain_NameWallet_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Providers_Order_By>>;
  where?: InputMaybe<Wallet_Providers_Bool_Exp>;
};

/** columns and relationships of "enum_blockchain_name" */
export type Enum_Blockchain_NameWallet_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Providers_Order_By>>;
  where?: InputMaybe<Wallet_Providers_Bool_Exp>;
};

/** aggregated selection of "enum_blockchain_name" */
export type Enum_Blockchain_Name_Aggregate = {
  __typename?: 'enum_blockchain_name_aggregate';
  aggregate?: Maybe<Enum_Blockchain_Name_Aggregate_Fields>;
  nodes: Array<Enum_Blockchain_Name>;
};

/** aggregate fields of "enum_blockchain_name" */
export type Enum_Blockchain_Name_Aggregate_Fields = {
  __typename?: 'enum_blockchain_name_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Blockchain_Name_Max_Fields>;
  min?: Maybe<Enum_Blockchain_Name_Min_Fields>;
};

/** aggregate fields of "enum_blockchain_name" */
export type Enum_Blockchain_Name_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Blockchain_Name_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_blockchain_name". All fields are combined with a logical 'AND'. */
export type Enum_Blockchain_Name_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Blockchain_Name_Bool_Exp>>;
  _not?: InputMaybe<Enum_Blockchain_Name_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Blockchain_Name_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
  wallet_providers?: InputMaybe<Wallet_Providers_Bool_Exp>;
};

/** unique or primary key constraints on table "enum_blockchain_name" */
export enum Enum_Blockchain_Name_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumBlockchainNamePkey = 'enum_blockchain_name_pkey',
}

export enum Enum_Blockchain_Name_Enum {
  /** ethereum block chain */
  Ethereum = 'ethereum',
  /** solana block chain */
  Solana = 'solana',
  /** tezos block chain */
  Tezos = 'tezos',
}

/** Boolean expression to compare columns of type "enum_blockchain_name_enum". All fields are combined with logical 'AND'. */
export type Enum_Blockchain_Name_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Blockchain_Name_Enum>;
  _in?: InputMaybe<Array<Enum_Blockchain_Name_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Blockchain_Name_Enum>;
  _nin?: InputMaybe<Array<Enum_Blockchain_Name_Enum>>;
};

/** input type for inserting data into table "enum_blockchain_name" */
export type Enum_Blockchain_Name_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
  wallet_providers?: InputMaybe<Wallet_Providers_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Enum_Blockchain_Name_Max_Fields = {
  __typename?: 'enum_blockchain_name_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Blockchain_Name_Min_Fields = {
  __typename?: 'enum_blockchain_name_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_blockchain_name" */
export type Enum_Blockchain_Name_Mutation_Response = {
  __typename?: 'enum_blockchain_name_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Blockchain_Name>;
};

/** input type for inserting object relation for remote table "enum_blockchain_name" */
export type Enum_Blockchain_Name_Obj_Rel_Insert_Input = {
  data: Enum_Blockchain_Name_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Blockchain_Name_On_Conflict>;
};

/** on_conflict condition type for table "enum_blockchain_name" */
export type Enum_Blockchain_Name_On_Conflict = {
  constraint: Enum_Blockchain_Name_Constraint;
  update_columns?: Array<Enum_Blockchain_Name_Update_Column>;
  where?: InputMaybe<Enum_Blockchain_Name_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_blockchain_name". */
export type Enum_Blockchain_Name_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
  wallet_providers_aggregate?: InputMaybe<Wallet_Providers_Aggregate_Order_By>;
};

/** primary key columns input for table: enum_blockchain_name */
export type Enum_Blockchain_Name_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_blockchain_name" */
export enum Enum_Blockchain_Name_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_blockchain_name" */
export type Enum_Blockchain_Name_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_blockchain_name" */
export enum Enum_Blockchain_Name_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** columns and relationships of "enum_display_location" */
export type Enum_Display_Location = {
  __typename?: 'enum_display_location';
  comment: Scalars['String'];
  /** An array relationship */
  user_links: Array<User_Links>;
  /** An aggregate relationship */
  user_links_aggregate: User_Links_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "enum_display_location" */
export type Enum_Display_LocationUser_LinksArgs = {
  distinct_on?: InputMaybe<Array<User_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Links_Order_By>>;
  where?: InputMaybe<User_Links_Bool_Exp>;
};

/** columns and relationships of "enum_display_location" */
export type Enum_Display_LocationUser_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Links_Order_By>>;
  where?: InputMaybe<User_Links_Bool_Exp>;
};

/** aggregated selection of "enum_display_location" */
export type Enum_Display_Location_Aggregate = {
  __typename?: 'enum_display_location_aggregate';
  aggregate?: Maybe<Enum_Display_Location_Aggregate_Fields>;
  nodes: Array<Enum_Display_Location>;
};

/** aggregate fields of "enum_display_location" */
export type Enum_Display_Location_Aggregate_Fields = {
  __typename?: 'enum_display_location_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Display_Location_Max_Fields>;
  min?: Maybe<Enum_Display_Location_Min_Fields>;
};

/** aggregate fields of "enum_display_location" */
export type Enum_Display_Location_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Display_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_display_location". All fields are combined with a logical 'AND'. */
export type Enum_Display_Location_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Display_Location_Bool_Exp>>;
  _not?: InputMaybe<Enum_Display_Location_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Display_Location_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  user_links?: InputMaybe<User_Links_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_display_location" */
export enum Enum_Display_Location_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumDisplayLocationPkey = 'enum_display_location_pkey',
}

export enum Enum_Display_Location_Enum {
  /** general_links */
  GeneralLinks = 'general_links',
  /** profile_circle_links */
  ProfileCircleLinks = 'profile_circle_links',
}

/** Boolean expression to compare columns of type "enum_display_location_enum". All fields are combined with logical 'AND'. */
export type Enum_Display_Location_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Display_Location_Enum>;
  _in?: InputMaybe<Array<Enum_Display_Location_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Display_Location_Enum>;
  _nin?: InputMaybe<Array<Enum_Display_Location_Enum>>;
};

/** input type for inserting data into table "enum_display_location" */
export type Enum_Display_Location_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  user_links?: InputMaybe<User_Links_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Display_Location_Max_Fields = {
  __typename?: 'enum_display_location_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Display_Location_Min_Fields = {
  __typename?: 'enum_display_location_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_display_location" */
export type Enum_Display_Location_Mutation_Response = {
  __typename?: 'enum_display_location_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Display_Location>;
};

/** input type for inserting object relation for remote table "enum_display_location" */
export type Enum_Display_Location_Obj_Rel_Insert_Input = {
  data: Enum_Display_Location_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Display_Location_On_Conflict>;
};

/** on_conflict condition type for table "enum_display_location" */
export type Enum_Display_Location_On_Conflict = {
  constraint: Enum_Display_Location_Constraint;
  update_columns?: Array<Enum_Display_Location_Update_Column>;
  where?: InputMaybe<Enum_Display_Location_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_display_location". */
export type Enum_Display_Location_Order_By = {
  comment?: InputMaybe<Order_By>;
  user_links_aggregate?: InputMaybe<User_Links_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_display_location */
export type Enum_Display_Location_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_display_location" */
export enum Enum_Display_Location_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_display_location" */
export type Enum_Display_Location_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_display_location" */
export enum Enum_Display_Location_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** columns and relationships of "enum_marketplace" */
export type Enum_Marketplace = {
  __typename?: 'enum_marketplace';
  comment: Scalars['String'];
  /** An array relationship */
  nft_tokens: Array<Nft_Tokens>;
  /** An aggregate relationship */
  nft_tokens_aggregate: Nft_Tokens_Aggregate;
  /** An array relationship */
  nft_tokens_createds: Array<Nft_Tokens_Created>;
  /** An aggregate relationship */
  nft_tokens_createds_aggregate: Nft_Tokens_Created_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "enum_marketplace" */
export type Enum_MarketplaceNft_TokensArgs = {
  distinct_on?: InputMaybe<Array<Nft_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nft_Tokens_Order_By>>;
  where?: InputMaybe<Nft_Tokens_Bool_Exp>;
};

/** columns and relationships of "enum_marketplace" */
export type Enum_MarketplaceNft_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nft_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nft_Tokens_Order_By>>;
  where?: InputMaybe<Nft_Tokens_Bool_Exp>;
};

/** columns and relationships of "enum_marketplace" */
export type Enum_MarketplaceNft_Tokens_CreatedsArgs = {
  distinct_on?: InputMaybe<Array<Nft_Tokens_Created_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nft_Tokens_Created_Order_By>>;
  where?: InputMaybe<Nft_Tokens_Created_Bool_Exp>;
};

/** columns and relationships of "enum_marketplace" */
export type Enum_MarketplaceNft_Tokens_Createds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nft_Tokens_Created_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nft_Tokens_Created_Order_By>>;
  where?: InputMaybe<Nft_Tokens_Created_Bool_Exp>;
};

/** aggregated selection of "enum_marketplace" */
export type Enum_Marketplace_Aggregate = {
  __typename?: 'enum_marketplace_aggregate';
  aggregate?: Maybe<Enum_Marketplace_Aggregate_Fields>;
  nodes: Array<Enum_Marketplace>;
};

/** aggregate fields of "enum_marketplace" */
export type Enum_Marketplace_Aggregate_Fields = {
  __typename?: 'enum_marketplace_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Marketplace_Max_Fields>;
  min?: Maybe<Enum_Marketplace_Min_Fields>;
};

/** aggregate fields of "enum_marketplace" */
export type Enum_Marketplace_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Marketplace_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_marketplace". All fields are combined with a logical 'AND'. */
export type Enum_Marketplace_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Marketplace_Bool_Exp>>;
  _not?: InputMaybe<Enum_Marketplace_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Marketplace_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  nft_tokens?: InputMaybe<Nft_Tokens_Bool_Exp>;
  nft_tokens_createds?: InputMaybe<Nft_Tokens_Created_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_marketplace" */
export enum Enum_Marketplace_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumMarketplacePkey = 'enum_marketplace_pkey',
}

export enum Enum_Marketplace_Enum {
  /** Foundation for Etherium */
  Foundation = 'FOUNDATION',
  /** Magiceden for Solana */
  Magiceden = 'MAGICEDEN',
  /** OBJKT for tezos */
  Objkt = 'OBJKT',
  /** Opensea for Etherium */
  Opensea = 'OPENSEA',
}

/** Boolean expression to compare columns of type "enum_marketplace_enum". All fields are combined with logical 'AND'. */
export type Enum_Marketplace_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Marketplace_Enum>;
  _in?: InputMaybe<Array<Enum_Marketplace_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Marketplace_Enum>;
  _nin?: InputMaybe<Array<Enum_Marketplace_Enum>>;
};

/** input type for inserting data into table "enum_marketplace" */
export type Enum_Marketplace_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  nft_tokens?: InputMaybe<Nft_Tokens_Arr_Rel_Insert_Input>;
  nft_tokens_createds?: InputMaybe<Nft_Tokens_Created_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Marketplace_Max_Fields = {
  __typename?: 'enum_marketplace_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Marketplace_Min_Fields = {
  __typename?: 'enum_marketplace_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_marketplace" */
export type Enum_Marketplace_Mutation_Response = {
  __typename?: 'enum_marketplace_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Marketplace>;
};

/** input type for inserting object relation for remote table "enum_marketplace" */
export type Enum_Marketplace_Obj_Rel_Insert_Input = {
  data: Enum_Marketplace_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Marketplace_On_Conflict>;
};

/** on_conflict condition type for table "enum_marketplace" */
export type Enum_Marketplace_On_Conflict = {
  constraint: Enum_Marketplace_Constraint;
  update_columns?: Array<Enum_Marketplace_Update_Column>;
  where?: InputMaybe<Enum_Marketplace_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_marketplace". */
export type Enum_Marketplace_Order_By = {
  comment?: InputMaybe<Order_By>;
  nft_tokens_aggregate?: InputMaybe<Nft_Tokens_Aggregate_Order_By>;
  nft_tokens_createds_aggregate?: InputMaybe<Nft_Tokens_Created_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_marketplace */
export type Enum_Marketplace_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_marketplace" */
export enum Enum_Marketplace_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_marketplace" */
export type Enum_Marketplace_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_marketplace" */
export enum Enum_Marketplace_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** columns and relationships of "enum_nft_type" */
export type Enum_Nft_Type = {
  __typename?: 'enum_nft_type';
  comment: Scalars['String'];
  /** An array relationship */
  user_linked_nft_tokens: Array<User_Linked_Nft_Token>;
  /** An aggregate relationship */
  user_linked_nft_tokens_aggregate: User_Linked_Nft_Token_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "enum_nft_type" */
export type Enum_Nft_TypeUser_Linked_Nft_TokensArgs = {
  distinct_on?: InputMaybe<Array<User_Linked_Nft_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Linked_Nft_Token_Order_By>>;
  where?: InputMaybe<User_Linked_Nft_Token_Bool_Exp>;
};

/** columns and relationships of "enum_nft_type" */
export type Enum_Nft_TypeUser_Linked_Nft_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Linked_Nft_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Linked_Nft_Token_Order_By>>;
  where?: InputMaybe<User_Linked_Nft_Token_Bool_Exp>;
};

/** aggregated selection of "enum_nft_type" */
export type Enum_Nft_Type_Aggregate = {
  __typename?: 'enum_nft_type_aggregate';
  aggregate?: Maybe<Enum_Nft_Type_Aggregate_Fields>;
  nodes: Array<Enum_Nft_Type>;
};

/** aggregate fields of "enum_nft_type" */
export type Enum_Nft_Type_Aggregate_Fields = {
  __typename?: 'enum_nft_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Nft_Type_Max_Fields>;
  min?: Maybe<Enum_Nft_Type_Min_Fields>;
};

/** aggregate fields of "enum_nft_type" */
export type Enum_Nft_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Nft_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_nft_type". All fields are combined with a logical 'AND'. */
export type Enum_Nft_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Nft_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Nft_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Nft_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  user_linked_nft_tokens?: InputMaybe<User_Linked_Nft_Token_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_nft_type" */
export enum Enum_Nft_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumNftTypePkey = 'enum_nft_type_pkey',
}

export enum Enum_Nft_Type_Enum {
  /** represent created nft table */
  Created = 'created',
  /** represent owned nft table */
  Owned = 'owned',
}

/** Boolean expression to compare columns of type "enum_nft_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Nft_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Nft_Type_Enum>;
  _in?: InputMaybe<Array<Enum_Nft_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Nft_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_Nft_Type_Enum>>;
};

/** input type for inserting data into table "enum_nft_type" */
export type Enum_Nft_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  user_linked_nft_tokens?: InputMaybe<User_Linked_Nft_Token_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Nft_Type_Max_Fields = {
  __typename?: 'enum_nft_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Nft_Type_Min_Fields = {
  __typename?: 'enum_nft_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_nft_type" */
export type Enum_Nft_Type_Mutation_Response = {
  __typename?: 'enum_nft_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Nft_Type>;
};

/** input type for inserting object relation for remote table "enum_nft_type" */
export type Enum_Nft_Type_Obj_Rel_Insert_Input = {
  data: Enum_Nft_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Nft_Type_On_Conflict>;
};

/** on_conflict condition type for table "enum_nft_type" */
export type Enum_Nft_Type_On_Conflict = {
  constraint: Enum_Nft_Type_Constraint;
  update_columns?: Array<Enum_Nft_Type_Update_Column>;
  where?: InputMaybe<Enum_Nft_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_nft_type". */
export type Enum_Nft_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  user_linked_nft_tokens_aggregate?: InputMaybe<User_Linked_Nft_Token_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_nft_type */
export type Enum_Nft_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_nft_type" */
export enum Enum_Nft_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_nft_type" */
export type Enum_Nft_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_nft_type" */
export enum Enum_Nft_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** columns and relationships of "enum_providers" */
export type Enum_Providers = {
  __typename?: 'enum_providers';
  /** An array relationship */
  account_providers: Array<Account_Providers>;
  /** An aggregate relationship */
  account_providers_aggregate: Account_Providers_Aggregate;
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** columns and relationships of "enum_providers" */
export type Enum_ProvidersAccount_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Account_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Providers_Order_By>>;
  where?: InputMaybe<Account_Providers_Bool_Exp>;
};

/** columns and relationships of "enum_providers" */
export type Enum_ProvidersAccount_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Providers_Order_By>>;
  where?: InputMaybe<Account_Providers_Bool_Exp>;
};

/** aggregated selection of "enum_providers" */
export type Enum_Providers_Aggregate = {
  __typename?: 'enum_providers_aggregate';
  aggregate?: Maybe<Enum_Providers_Aggregate_Fields>;
  nodes: Array<Enum_Providers>;
};

/** aggregate fields of "enum_providers" */
export type Enum_Providers_Aggregate_Fields = {
  __typename?: 'enum_providers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Providers_Max_Fields>;
  min?: Maybe<Enum_Providers_Min_Fields>;
};

/** aggregate fields of "enum_providers" */
export type Enum_Providers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Providers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_providers". All fields are combined with a logical 'AND'. */
export type Enum_Providers_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Providers_Bool_Exp>>;
  _not?: InputMaybe<Enum_Providers_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Providers_Bool_Exp>>;
  account_providers?: InputMaybe<Account_Providers_Bool_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_providers" */
export enum Enum_Providers_Constraint {
  /** unique or primary key constraint on columns "value" */
  ProvidersPkey = 'providers_pkey',
}

export enum Enum_Providers_Enum {
  /** ethereum wallet auth */
  Ethereum = 'ethereum',
  /** magic link auth */
  Magic = 'magic',
  /** solana wallet auth */
  Solana = 'solana',
  /** tezos wallet auth */
  Tezos = 'tezos',
}

/** Boolean expression to compare columns of type "enum_providers_enum". All fields are combined with logical 'AND'. */
export type Enum_Providers_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Providers_Enum>;
  _in?: InputMaybe<Array<Enum_Providers_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Providers_Enum>;
  _nin?: InputMaybe<Array<Enum_Providers_Enum>>;
};

/** input type for inserting data into table "enum_providers" */
export type Enum_Providers_Insert_Input = {
  account_providers?: InputMaybe<Account_Providers_Arr_Rel_Insert_Input>;
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Providers_Max_Fields = {
  __typename?: 'enum_providers_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Providers_Min_Fields = {
  __typename?: 'enum_providers_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_providers" */
export type Enum_Providers_Mutation_Response = {
  __typename?: 'enum_providers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Providers>;
};

/** input type for inserting object relation for remote table "enum_providers" */
export type Enum_Providers_Obj_Rel_Insert_Input = {
  data: Enum_Providers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Providers_On_Conflict>;
};

/** on_conflict condition type for table "enum_providers" */
export type Enum_Providers_On_Conflict = {
  constraint: Enum_Providers_Constraint;
  update_columns?: Array<Enum_Providers_Update_Column>;
  where?: InputMaybe<Enum_Providers_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_providers". */
export type Enum_Providers_Order_By = {
  account_providers_aggregate?: InputMaybe<Account_Providers_Aggregate_Order_By>;
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_providers */
export type Enum_Providers_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_providers" */
export enum Enum_Providers_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_providers" */
export type Enum_Providers_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_providers" */
export enum Enum_Providers_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** Sale type */
export type Enum_Sale_Kind = {
  __typename?: 'enum_sale_kind';
  comment: Scalars['String'];
  /** An array relationship */
  nft_tokens: Array<Nft_Tokens>;
  /** An aggregate relationship */
  nft_tokens_aggregate: Nft_Tokens_Aggregate;
  /** An array relationship */
  nft_tokens_createds: Array<Nft_Tokens_Created>;
  /** An aggregate relationship */
  nft_tokens_createds_aggregate: Nft_Tokens_Created_Aggregate;
  value: Scalars['String'];
};

/** Sale type */
export type Enum_Sale_KindNft_TokensArgs = {
  distinct_on?: InputMaybe<Array<Nft_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nft_Tokens_Order_By>>;
  where?: InputMaybe<Nft_Tokens_Bool_Exp>;
};

/** Sale type */
export type Enum_Sale_KindNft_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nft_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nft_Tokens_Order_By>>;
  where?: InputMaybe<Nft_Tokens_Bool_Exp>;
};

/** Sale type */
export type Enum_Sale_KindNft_Tokens_CreatedsArgs = {
  distinct_on?: InputMaybe<Array<Nft_Tokens_Created_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nft_Tokens_Created_Order_By>>;
  where?: InputMaybe<Nft_Tokens_Created_Bool_Exp>;
};

/** Sale type */
export type Enum_Sale_KindNft_Tokens_Createds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nft_Tokens_Created_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nft_Tokens_Created_Order_By>>;
  where?: InputMaybe<Nft_Tokens_Created_Bool_Exp>;
};

/** aggregated selection of "enum_sale_kind" */
export type Enum_Sale_Kind_Aggregate = {
  __typename?: 'enum_sale_kind_aggregate';
  aggregate?: Maybe<Enum_Sale_Kind_Aggregate_Fields>;
  nodes: Array<Enum_Sale_Kind>;
};

/** aggregate fields of "enum_sale_kind" */
export type Enum_Sale_Kind_Aggregate_Fields = {
  __typename?: 'enum_sale_kind_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Sale_Kind_Max_Fields>;
  min?: Maybe<Enum_Sale_Kind_Min_Fields>;
};

/** aggregate fields of "enum_sale_kind" */
export type Enum_Sale_Kind_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Sale_Kind_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_sale_kind". All fields are combined with a logical 'AND'. */
export type Enum_Sale_Kind_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Sale_Kind_Bool_Exp>>;
  _not?: InputMaybe<Enum_Sale_Kind_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Sale_Kind_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  nft_tokens?: InputMaybe<Nft_Tokens_Bool_Exp>;
  nft_tokens_createds?: InputMaybe<Nft_Tokens_Created_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_sale_kind" */
export enum Enum_Sale_Kind_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumSaleKindPkey = 'enum_sale_kind_pkey',
}

export enum Enum_Sale_Kind_Enum {
  /** Auction NFT */
  Auction = 'auction',
  /** Buy NFT */
  Buy = 'buy',
  /** No Sale */
  None = 'none',
  /** Offer NFT */
  Offer = 'offer',
  /** Sell NFT */
  Sell = 'sell',
}

/** Boolean expression to compare columns of type "enum_sale_kind_enum". All fields are combined with logical 'AND'. */
export type Enum_Sale_Kind_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Sale_Kind_Enum>;
  _in?: InputMaybe<Array<Enum_Sale_Kind_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Sale_Kind_Enum>;
  _nin?: InputMaybe<Array<Enum_Sale_Kind_Enum>>;
};

/** input type for inserting data into table "enum_sale_kind" */
export type Enum_Sale_Kind_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  nft_tokens?: InputMaybe<Nft_Tokens_Arr_Rel_Insert_Input>;
  nft_tokens_createds?: InputMaybe<Nft_Tokens_Created_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Sale_Kind_Max_Fields = {
  __typename?: 'enum_sale_kind_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Sale_Kind_Min_Fields = {
  __typename?: 'enum_sale_kind_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_sale_kind" */
export type Enum_Sale_Kind_Mutation_Response = {
  __typename?: 'enum_sale_kind_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Sale_Kind>;
};

/** input type for inserting object relation for remote table "enum_sale_kind" */
export type Enum_Sale_Kind_Obj_Rel_Insert_Input = {
  data: Enum_Sale_Kind_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Sale_Kind_On_Conflict>;
};

/** on_conflict condition type for table "enum_sale_kind" */
export type Enum_Sale_Kind_On_Conflict = {
  constraint: Enum_Sale_Kind_Constraint;
  update_columns?: Array<Enum_Sale_Kind_Update_Column>;
  where?: InputMaybe<Enum_Sale_Kind_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_sale_kind". */
export type Enum_Sale_Kind_Order_By = {
  comment?: InputMaybe<Order_By>;
  nft_tokens_aggregate?: InputMaybe<Nft_Tokens_Aggregate_Order_By>;
  nft_tokens_createds_aggregate?: InputMaybe<Nft_Tokens_Created_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_sale_kind */
export type Enum_Sale_Kind_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_sale_kind" */
export enum Enum_Sale_Kind_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_sale_kind" */
export type Enum_Sale_Kind_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_sale_kind" */
export enum Enum_Sale_Kind_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** columns and relationships of "enum_storage_object_type" */
export type Enum_Storage_Object_Type = {
  __typename?: 'enum_storage_object_type';
  comment: Scalars['String'];
  /** An array relationship */
  storage_objects: Array<Storage_Objects>;
  /** An aggregate relationship */
  storage_objects_aggregate: Storage_Objects_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "enum_storage_object_type" */
export type Enum_Storage_Object_TypeStorage_ObjectsArgs = {
  distinct_on?: InputMaybe<Array<Storage_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Storage_Objects_Order_By>>;
  where?: InputMaybe<Storage_Objects_Bool_Exp>;
};

/** columns and relationships of "enum_storage_object_type" */
export type Enum_Storage_Object_TypeStorage_Objects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Storage_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Storage_Objects_Order_By>>;
  where?: InputMaybe<Storage_Objects_Bool_Exp>;
};

/** aggregated selection of "enum_storage_object_type" */
export type Enum_Storage_Object_Type_Aggregate = {
  __typename?: 'enum_storage_object_type_aggregate';
  aggregate?: Maybe<Enum_Storage_Object_Type_Aggregate_Fields>;
  nodes: Array<Enum_Storage_Object_Type>;
};

/** aggregate fields of "enum_storage_object_type" */
export type Enum_Storage_Object_Type_Aggregate_Fields = {
  __typename?: 'enum_storage_object_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Storage_Object_Type_Max_Fields>;
  min?: Maybe<Enum_Storage_Object_Type_Min_Fields>;
};

/** aggregate fields of "enum_storage_object_type" */
export type Enum_Storage_Object_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Storage_Object_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_storage_object_type". All fields are combined with a logical 'AND'. */
export type Enum_Storage_Object_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Storage_Object_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Storage_Object_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Storage_Object_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  storage_objects?: InputMaybe<Storage_Objects_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_storage_object_type" */
export enum Enum_Storage_Object_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  StorageObjectTypePkey = 'storage_object_type_pkey',
}

export enum Enum_Storage_Object_Type_Enum {
  /** Image type png,jpg,gif etc */
  Image = 'IMAGE',
  /** Video type mp4,avi etc */
  Video = 'VIDEO',
}

/** Boolean expression to compare columns of type "enum_storage_object_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Storage_Object_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Storage_Object_Type_Enum>;
  _in?: InputMaybe<Array<Enum_Storage_Object_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Storage_Object_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_Storage_Object_Type_Enum>>;
};

/** input type for inserting data into table "enum_storage_object_type" */
export type Enum_Storage_Object_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  storage_objects?: InputMaybe<Storage_Objects_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Storage_Object_Type_Max_Fields = {
  __typename?: 'enum_storage_object_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Storage_Object_Type_Min_Fields = {
  __typename?: 'enum_storage_object_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_storage_object_type" */
export type Enum_Storage_Object_Type_Mutation_Response = {
  __typename?: 'enum_storage_object_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Storage_Object_Type>;
};

/** input type for inserting object relation for remote table "enum_storage_object_type" */
export type Enum_Storage_Object_Type_Obj_Rel_Insert_Input = {
  data: Enum_Storage_Object_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Storage_Object_Type_On_Conflict>;
};

/** on_conflict condition type for table "enum_storage_object_type" */
export type Enum_Storage_Object_Type_On_Conflict = {
  constraint: Enum_Storage_Object_Type_Constraint;
  update_columns?: Array<Enum_Storage_Object_Type_Update_Column>;
  where?: InputMaybe<Enum_Storage_Object_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_storage_object_type". */
export type Enum_Storage_Object_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  storage_objects_aggregate?: InputMaybe<Storage_Objects_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_storage_object_type */
export type Enum_Storage_Object_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_storage_object_type" */
export enum Enum_Storage_Object_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_storage_object_type" */
export type Enum_Storage_Object_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_storage_object_type" */
export enum Enum_Storage_Object_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** columns and relationships of "enum_store_theme" */
export type Enum_Store_Theme = {
  __typename?: 'enum_store_theme';
  comment: Scalars['String'];
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  value: Scalars['String'];
};

/** columns and relationships of "enum_store_theme" */
export type Enum_Store_ThemeUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** columns and relationships of "enum_store_theme" */
export type Enum_Store_ThemeUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** aggregated selection of "enum_store_theme" */
export type Enum_Store_Theme_Aggregate = {
  __typename?: 'enum_store_theme_aggregate';
  aggregate?: Maybe<Enum_Store_Theme_Aggregate_Fields>;
  nodes: Array<Enum_Store_Theme>;
};

/** aggregate fields of "enum_store_theme" */
export type Enum_Store_Theme_Aggregate_Fields = {
  __typename?: 'enum_store_theme_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enum_Store_Theme_Max_Fields>;
  min?: Maybe<Enum_Store_Theme_Min_Fields>;
};

/** aggregate fields of "enum_store_theme" */
export type Enum_Store_Theme_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enum_Store_Theme_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enum_store_theme". All fields are combined with a logical 'AND'. */
export type Enum_Store_Theme_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Store_Theme_Bool_Exp>>;
  _not?: InputMaybe<Enum_Store_Theme_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Store_Theme_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  users?: InputMaybe<Users_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_store_theme" */
export enum Enum_Store_Theme_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumStoreThemePkey = 'enum_store_theme_pkey',
}

export enum Enum_Store_Theme_Enum {
  /** Autumn Leaf Theme */
  AutumnLeaf = 'autumn_leaf',
  /** Azetc Clay Theme */
  AztecClay = 'aztec_clay',
  /** Black Whitez Theme */
  BlackWhitez = 'black_whitez',
  /** Bon Jour Theme */
  BonJour = 'bon_jour',
  /** Bright Scampi Theme */
  BrightScampi = 'bright_scampi',
  /** Cafe Amber Theme */
  CafeAmber = 'cafe_amber',
  /** Coral Brooks Theme */
  CoralBrooks = 'coral_brooks',
  /** Darken Darz Theme */
  DarkenDarz = 'darken_darz',
  /** Darq Magentaz Theme */
  DarqMagentaz = 'darq_magentaz',
  /** Drift Wood Theme */
  DriftWood = 'drift_wood',
  /** Flush Cararra Theme */
  FlushCararra = 'flush_cararra',
  /** Gray Acapulco Theme */
  GrayAcapulco = 'gray_acapulco',
  /** Gray Tundora Theme */
  GrayTundora = 'gray_tundora',
  /** Mine Shaft Theme */
  MineShaft = 'mine_shaft',
  /** Pink Space Theme */
  PinkSpace = 'pink_space',
  /** Planetz Blue Theme */
  PlanetzBlue = 'planetz_blue',
  /** Purple Pearl Theme */
  PurplePearl = 'purple_pearl',
  /** Rogue Bluewood Theme */
  RogueBluewood = 'rogue_bluewood',
  /** Salsa Red Theme */
  SalsaRed = 'salsa_red',
  /** Softy Blues Theme */
  SoftyBlues = 'softy_blues',
  /** Terra Contessa Theme */
  TerraContessa = 'terra_contessa',
  /** Yellow Vannilaz Theme */
  YellowVannilaz = 'yellow_vannilaz',
}

/** Boolean expression to compare columns of type "enum_store_theme_enum". All fields are combined with logical 'AND'. */
export type Enum_Store_Theme_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Store_Theme_Enum>;
  _in?: InputMaybe<Array<Enum_Store_Theme_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Enum_Store_Theme_Enum>;
  _nin?: InputMaybe<Array<Enum_Store_Theme_Enum>>;
};

/** input type for inserting data into table "enum_store_theme" */
export type Enum_Store_Theme_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<Users_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_Store_Theme_Max_Fields = {
  __typename?: 'enum_store_theme_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Enum_Store_Theme_Min_Fields = {
  __typename?: 'enum_store_theme_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "enum_store_theme" */
export type Enum_Store_Theme_Mutation_Response = {
  __typename?: 'enum_store_theme_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Store_Theme>;
};

/** input type for inserting object relation for remote table "enum_store_theme" */
export type Enum_Store_Theme_Obj_Rel_Insert_Input = {
  data: Enum_Store_Theme_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enum_Store_Theme_On_Conflict>;
};

/** on_conflict condition type for table "enum_store_theme" */
export type Enum_Store_Theme_On_Conflict = {
  constraint: Enum_Store_Theme_Constraint;
  update_columns?: Array<Enum_Store_Theme_Update_Column>;
  where?: InputMaybe<Enum_Store_Theme_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_store_theme". */
export type Enum_Store_Theme_Order_By = {
  comment?: InputMaybe<Order_By>;
  users_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_store_theme */
export type Enum_Store_Theme_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_store_theme" */
export enum Enum_Store_Theme_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "enum_store_theme" */
export type Enum_Store_Theme_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "enum_store_theme" */
export enum Enum_Store_Theme_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Get_Upload_Url_Output = {
  __typename?: 'get_upload_url_Output';
  presigned_url: Scalars['String'];
};

export type Get_Upload_Url_Input = {
  file_path: Scalars['String'];
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

export type Login_Magic_Link_Input = {
  did_token: Scalars['String'];
};

export type Login_Magic_Link_Output = {
  __typename?: 'login_magic_link_output';
  account_id: Scalars['String'];
  jwt_token: Scalars['String'];
  new_account: Scalars['Boolean'];
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  connect_chain?: Maybe<ConnectChainOutput>;
  /** delete data from the table: "account_providers" */
  delete_account_providers?: Maybe<Account_Providers_Mutation_Response>;
  /** delete single row from the table: "account_providers" */
  delete_account_providers_by_pk?: Maybe<Account_Providers>;
  /** delete data from the table: "accounts" */
  delete_accounts?: Maybe<Accounts_Mutation_Response>;
  /** delete single row from the table: "accounts" */
  delete_accounts_by_pk?: Maybe<Accounts>;
  /** delete data from the table: "enum_blockchain_name" */
  delete_enum_blockchain_name?: Maybe<Enum_Blockchain_Name_Mutation_Response>;
  /** delete single row from the table: "enum_blockchain_name" */
  delete_enum_blockchain_name_by_pk?: Maybe<Enum_Blockchain_Name>;
  /** delete data from the table: "enum_display_location" */
  delete_enum_display_location?: Maybe<Enum_Display_Location_Mutation_Response>;
  /** delete single row from the table: "enum_display_location" */
  delete_enum_display_location_by_pk?: Maybe<Enum_Display_Location>;
  /** delete data from the table: "enum_marketplace" */
  delete_enum_marketplace?: Maybe<Enum_Marketplace_Mutation_Response>;
  /** delete single row from the table: "enum_marketplace" */
  delete_enum_marketplace_by_pk?: Maybe<Enum_Marketplace>;
  /** delete data from the table: "enum_nft_type" */
  delete_enum_nft_type?: Maybe<Enum_Nft_Type_Mutation_Response>;
  /** delete single row from the table: "enum_nft_type" */
  delete_enum_nft_type_by_pk?: Maybe<Enum_Nft_Type>;
  /** delete data from the table: "enum_providers" */
  delete_enum_providers?: Maybe<Enum_Providers_Mutation_Response>;
  /** delete single row from the table: "enum_providers" */
  delete_enum_providers_by_pk?: Maybe<Enum_Providers>;
  /** delete data from the table: "enum_sale_kind" */
  delete_enum_sale_kind?: Maybe<Enum_Sale_Kind_Mutation_Response>;
  /** delete single row from the table: "enum_sale_kind" */
  delete_enum_sale_kind_by_pk?: Maybe<Enum_Sale_Kind>;
  /** delete data from the table: "enum_storage_object_type" */
  delete_enum_storage_object_type?: Maybe<Enum_Storage_Object_Type_Mutation_Response>;
  /** delete single row from the table: "enum_storage_object_type" */
  delete_enum_storage_object_type_by_pk?: Maybe<Enum_Storage_Object_Type>;
  /** delete data from the table: "enum_store_theme" */
  delete_enum_store_theme?: Maybe<Enum_Store_Theme_Mutation_Response>;
  /** delete single row from the table: "enum_store_theme" */
  delete_enum_store_theme_by_pk?: Maybe<Enum_Store_Theme>;
  /** delete data from the table: "nft_tokens" */
  delete_nft_tokens?: Maybe<Nft_Tokens_Mutation_Response>;
  /** delete single row from the table: "nft_tokens" */
  delete_nft_tokens_by_pk?: Maybe<Nft_Tokens>;
  /** delete data from the table: "nft_tokens_created" */
  delete_nft_tokens_created?: Maybe<Nft_Tokens_Created_Mutation_Response>;
  /** delete single row from the table: "nft_tokens_created" */
  delete_nft_tokens_created_by_pk?: Maybe<Nft_Tokens_Created>;
  /** delete data from the table: "storage_objects" */
  delete_storage_objects?: Maybe<Storage_Objects_Mutation_Response>;
  /** delete single row from the table: "storage_objects" */
  delete_storage_objects_by_pk?: Maybe<Storage_Objects>;
  /** delete data from the table: "user_linked_nft_token" */
  delete_user_linked_nft_token?: Maybe<User_Linked_Nft_Token_Mutation_Response>;
  /** delete single row from the table: "user_linked_nft_token" */
  delete_user_linked_nft_token_by_pk?: Maybe<User_Linked_Nft_Token>;
  /** delete data from the table: "user_links" */
  delete_user_links?: Maybe<User_Links_Mutation_Response>;
  /** delete single row from the table: "user_links" */
  delete_user_links_by_pk?: Maybe<User_Links>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "wallet_providers" */
  delete_wallet_providers?: Maybe<Wallet_Providers_Mutation_Response>;
  /** delete single row from the table: "wallet_providers" */
  delete_wallet_providers_by_pk?: Maybe<Wallet_Providers>;
  get_nfts?: Maybe<GetNftsOutput>;
  get_nonce?: Maybe<GetNonceOutput>;
  get_upload_url?: Maybe<Get_Upload_Url_Output>;
  /** insert data into the table: "account_providers" */
  insert_account_providers?: Maybe<Account_Providers_Mutation_Response>;
  /** insert a single row into the table: "account_providers" */
  insert_account_providers_one?: Maybe<Account_Providers>;
  /** insert data into the table: "accounts" */
  insert_accounts?: Maybe<Accounts_Mutation_Response>;
  /** insert a single row into the table: "accounts" */
  insert_accounts_one?: Maybe<Accounts>;
  /** insert data into the table: "enum_blockchain_name" */
  insert_enum_blockchain_name?: Maybe<Enum_Blockchain_Name_Mutation_Response>;
  /** insert a single row into the table: "enum_blockchain_name" */
  insert_enum_blockchain_name_one?: Maybe<Enum_Blockchain_Name>;
  /** insert data into the table: "enum_display_location" */
  insert_enum_display_location?: Maybe<Enum_Display_Location_Mutation_Response>;
  /** insert a single row into the table: "enum_display_location" */
  insert_enum_display_location_one?: Maybe<Enum_Display_Location>;
  /** insert data into the table: "enum_marketplace" */
  insert_enum_marketplace?: Maybe<Enum_Marketplace_Mutation_Response>;
  /** insert a single row into the table: "enum_marketplace" */
  insert_enum_marketplace_one?: Maybe<Enum_Marketplace>;
  /** insert data into the table: "enum_nft_type" */
  insert_enum_nft_type?: Maybe<Enum_Nft_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_nft_type" */
  insert_enum_nft_type_one?: Maybe<Enum_Nft_Type>;
  /** insert data into the table: "enum_providers" */
  insert_enum_providers?: Maybe<Enum_Providers_Mutation_Response>;
  /** insert a single row into the table: "enum_providers" */
  insert_enum_providers_one?: Maybe<Enum_Providers>;
  /** insert data into the table: "enum_sale_kind" */
  insert_enum_sale_kind?: Maybe<Enum_Sale_Kind_Mutation_Response>;
  /** insert a single row into the table: "enum_sale_kind" */
  insert_enum_sale_kind_one?: Maybe<Enum_Sale_Kind>;
  /** insert data into the table: "enum_storage_object_type" */
  insert_enum_storage_object_type?: Maybe<Enum_Storage_Object_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_storage_object_type" */
  insert_enum_storage_object_type_one?: Maybe<Enum_Storage_Object_Type>;
  /** insert data into the table: "enum_store_theme" */
  insert_enum_store_theme?: Maybe<Enum_Store_Theme_Mutation_Response>;
  /** insert a single row into the table: "enum_store_theme" */
  insert_enum_store_theme_one?: Maybe<Enum_Store_Theme>;
  /** insert data into the table: "nft_tokens" */
  insert_nft_tokens?: Maybe<Nft_Tokens_Mutation_Response>;
  /** insert data into the table: "nft_tokens_created" */
  insert_nft_tokens_created?: Maybe<Nft_Tokens_Created_Mutation_Response>;
  /** insert a single row into the table: "nft_tokens_created" */
  insert_nft_tokens_created_one?: Maybe<Nft_Tokens_Created>;
  /** insert a single row into the table: "nft_tokens" */
  insert_nft_tokens_one?: Maybe<Nft_Tokens>;
  /** insert data into the table: "storage_objects" */
  insert_storage_objects?: Maybe<Storage_Objects_Mutation_Response>;
  /** insert a single row into the table: "storage_objects" */
  insert_storage_objects_one?: Maybe<Storage_Objects>;
  /** insert data into the table: "user_linked_nft_token" */
  insert_user_linked_nft_token?: Maybe<User_Linked_Nft_Token_Mutation_Response>;
  /** insert a single row into the table: "user_linked_nft_token" */
  insert_user_linked_nft_token_one?: Maybe<User_Linked_Nft_Token>;
  /** insert data into the table: "user_links" */
  insert_user_links?: Maybe<User_Links_Mutation_Response>;
  /** insert a single row into the table: "user_links" */
  insert_user_links_one?: Maybe<User_Links>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "wallet_providers" */
  insert_wallet_providers?: Maybe<Wallet_Providers_Mutation_Response>;
  /** insert a single row into the table: "wallet_providers" */
  insert_wallet_providers_one?: Maybe<Wallet_Providers>;
  login_magic_link?: Maybe<Login_Magic_Link_Output>;
  refresh_created_nfts?: Maybe<RefreshCreatedNftOutput>;
  /** update data of the table: "account_providers" */
  update_account_providers?: Maybe<Account_Providers_Mutation_Response>;
  /** update single row of the table: "account_providers" */
  update_account_providers_by_pk?: Maybe<Account_Providers>;
  /** update data of the table: "accounts" */
  update_accounts?: Maybe<Accounts_Mutation_Response>;
  /** update single row of the table: "accounts" */
  update_accounts_by_pk?: Maybe<Accounts>;
  /** update data of the table: "enum_blockchain_name" */
  update_enum_blockchain_name?: Maybe<Enum_Blockchain_Name_Mutation_Response>;
  /** update single row of the table: "enum_blockchain_name" */
  update_enum_blockchain_name_by_pk?: Maybe<Enum_Blockchain_Name>;
  /** update data of the table: "enum_display_location" */
  update_enum_display_location?: Maybe<Enum_Display_Location_Mutation_Response>;
  /** update single row of the table: "enum_display_location" */
  update_enum_display_location_by_pk?: Maybe<Enum_Display_Location>;
  /** update data of the table: "enum_marketplace" */
  update_enum_marketplace?: Maybe<Enum_Marketplace_Mutation_Response>;
  /** update single row of the table: "enum_marketplace" */
  update_enum_marketplace_by_pk?: Maybe<Enum_Marketplace>;
  /** update data of the table: "enum_nft_type" */
  update_enum_nft_type?: Maybe<Enum_Nft_Type_Mutation_Response>;
  /** update single row of the table: "enum_nft_type" */
  update_enum_nft_type_by_pk?: Maybe<Enum_Nft_Type>;
  /** update data of the table: "enum_providers" */
  update_enum_providers?: Maybe<Enum_Providers_Mutation_Response>;
  /** update single row of the table: "enum_providers" */
  update_enum_providers_by_pk?: Maybe<Enum_Providers>;
  /** update data of the table: "enum_sale_kind" */
  update_enum_sale_kind?: Maybe<Enum_Sale_Kind_Mutation_Response>;
  /** update single row of the table: "enum_sale_kind" */
  update_enum_sale_kind_by_pk?: Maybe<Enum_Sale_Kind>;
  /** update data of the table: "enum_storage_object_type" */
  update_enum_storage_object_type?: Maybe<Enum_Storage_Object_Type_Mutation_Response>;
  /** update single row of the table: "enum_storage_object_type" */
  update_enum_storage_object_type_by_pk?: Maybe<Enum_Storage_Object_Type>;
  /** update data of the table: "enum_store_theme" */
  update_enum_store_theme?: Maybe<Enum_Store_Theme_Mutation_Response>;
  /** update single row of the table: "enum_store_theme" */
  update_enum_store_theme_by_pk?: Maybe<Enum_Store_Theme>;
  /** update data of the table: "nft_tokens" */
  update_nft_tokens?: Maybe<Nft_Tokens_Mutation_Response>;
  /** update single row of the table: "nft_tokens" */
  update_nft_tokens_by_pk?: Maybe<Nft_Tokens>;
  /** update data of the table: "nft_tokens_created" */
  update_nft_tokens_created?: Maybe<Nft_Tokens_Created_Mutation_Response>;
  /** update single row of the table: "nft_tokens_created" */
  update_nft_tokens_created_by_pk?: Maybe<Nft_Tokens_Created>;
  /** update data of the table: "storage_objects" */
  update_storage_objects?: Maybe<Storage_Objects_Mutation_Response>;
  /** update single row of the table: "storage_objects" */
  update_storage_objects_by_pk?: Maybe<Storage_Objects>;
  /** update data of the table: "user_linked_nft_token" */
  update_user_linked_nft_token?: Maybe<User_Linked_Nft_Token_Mutation_Response>;
  /** update single row of the table: "user_linked_nft_token" */
  update_user_linked_nft_token_by_pk?: Maybe<User_Linked_Nft_Token>;
  /** update data of the table: "user_links" */
  update_user_links?: Maybe<User_Links_Mutation_Response>;
  /** update single row of the table: "user_links" */
  update_user_links_by_pk?: Maybe<User_Links>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update data of the table: "wallet_providers" */
  update_wallet_providers?: Maybe<Wallet_Providers_Mutation_Response>;
  /** update single row of the table: "wallet_providers" */
  update_wallet_providers_by_pk?: Maybe<Wallet_Providers>;
  verify_session?: Maybe<Verify_Session_Output>;
  verify_signature?: Maybe<VerifySignatureOutput>;
};

/** mutation root */
export type Mutation_RootConnect_ChainArgs = {
  object: ConnectChainInput;
};

/** mutation root */
export type Mutation_RootDelete_Account_ProvidersArgs = {
  where: Account_Providers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Account_Providers_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_AccountsArgs = {
  where: Accounts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Accounts_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Blockchain_NameArgs = {
  where: Enum_Blockchain_Name_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Blockchain_Name_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Display_LocationArgs = {
  where: Enum_Display_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Display_Location_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_MarketplaceArgs = {
  where: Enum_Marketplace_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Marketplace_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Nft_TypeArgs = {
  where: Enum_Nft_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Nft_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_ProvidersArgs = {
  where: Enum_Providers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Providers_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Sale_KindArgs = {
  where: Enum_Sale_Kind_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Sale_Kind_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Storage_Object_TypeArgs = {
  where: Enum_Storage_Object_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Storage_Object_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Enum_Store_ThemeArgs = {
  where: Enum_Store_Theme_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enum_Store_Theme_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Nft_TokensArgs = {
  where: Nft_Tokens_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Nft_Tokens_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Nft_Tokens_CreatedArgs = {
  where: Nft_Tokens_Created_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Nft_Tokens_Created_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Storage_ObjectsArgs = {
  where: Storage_Objects_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Storage_Objects_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_User_Linked_Nft_TokenArgs = {
  where: User_Linked_Nft_Token_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Linked_Nft_Token_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_User_LinksArgs = {
  where: User_Links_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Links_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Wallet_ProvidersArgs = {
  where: Wallet_Providers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Wallet_Providers_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootGet_NftsArgs = {
  object: GetNftsInput;
};

/** mutation root */
export type Mutation_RootGet_NonceArgs = {
  object: GetNonceInput;
};

/** mutation root */
export type Mutation_RootGet_Upload_UrlArgs = {
  object: Get_Upload_Url_Input;
};

/** mutation root */
export type Mutation_RootInsert_Account_ProvidersArgs = {
  objects: Array<Account_Providers_Insert_Input>;
  on_conflict?: InputMaybe<Account_Providers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Account_Providers_OneArgs = {
  object: Account_Providers_Insert_Input;
  on_conflict?: InputMaybe<Account_Providers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_AccountsArgs = {
  objects: Array<Accounts_Insert_Input>;
  on_conflict?: InputMaybe<Accounts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Accounts_OneArgs = {
  object: Accounts_Insert_Input;
  on_conflict?: InputMaybe<Accounts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Blockchain_NameArgs = {
  objects: Array<Enum_Blockchain_Name_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Blockchain_Name_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Blockchain_Name_OneArgs = {
  object: Enum_Blockchain_Name_Insert_Input;
  on_conflict?: InputMaybe<Enum_Blockchain_Name_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Display_LocationArgs = {
  objects: Array<Enum_Display_Location_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Display_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Display_Location_OneArgs = {
  object: Enum_Display_Location_Insert_Input;
  on_conflict?: InputMaybe<Enum_Display_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_MarketplaceArgs = {
  objects: Array<Enum_Marketplace_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Marketplace_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Marketplace_OneArgs = {
  object: Enum_Marketplace_Insert_Input;
  on_conflict?: InputMaybe<Enum_Marketplace_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Nft_TypeArgs = {
  objects: Array<Enum_Nft_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Nft_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Nft_Type_OneArgs = {
  object: Enum_Nft_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Nft_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_ProvidersArgs = {
  objects: Array<Enum_Providers_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Providers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Providers_OneArgs = {
  object: Enum_Providers_Insert_Input;
  on_conflict?: InputMaybe<Enum_Providers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Sale_KindArgs = {
  objects: Array<Enum_Sale_Kind_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Sale_Kind_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Sale_Kind_OneArgs = {
  object: Enum_Sale_Kind_Insert_Input;
  on_conflict?: InputMaybe<Enum_Sale_Kind_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Storage_Object_TypeArgs = {
  objects: Array<Enum_Storage_Object_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Storage_Object_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Storage_Object_Type_OneArgs = {
  object: Enum_Storage_Object_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Storage_Object_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Store_ThemeArgs = {
  objects: Array<Enum_Store_Theme_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Store_Theme_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enum_Store_Theme_OneArgs = {
  object: Enum_Store_Theme_Insert_Input;
  on_conflict?: InputMaybe<Enum_Store_Theme_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Nft_TokensArgs = {
  objects: Array<Nft_Tokens_Insert_Input>;
  on_conflict?: InputMaybe<Nft_Tokens_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Nft_Tokens_CreatedArgs = {
  objects: Array<Nft_Tokens_Created_Insert_Input>;
  on_conflict?: InputMaybe<Nft_Tokens_Created_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Nft_Tokens_Created_OneArgs = {
  object: Nft_Tokens_Created_Insert_Input;
  on_conflict?: InputMaybe<Nft_Tokens_Created_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Nft_Tokens_OneArgs = {
  object: Nft_Tokens_Insert_Input;
  on_conflict?: InputMaybe<Nft_Tokens_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Storage_ObjectsArgs = {
  objects: Array<Storage_Objects_Insert_Input>;
  on_conflict?: InputMaybe<Storage_Objects_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Storage_Objects_OneArgs = {
  object: Storage_Objects_Insert_Input;
  on_conflict?: InputMaybe<Storage_Objects_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Linked_Nft_TokenArgs = {
  objects: Array<User_Linked_Nft_Token_Insert_Input>;
  on_conflict?: InputMaybe<User_Linked_Nft_Token_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Linked_Nft_Token_OneArgs = {
  object: User_Linked_Nft_Token_Insert_Input;
  on_conflict?: InputMaybe<User_Linked_Nft_Token_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_LinksArgs = {
  objects: Array<User_Links_Insert_Input>;
  on_conflict?: InputMaybe<User_Links_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Links_OneArgs = {
  object: User_Links_Insert_Input;
  on_conflict?: InputMaybe<User_Links_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Wallet_ProvidersArgs = {
  objects: Array<Wallet_Providers_Insert_Input>;
  on_conflict?: InputMaybe<Wallet_Providers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Wallet_Providers_OneArgs = {
  object: Wallet_Providers_Insert_Input;
  on_conflict?: InputMaybe<Wallet_Providers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootLogin_Magic_LinkArgs = {
  object: Login_Magic_Link_Input;
};

/** mutation root */
export type Mutation_RootRefresh_Created_NftsArgs = {
  object: RefreshCreatedNftInput;
};

/** mutation root */
export type Mutation_RootUpdate_Account_ProvidersArgs = {
  _append?: InputMaybe<Account_Providers_Append_Input>;
  _delete_at_path?: InputMaybe<Account_Providers_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Account_Providers_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Account_Providers_Delete_Key_Input>;
  _prepend?: InputMaybe<Account_Providers_Prepend_Input>;
  _set?: InputMaybe<Account_Providers_Set_Input>;
  where: Account_Providers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Account_Providers_By_PkArgs = {
  _append?: InputMaybe<Account_Providers_Append_Input>;
  _delete_at_path?: InputMaybe<Account_Providers_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Account_Providers_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Account_Providers_Delete_Key_Input>;
  _prepend?: InputMaybe<Account_Providers_Prepend_Input>;
  _set?: InputMaybe<Account_Providers_Set_Input>;
  pk_columns: Account_Providers_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_AccountsArgs = {
  _set?: InputMaybe<Accounts_Set_Input>;
  where: Accounts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Accounts_By_PkArgs = {
  _set?: InputMaybe<Accounts_Set_Input>;
  pk_columns: Accounts_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Blockchain_NameArgs = {
  _set?: InputMaybe<Enum_Blockchain_Name_Set_Input>;
  where: Enum_Blockchain_Name_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Blockchain_Name_By_PkArgs = {
  _set?: InputMaybe<Enum_Blockchain_Name_Set_Input>;
  pk_columns: Enum_Blockchain_Name_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Display_LocationArgs = {
  _set?: InputMaybe<Enum_Display_Location_Set_Input>;
  where: Enum_Display_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Display_Location_By_PkArgs = {
  _set?: InputMaybe<Enum_Display_Location_Set_Input>;
  pk_columns: Enum_Display_Location_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_MarketplaceArgs = {
  _set?: InputMaybe<Enum_Marketplace_Set_Input>;
  where: Enum_Marketplace_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Marketplace_By_PkArgs = {
  _set?: InputMaybe<Enum_Marketplace_Set_Input>;
  pk_columns: Enum_Marketplace_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Nft_TypeArgs = {
  _set?: InputMaybe<Enum_Nft_Type_Set_Input>;
  where: Enum_Nft_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Nft_Type_By_PkArgs = {
  _set?: InputMaybe<Enum_Nft_Type_Set_Input>;
  pk_columns: Enum_Nft_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_ProvidersArgs = {
  _set?: InputMaybe<Enum_Providers_Set_Input>;
  where: Enum_Providers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Providers_By_PkArgs = {
  _set?: InputMaybe<Enum_Providers_Set_Input>;
  pk_columns: Enum_Providers_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Sale_KindArgs = {
  _set?: InputMaybe<Enum_Sale_Kind_Set_Input>;
  where: Enum_Sale_Kind_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Sale_Kind_By_PkArgs = {
  _set?: InputMaybe<Enum_Sale_Kind_Set_Input>;
  pk_columns: Enum_Sale_Kind_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Storage_Object_TypeArgs = {
  _set?: InputMaybe<Enum_Storage_Object_Type_Set_Input>;
  where: Enum_Storage_Object_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Storage_Object_Type_By_PkArgs = {
  _set?: InputMaybe<Enum_Storage_Object_Type_Set_Input>;
  pk_columns: Enum_Storage_Object_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Store_ThemeArgs = {
  _set?: InputMaybe<Enum_Store_Theme_Set_Input>;
  where: Enum_Store_Theme_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enum_Store_Theme_By_PkArgs = {
  _set?: InputMaybe<Enum_Store_Theme_Set_Input>;
  pk_columns: Enum_Store_Theme_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Nft_TokensArgs = {
  _append?: InputMaybe<Nft_Tokens_Append_Input>;
  _delete_at_path?: InputMaybe<Nft_Tokens_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Nft_Tokens_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Nft_Tokens_Delete_Key_Input>;
  _prepend?: InputMaybe<Nft_Tokens_Prepend_Input>;
  _set?: InputMaybe<Nft_Tokens_Set_Input>;
  where: Nft_Tokens_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Nft_Tokens_By_PkArgs = {
  _append?: InputMaybe<Nft_Tokens_Append_Input>;
  _delete_at_path?: InputMaybe<Nft_Tokens_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Nft_Tokens_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Nft_Tokens_Delete_Key_Input>;
  _prepend?: InputMaybe<Nft_Tokens_Prepend_Input>;
  _set?: InputMaybe<Nft_Tokens_Set_Input>;
  pk_columns: Nft_Tokens_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Nft_Tokens_CreatedArgs = {
  _append?: InputMaybe<Nft_Tokens_Created_Append_Input>;
  _delete_at_path?: InputMaybe<Nft_Tokens_Created_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Nft_Tokens_Created_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Nft_Tokens_Created_Delete_Key_Input>;
  _prepend?: InputMaybe<Nft_Tokens_Created_Prepend_Input>;
  _set?: InputMaybe<Nft_Tokens_Created_Set_Input>;
  where: Nft_Tokens_Created_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Nft_Tokens_Created_By_PkArgs = {
  _append?: InputMaybe<Nft_Tokens_Created_Append_Input>;
  _delete_at_path?: InputMaybe<Nft_Tokens_Created_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Nft_Tokens_Created_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Nft_Tokens_Created_Delete_Key_Input>;
  _prepend?: InputMaybe<Nft_Tokens_Created_Prepend_Input>;
  _set?: InputMaybe<Nft_Tokens_Created_Set_Input>;
  pk_columns: Nft_Tokens_Created_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Storage_ObjectsArgs = {
  _append?: InputMaybe<Storage_Objects_Append_Input>;
  _delete_at_path?: InputMaybe<Storage_Objects_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Storage_Objects_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Storage_Objects_Delete_Key_Input>;
  _prepend?: InputMaybe<Storage_Objects_Prepend_Input>;
  _set?: InputMaybe<Storage_Objects_Set_Input>;
  where: Storage_Objects_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Storage_Objects_By_PkArgs = {
  _append?: InputMaybe<Storage_Objects_Append_Input>;
  _delete_at_path?: InputMaybe<Storage_Objects_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Storage_Objects_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Storage_Objects_Delete_Key_Input>;
  _prepend?: InputMaybe<Storage_Objects_Prepend_Input>;
  _set?: InputMaybe<Storage_Objects_Set_Input>;
  pk_columns: Storage_Objects_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Linked_Nft_TokenArgs = {
  _set?: InputMaybe<User_Linked_Nft_Token_Set_Input>;
  where: User_Linked_Nft_Token_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Linked_Nft_Token_By_PkArgs = {
  _set?: InputMaybe<User_Linked_Nft_Token_Set_Input>;
  pk_columns: User_Linked_Nft_Token_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_LinksArgs = {
  _set?: InputMaybe<User_Links_Set_Input>;
  where: User_Links_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Links_By_PkArgs = {
  _set?: InputMaybe<User_Links_Set_Input>;
  pk_columns: User_Links_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _append?: InputMaybe<Users_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  _prepend?: InputMaybe<Users_Prepend_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _append?: InputMaybe<Users_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  _prepend?: InputMaybe<Users_Prepend_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Wallet_ProvidersArgs = {
  _append?: InputMaybe<Wallet_Providers_Append_Input>;
  _delete_at_path?: InputMaybe<Wallet_Providers_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Wallet_Providers_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Wallet_Providers_Delete_Key_Input>;
  _prepend?: InputMaybe<Wallet_Providers_Prepend_Input>;
  _set?: InputMaybe<Wallet_Providers_Set_Input>;
  where: Wallet_Providers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Wallet_Providers_By_PkArgs = {
  _append?: InputMaybe<Wallet_Providers_Append_Input>;
  _delete_at_path?: InputMaybe<Wallet_Providers_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Wallet_Providers_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Wallet_Providers_Delete_Key_Input>;
  _prepend?: InputMaybe<Wallet_Providers_Prepend_Input>;
  _set?: InputMaybe<Wallet_Providers_Set_Input>;
  pk_columns: Wallet_Providers_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootVerify_SessionArgs = {
  object: Verify_Session_Input;
};

/** mutation root */
export type Mutation_RootVerify_SignatureArgs = {
  object: VerifySignatureInput;
};

/** columns and relationships of "nft_tokens" */
export type Nft_Tokens = {
  __typename?: 'nft_tokens';
  asset_org_url?: Maybe<Scalars['String']>;
  asset_thumbnail_url?: Maybe<Scalars['String']>;
  asset_type?: Maybe<Scalars['String']>;
  asset_url?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  current_price?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** An object relationship */
  enum_marketplace: Enum_Marketplace;
  /** An object relationship */
  enum_sale_kind?: Maybe<Enum_Sale_Kind>;
  id: Scalars['uuid'];
  marketplace: Enum_Marketplace_Enum;
  metadata?: Maybe<Scalars['jsonb']>;
  name?: Maybe<Scalars['String']>;
  order_type?: Maybe<Scalars['String']>;
  owner_address?: Maybe<Scalars['String']>;
  sale_type?: Maybe<Enum_Sale_Kind_Enum>;
  symbol?: Maybe<Scalars['String']>;
  token_address: Scalars['String'];
  token_id: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  visible?: Maybe<Scalars['Boolean']>;
  wallet_address: Scalars['String'];
};

/** columns and relationships of "nft_tokens" */
export type Nft_TokensMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "nft_tokens" */
export type Nft_Tokens_Aggregate = {
  __typename?: 'nft_tokens_aggregate';
  aggregate?: Maybe<Nft_Tokens_Aggregate_Fields>;
  nodes: Array<Nft_Tokens>;
};

/** aggregate fields of "nft_tokens" */
export type Nft_Tokens_Aggregate_Fields = {
  __typename?: 'nft_tokens_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nft_Tokens_Max_Fields>;
  min?: Maybe<Nft_Tokens_Min_Fields>;
};

/** aggregate fields of "nft_tokens" */
export type Nft_Tokens_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nft_Tokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nft_tokens" */
export type Nft_Tokens_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nft_Tokens_Max_Order_By>;
  min?: InputMaybe<Nft_Tokens_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Nft_Tokens_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "nft_tokens" */
export type Nft_Tokens_Arr_Rel_Insert_Input = {
  data: Array<Nft_Tokens_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nft_Tokens_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nft_tokens". All fields are combined with a logical 'AND'. */
export type Nft_Tokens_Bool_Exp = {
  _and?: InputMaybe<Array<Nft_Tokens_Bool_Exp>>;
  _not?: InputMaybe<Nft_Tokens_Bool_Exp>;
  _or?: InputMaybe<Array<Nft_Tokens_Bool_Exp>>;
  asset_org_url?: InputMaybe<String_Comparison_Exp>;
  asset_thumbnail_url?: InputMaybe<String_Comparison_Exp>;
  asset_type?: InputMaybe<String_Comparison_Exp>;
  asset_url?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  current_price?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  enum_marketplace?: InputMaybe<Enum_Marketplace_Bool_Exp>;
  enum_sale_kind?: InputMaybe<Enum_Sale_Kind_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  marketplace?: InputMaybe<Enum_Marketplace_Enum_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order_type?: InputMaybe<String_Comparison_Exp>;
  owner_address?: InputMaybe<String_Comparison_Exp>;
  sale_type?: InputMaybe<Enum_Sale_Kind_Enum_Comparison_Exp>;
  symbol?: InputMaybe<String_Comparison_Exp>;
  token_address?: InputMaybe<String_Comparison_Exp>;
  token_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  visible?: InputMaybe<Boolean_Comparison_Exp>;
  wallet_address?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "nft_tokens" */
export enum Nft_Tokens_Constraint {
  /** unique or primary key constraint on columns "id" */
  NftTokensPkey = 'nft_tokens_pkey',
  /** unique or primary key constraint on columns "token_address", "token_id" */
  NftTokensTokenIdTokenAddressKey = 'nft_tokens_token_id_token_address_key',
}

/** columns and relationships of "nft_tokens_created" */
export type Nft_Tokens_Created = {
  __typename?: 'nft_tokens_created';
  asset_org_url?: Maybe<Scalars['String']>;
  asset_thumbnail_url?: Maybe<Scalars['String']>;
  asset_type?: Maybe<Scalars['String']>;
  asset_url?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  current_price?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** An object relationship */
  enum_marketplace: Enum_Marketplace;
  /** An object relationship */
  enum_sale_kind?: Maybe<Enum_Sale_Kind>;
  id: Scalars['uuid'];
  marketplace: Enum_Marketplace_Enum;
  metadata?: Maybe<Scalars['jsonb']>;
  name?: Maybe<Scalars['String']>;
  order_type?: Maybe<Scalars['String']>;
  owner_address?: Maybe<Scalars['String']>;
  sale_type?: Maybe<Enum_Sale_Kind_Enum>;
  symbol?: Maybe<Scalars['String']>;
  token_address: Scalars['String'];
  token_id: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  visible?: Maybe<Scalars['Boolean']>;
  wallet_address: Scalars['String'];
};

/** columns and relationships of "nft_tokens_created" */
export type Nft_Tokens_CreatedMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "nft_tokens_created" */
export type Nft_Tokens_Created_Aggregate = {
  __typename?: 'nft_tokens_created_aggregate';
  aggregate?: Maybe<Nft_Tokens_Created_Aggregate_Fields>;
  nodes: Array<Nft_Tokens_Created>;
};

/** aggregate fields of "nft_tokens_created" */
export type Nft_Tokens_Created_Aggregate_Fields = {
  __typename?: 'nft_tokens_created_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nft_Tokens_Created_Max_Fields>;
  min?: Maybe<Nft_Tokens_Created_Min_Fields>;
};

/** aggregate fields of "nft_tokens_created" */
export type Nft_Tokens_Created_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nft_Tokens_Created_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "nft_tokens_created" */
export type Nft_Tokens_Created_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nft_Tokens_Created_Max_Order_By>;
  min?: InputMaybe<Nft_Tokens_Created_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Nft_Tokens_Created_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "nft_tokens_created" */
export type Nft_Tokens_Created_Arr_Rel_Insert_Input = {
  data: Array<Nft_Tokens_Created_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nft_Tokens_Created_On_Conflict>;
};

/** Boolean expression to filter rows from the table "nft_tokens_created". All fields are combined with a logical 'AND'. */
export type Nft_Tokens_Created_Bool_Exp = {
  _and?: InputMaybe<Array<Nft_Tokens_Created_Bool_Exp>>;
  _not?: InputMaybe<Nft_Tokens_Created_Bool_Exp>;
  _or?: InputMaybe<Array<Nft_Tokens_Created_Bool_Exp>>;
  asset_org_url?: InputMaybe<String_Comparison_Exp>;
  asset_thumbnail_url?: InputMaybe<String_Comparison_Exp>;
  asset_type?: InputMaybe<String_Comparison_Exp>;
  asset_url?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  current_price?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  enum_marketplace?: InputMaybe<Enum_Marketplace_Bool_Exp>;
  enum_sale_kind?: InputMaybe<Enum_Sale_Kind_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  marketplace?: InputMaybe<Enum_Marketplace_Enum_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order_type?: InputMaybe<String_Comparison_Exp>;
  owner_address?: InputMaybe<String_Comparison_Exp>;
  sale_type?: InputMaybe<Enum_Sale_Kind_Enum_Comparison_Exp>;
  symbol?: InputMaybe<String_Comparison_Exp>;
  token_address?: InputMaybe<String_Comparison_Exp>;
  token_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  visible?: InputMaybe<Boolean_Comparison_Exp>;
  wallet_address?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "nft_tokens_created" */
export enum Nft_Tokens_Created_Constraint {
  /** unique or primary key constraint on columns "id" */
  NftTokensCreatedPkey = 'nft_tokens_created_pkey',
  /** unique or primary key constraint on columns "token_address", "token_id" */
  NftTokensCreatedTokenIdTokenAddressKey = 'nft_tokens_created_token_id_token_address_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Nft_Tokens_Created_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Nft_Tokens_Created_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Nft_Tokens_Created_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "nft_tokens_created" */
export type Nft_Tokens_Created_Insert_Input = {
  asset_org_url?: InputMaybe<Scalars['String']>;
  asset_thumbnail_url?: InputMaybe<Scalars['String']>;
  asset_type?: InputMaybe<Scalars['String']>;
  asset_url?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  current_price?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  enum_marketplace?: InputMaybe<Enum_Marketplace_Obj_Rel_Insert_Input>;
  enum_sale_kind?: InputMaybe<Enum_Sale_Kind_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  marketplace?: InputMaybe<Enum_Marketplace_Enum>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  order_type?: InputMaybe<Scalars['String']>;
  owner_address?: InputMaybe<Scalars['String']>;
  sale_type?: InputMaybe<Enum_Sale_Kind_Enum>;
  symbol?: InputMaybe<Scalars['String']>;
  token_address?: InputMaybe<Scalars['String']>;
  token_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visible?: InputMaybe<Scalars['Boolean']>;
  wallet_address?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Nft_Tokens_Created_Max_Fields = {
  __typename?: 'nft_tokens_created_max_fields';
  asset_org_url?: Maybe<Scalars['String']>;
  asset_thumbnail_url?: Maybe<Scalars['String']>;
  asset_type?: Maybe<Scalars['String']>;
  asset_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current_price?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  order_type?: Maybe<Scalars['String']>;
  owner_address?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  token_address?: Maybe<Scalars['String']>;
  token_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wallet_address?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "nft_tokens_created" */
export type Nft_Tokens_Created_Max_Order_By = {
  asset_org_url?: InputMaybe<Order_By>;
  asset_thumbnail_url?: InputMaybe<Order_By>;
  asset_type?: InputMaybe<Order_By>;
  asset_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_price?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order_type?: InputMaybe<Order_By>;
  owner_address?: InputMaybe<Order_By>;
  symbol?: InputMaybe<Order_By>;
  token_address?: InputMaybe<Order_By>;
  token_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  wallet_address?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nft_Tokens_Created_Min_Fields = {
  __typename?: 'nft_tokens_created_min_fields';
  asset_org_url?: Maybe<Scalars['String']>;
  asset_thumbnail_url?: Maybe<Scalars['String']>;
  asset_type?: Maybe<Scalars['String']>;
  asset_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current_price?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  order_type?: Maybe<Scalars['String']>;
  owner_address?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  token_address?: Maybe<Scalars['String']>;
  token_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wallet_address?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "nft_tokens_created" */
export type Nft_Tokens_Created_Min_Order_By = {
  asset_org_url?: InputMaybe<Order_By>;
  asset_thumbnail_url?: InputMaybe<Order_By>;
  asset_type?: InputMaybe<Order_By>;
  asset_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_price?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order_type?: InputMaybe<Order_By>;
  owner_address?: InputMaybe<Order_By>;
  symbol?: InputMaybe<Order_By>;
  token_address?: InputMaybe<Order_By>;
  token_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  wallet_address?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nft_tokens_created" */
export type Nft_Tokens_Created_Mutation_Response = {
  __typename?: 'nft_tokens_created_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nft_Tokens_Created>;
};

/** on_conflict condition type for table "nft_tokens_created" */
export type Nft_Tokens_Created_On_Conflict = {
  constraint: Nft_Tokens_Created_Constraint;
  update_columns?: Array<Nft_Tokens_Created_Update_Column>;
  where?: InputMaybe<Nft_Tokens_Created_Bool_Exp>;
};

/** Ordering options when selecting data from "nft_tokens_created". */
export type Nft_Tokens_Created_Order_By = {
  asset_org_url?: InputMaybe<Order_By>;
  asset_thumbnail_url?: InputMaybe<Order_By>;
  asset_type?: InputMaybe<Order_By>;
  asset_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_price?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  enum_marketplace?: InputMaybe<Enum_Marketplace_Order_By>;
  enum_sale_kind?: InputMaybe<Enum_Sale_Kind_Order_By>;
  id?: InputMaybe<Order_By>;
  marketplace?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order_type?: InputMaybe<Order_By>;
  owner_address?: InputMaybe<Order_By>;
  sale_type?: InputMaybe<Order_By>;
  symbol?: InputMaybe<Order_By>;
  token_address?: InputMaybe<Order_By>;
  token_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  visible?: InputMaybe<Order_By>;
  wallet_address?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nft_tokens_created */
export type Nft_Tokens_Created_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Nft_Tokens_Created_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "nft_tokens_created" */
export enum Nft_Tokens_Created_Select_Column {
  /** column name */
  AssetOrgUrl = 'asset_org_url',
  /** column name */
  AssetThumbnailUrl = 'asset_thumbnail_url',
  /** column name */
  AssetType = 'asset_type',
  /** column name */
  AssetUrl = 'asset_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentPrice = 'current_price',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Marketplace = 'marketplace',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  OrderType = 'order_type',
  /** column name */
  OwnerAddress = 'owner_address',
  /** column name */
  SaleType = 'sale_type',
  /** column name */
  Symbol = 'symbol',
  /** column name */
  TokenAddress = 'token_address',
  /** column name */
  TokenId = 'token_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Visible = 'visible',
  /** column name */
  WalletAddress = 'wallet_address',
}

/** input type for updating data in table "nft_tokens_created" */
export type Nft_Tokens_Created_Set_Input = {
  asset_org_url?: InputMaybe<Scalars['String']>;
  asset_thumbnail_url?: InputMaybe<Scalars['String']>;
  asset_type?: InputMaybe<Scalars['String']>;
  asset_url?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  current_price?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  marketplace?: InputMaybe<Enum_Marketplace_Enum>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  order_type?: InputMaybe<Scalars['String']>;
  owner_address?: InputMaybe<Scalars['String']>;
  sale_type?: InputMaybe<Enum_Sale_Kind_Enum>;
  symbol?: InputMaybe<Scalars['String']>;
  token_address?: InputMaybe<Scalars['String']>;
  token_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visible?: InputMaybe<Scalars['Boolean']>;
  wallet_address?: InputMaybe<Scalars['String']>;
};

/** update columns of table "nft_tokens_created" */
export enum Nft_Tokens_Created_Update_Column {
  /** column name */
  AssetOrgUrl = 'asset_org_url',
  /** column name */
  AssetThumbnailUrl = 'asset_thumbnail_url',
  /** column name */
  AssetType = 'asset_type',
  /** column name */
  AssetUrl = 'asset_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentPrice = 'current_price',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Marketplace = 'marketplace',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  OrderType = 'order_type',
  /** column name */
  OwnerAddress = 'owner_address',
  /** column name */
  SaleType = 'sale_type',
  /** column name */
  Symbol = 'symbol',
  /** column name */
  TokenAddress = 'token_address',
  /** column name */
  TokenId = 'token_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Visible = 'visible',
  /** column name */
  WalletAddress = 'wallet_address',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Nft_Tokens_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Nft_Tokens_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Nft_Tokens_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "nft_tokens" */
export type Nft_Tokens_Insert_Input = {
  asset_org_url?: InputMaybe<Scalars['String']>;
  asset_thumbnail_url?: InputMaybe<Scalars['String']>;
  asset_type?: InputMaybe<Scalars['String']>;
  asset_url?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  current_price?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  enum_marketplace?: InputMaybe<Enum_Marketplace_Obj_Rel_Insert_Input>;
  enum_sale_kind?: InputMaybe<Enum_Sale_Kind_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  marketplace?: InputMaybe<Enum_Marketplace_Enum>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  order_type?: InputMaybe<Scalars['String']>;
  owner_address?: InputMaybe<Scalars['String']>;
  sale_type?: InputMaybe<Enum_Sale_Kind_Enum>;
  symbol?: InputMaybe<Scalars['String']>;
  token_address?: InputMaybe<Scalars['String']>;
  token_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visible?: InputMaybe<Scalars['Boolean']>;
  wallet_address?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Nft_Tokens_Max_Fields = {
  __typename?: 'nft_tokens_max_fields';
  asset_org_url?: Maybe<Scalars['String']>;
  asset_thumbnail_url?: Maybe<Scalars['String']>;
  asset_type?: Maybe<Scalars['String']>;
  asset_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current_price?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  order_type?: Maybe<Scalars['String']>;
  owner_address?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  token_address?: Maybe<Scalars['String']>;
  token_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wallet_address?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "nft_tokens" */
export type Nft_Tokens_Max_Order_By = {
  asset_org_url?: InputMaybe<Order_By>;
  asset_thumbnail_url?: InputMaybe<Order_By>;
  asset_type?: InputMaybe<Order_By>;
  asset_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_price?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order_type?: InputMaybe<Order_By>;
  owner_address?: InputMaybe<Order_By>;
  symbol?: InputMaybe<Order_By>;
  token_address?: InputMaybe<Order_By>;
  token_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  wallet_address?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nft_Tokens_Min_Fields = {
  __typename?: 'nft_tokens_min_fields';
  asset_org_url?: Maybe<Scalars['String']>;
  asset_thumbnail_url?: Maybe<Scalars['String']>;
  asset_type?: Maybe<Scalars['String']>;
  asset_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current_price?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  order_type?: Maybe<Scalars['String']>;
  owner_address?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  token_address?: Maybe<Scalars['String']>;
  token_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wallet_address?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "nft_tokens" */
export type Nft_Tokens_Min_Order_By = {
  asset_org_url?: InputMaybe<Order_By>;
  asset_thumbnail_url?: InputMaybe<Order_By>;
  asset_type?: InputMaybe<Order_By>;
  asset_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_price?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order_type?: InputMaybe<Order_By>;
  owner_address?: InputMaybe<Order_By>;
  symbol?: InputMaybe<Order_By>;
  token_address?: InputMaybe<Order_By>;
  token_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  wallet_address?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nft_tokens" */
export type Nft_Tokens_Mutation_Response = {
  __typename?: 'nft_tokens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nft_Tokens>;
};

/** on_conflict condition type for table "nft_tokens" */
export type Nft_Tokens_On_Conflict = {
  constraint: Nft_Tokens_Constraint;
  update_columns?: Array<Nft_Tokens_Update_Column>;
  where?: InputMaybe<Nft_Tokens_Bool_Exp>;
};

/** Ordering options when selecting data from "nft_tokens". */
export type Nft_Tokens_Order_By = {
  asset_org_url?: InputMaybe<Order_By>;
  asset_thumbnail_url?: InputMaybe<Order_By>;
  asset_type?: InputMaybe<Order_By>;
  asset_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_price?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  enum_marketplace?: InputMaybe<Enum_Marketplace_Order_By>;
  enum_sale_kind?: InputMaybe<Enum_Sale_Kind_Order_By>;
  id?: InputMaybe<Order_By>;
  marketplace?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order_type?: InputMaybe<Order_By>;
  owner_address?: InputMaybe<Order_By>;
  sale_type?: InputMaybe<Order_By>;
  symbol?: InputMaybe<Order_By>;
  token_address?: InputMaybe<Order_By>;
  token_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  visible?: InputMaybe<Order_By>;
  wallet_address?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nft_tokens */
export type Nft_Tokens_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Nft_Tokens_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "nft_tokens" */
export enum Nft_Tokens_Select_Column {
  /** column name */
  AssetOrgUrl = 'asset_org_url',
  /** column name */
  AssetThumbnailUrl = 'asset_thumbnail_url',
  /** column name */
  AssetType = 'asset_type',
  /** column name */
  AssetUrl = 'asset_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentPrice = 'current_price',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Marketplace = 'marketplace',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  OrderType = 'order_type',
  /** column name */
  OwnerAddress = 'owner_address',
  /** column name */
  SaleType = 'sale_type',
  /** column name */
  Symbol = 'symbol',
  /** column name */
  TokenAddress = 'token_address',
  /** column name */
  TokenId = 'token_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Visible = 'visible',
  /** column name */
  WalletAddress = 'wallet_address',
}

/** input type for updating data in table "nft_tokens" */
export type Nft_Tokens_Set_Input = {
  asset_org_url?: InputMaybe<Scalars['String']>;
  asset_thumbnail_url?: InputMaybe<Scalars['String']>;
  asset_type?: InputMaybe<Scalars['String']>;
  asset_url?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  current_price?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  marketplace?: InputMaybe<Enum_Marketplace_Enum>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  order_type?: InputMaybe<Scalars['String']>;
  owner_address?: InputMaybe<Scalars['String']>;
  sale_type?: InputMaybe<Enum_Sale_Kind_Enum>;
  symbol?: InputMaybe<Scalars['String']>;
  token_address?: InputMaybe<Scalars['String']>;
  token_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visible?: InputMaybe<Scalars['Boolean']>;
  wallet_address?: InputMaybe<Scalars['String']>;
};

/** update columns of table "nft_tokens" */
export enum Nft_Tokens_Update_Column {
  /** column name */
  AssetOrgUrl = 'asset_org_url',
  /** column name */
  AssetThumbnailUrl = 'asset_thumbnail_url',
  /** column name */
  AssetType = 'asset_type',
  /** column name */
  AssetUrl = 'asset_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentPrice = 'current_price',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Marketplace = 'marketplace',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  OrderType = 'order_type',
  /** column name */
  OwnerAddress = 'owner_address',
  /** column name */
  SaleType = 'sale_type',
  /** column name */
  Symbol = 'symbol',
  /** column name */
  TokenAddress = 'token_address',
  /** column name */
  TokenId = 'token_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Visible = 'visible',
  /** column name */
  WalletAddress = 'wallet_address',
}

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

export type Query_Root = {
  __typename?: 'query_root';
  /** An array relationship */
  account_providers: Array<Account_Providers>;
  /** An aggregate relationship */
  account_providers_aggregate: Account_Providers_Aggregate;
  /** fetch data from the table: "account_providers" using primary key columns */
  account_providers_by_pk?: Maybe<Account_Providers>;
  /** fetch data from the table: "accounts" */
  accounts: Array<Accounts>;
  /** fetch aggregated fields from the table: "accounts" */
  accounts_aggregate: Accounts_Aggregate;
  /** fetch data from the table: "accounts" using primary key columns */
  accounts_by_pk?: Maybe<Accounts>;
  /** fetch data from the table: "combined_nft_tokens" */
  combined_nft_tokens: Array<Combined_Nft_Tokens>;
  /** fetch aggregated fields from the table: "combined_nft_tokens" */
  combined_nft_tokens_aggregate: Combined_Nft_Tokens_Aggregate;
  /** fetch data from the table: "created_nft_tokens" */
  created_nft_tokens: Array<Created_Nft_Tokens>;
  /** fetch aggregated fields from the table: "created_nft_tokens" */
  created_nft_tokens_aggregate: Created_Nft_Tokens_Aggregate;
  /** fetch data from the table: "enum_blockchain_name" */
  enum_blockchain_name: Array<Enum_Blockchain_Name>;
  /** fetch aggregated fields from the table: "enum_blockchain_name" */
  enum_blockchain_name_aggregate: Enum_Blockchain_Name_Aggregate;
  /** fetch data from the table: "enum_blockchain_name" using primary key columns */
  enum_blockchain_name_by_pk?: Maybe<Enum_Blockchain_Name>;
  /** fetch data from the table: "enum_display_location" */
  enum_display_location: Array<Enum_Display_Location>;
  /** fetch aggregated fields from the table: "enum_display_location" */
  enum_display_location_aggregate: Enum_Display_Location_Aggregate;
  /** fetch data from the table: "enum_display_location" using primary key columns */
  enum_display_location_by_pk?: Maybe<Enum_Display_Location>;
  /** fetch data from the table: "enum_marketplace" */
  enum_marketplace: Array<Enum_Marketplace>;
  /** fetch aggregated fields from the table: "enum_marketplace" */
  enum_marketplace_aggregate: Enum_Marketplace_Aggregate;
  /** fetch data from the table: "enum_marketplace" using primary key columns */
  enum_marketplace_by_pk?: Maybe<Enum_Marketplace>;
  /** fetch data from the table: "enum_nft_type" */
  enum_nft_type: Array<Enum_Nft_Type>;
  /** fetch aggregated fields from the table: "enum_nft_type" */
  enum_nft_type_aggregate: Enum_Nft_Type_Aggregate;
  /** fetch data from the table: "enum_nft_type" using primary key columns */
  enum_nft_type_by_pk?: Maybe<Enum_Nft_Type>;
  /** fetch data from the table: "enum_providers" */
  enum_providers: Array<Enum_Providers>;
  /** fetch aggregated fields from the table: "enum_providers" */
  enum_providers_aggregate: Enum_Providers_Aggregate;
  /** fetch data from the table: "enum_providers" using primary key columns */
  enum_providers_by_pk?: Maybe<Enum_Providers>;
  /** fetch data from the table: "enum_sale_kind" */
  enum_sale_kind: Array<Enum_Sale_Kind>;
  /** fetch aggregated fields from the table: "enum_sale_kind" */
  enum_sale_kind_aggregate: Enum_Sale_Kind_Aggregate;
  /** fetch data from the table: "enum_sale_kind" using primary key columns */
  enum_sale_kind_by_pk?: Maybe<Enum_Sale_Kind>;
  /** fetch data from the table: "enum_storage_object_type" */
  enum_storage_object_type: Array<Enum_Storage_Object_Type>;
  /** fetch aggregated fields from the table: "enum_storage_object_type" */
  enum_storage_object_type_aggregate: Enum_Storage_Object_Type_Aggregate;
  /** fetch data from the table: "enum_storage_object_type" using primary key columns */
  enum_storage_object_type_by_pk?: Maybe<Enum_Storage_Object_Type>;
  /** fetch data from the table: "enum_store_theme" */
  enum_store_theme: Array<Enum_Store_Theme>;
  /** fetch aggregated fields from the table: "enum_store_theme" */
  enum_store_theme_aggregate: Enum_Store_Theme_Aggregate;
  /** fetch data from the table: "enum_store_theme" using primary key columns */
  enum_store_theme_by_pk?: Maybe<Enum_Store_Theme>;
  /** An array relationship */
  nft_tokens: Array<Nft_Tokens>;
  /** An aggregate relationship */
  nft_tokens_aggregate: Nft_Tokens_Aggregate;
  /** fetch data from the table: "nft_tokens" using primary key columns */
  nft_tokens_by_pk?: Maybe<Nft_Tokens>;
  /** fetch data from the table: "nft_tokens_created" */
  nft_tokens_created: Array<Nft_Tokens_Created>;
  /** fetch aggregated fields from the table: "nft_tokens_created" */
  nft_tokens_created_aggregate: Nft_Tokens_Created_Aggregate;
  /** fetch data from the table: "nft_tokens_created" using primary key columns */
  nft_tokens_created_by_pk?: Maybe<Nft_Tokens_Created>;
  /** An array relationship */
  storage_objects: Array<Storage_Objects>;
  /** An aggregate relationship */
  storage_objects_aggregate: Storage_Objects_Aggregate;
  /** fetch data from the table: "storage_objects" using primary key columns */
  storage_objects_by_pk?: Maybe<Storage_Objects>;
  /** fetch data from the table: "user_linked_nft_token" */
  user_linked_nft_token: Array<User_Linked_Nft_Token>;
  /** fetch aggregated fields from the table: "user_linked_nft_token" */
  user_linked_nft_token_aggregate: User_Linked_Nft_Token_Aggregate;
  /** fetch data from the table: "user_linked_nft_token" using primary key columns */
  user_linked_nft_token_by_pk?: Maybe<User_Linked_Nft_Token>;
  /** An array relationship */
  user_links: Array<User_Links>;
  /** An aggregate relationship */
  user_links_aggregate: User_Links_Aggregate;
  /** fetch data from the table: "user_links" using primary key columns */
  user_links_by_pk?: Maybe<User_Links>;
  /** fetch data from the table: "user_nft_tokens" */
  user_nft_tokens: Array<User_Nft_Tokens>;
  /** fetch aggregated fields from the table: "user_nft_tokens" */
  user_nft_tokens_aggregate: User_Nft_Tokens_Aggregate;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** An array relationship */
  wallet_providers: Array<Wallet_Providers>;
  /** An aggregate relationship */
  wallet_providers_aggregate: Wallet_Providers_Aggregate;
  /** fetch data from the table: "wallet_providers" using primary key columns */
  wallet_providers_by_pk?: Maybe<Wallet_Providers>;
};

export type Query_RootAccount_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Account_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Providers_Order_By>>;
  where?: InputMaybe<Account_Providers_Bool_Exp>;
};

export type Query_RootAccount_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Providers_Order_By>>;
  where?: InputMaybe<Account_Providers_Bool_Exp>;
};

export type Query_RootAccount_Providers_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAccountsArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};

export type Query_RootAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};

export type Query_RootAccounts_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCombined_Nft_TokensArgs = {
  distinct_on?: InputMaybe<Array<Combined_Nft_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Combined_Nft_Tokens_Order_By>>;
  where?: InputMaybe<Combined_Nft_Tokens_Bool_Exp>;
};

export type Query_RootCombined_Nft_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Combined_Nft_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Combined_Nft_Tokens_Order_By>>;
  where?: InputMaybe<Combined_Nft_Tokens_Bool_Exp>;
};

export type Query_RootCreated_Nft_TokensArgs = {
  distinct_on?: InputMaybe<Array<Created_Nft_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Created_Nft_Tokens_Order_By>>;
  where?: InputMaybe<Created_Nft_Tokens_Bool_Exp>;
};

export type Query_RootCreated_Nft_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Created_Nft_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Created_Nft_Tokens_Order_By>>;
  where?: InputMaybe<Created_Nft_Tokens_Bool_Exp>;
};

export type Query_RootEnum_Blockchain_NameArgs = {
  distinct_on?: InputMaybe<Array<Enum_Blockchain_Name_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Blockchain_Name_Order_By>>;
  where?: InputMaybe<Enum_Blockchain_Name_Bool_Exp>;
};

export type Query_RootEnum_Blockchain_Name_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Blockchain_Name_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Blockchain_Name_Order_By>>;
  where?: InputMaybe<Enum_Blockchain_Name_Bool_Exp>;
};

export type Query_RootEnum_Blockchain_Name_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Display_LocationArgs = {
  distinct_on?: InputMaybe<Array<Enum_Display_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Display_Location_Order_By>>;
  where?: InputMaybe<Enum_Display_Location_Bool_Exp>;
};

export type Query_RootEnum_Display_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Display_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Display_Location_Order_By>>;
  where?: InputMaybe<Enum_Display_Location_Bool_Exp>;
};

export type Query_RootEnum_Display_Location_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_MarketplaceArgs = {
  distinct_on?: InputMaybe<Array<Enum_Marketplace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Marketplace_Order_By>>;
  where?: InputMaybe<Enum_Marketplace_Bool_Exp>;
};

export type Query_RootEnum_Marketplace_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Marketplace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Marketplace_Order_By>>;
  where?: InputMaybe<Enum_Marketplace_Bool_Exp>;
};

export type Query_RootEnum_Marketplace_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Nft_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Nft_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Nft_Type_Order_By>>;
  where?: InputMaybe<Enum_Nft_Type_Bool_Exp>;
};

export type Query_RootEnum_Nft_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Nft_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Nft_Type_Order_By>>;
  where?: InputMaybe<Enum_Nft_Type_Bool_Exp>;
};

export type Query_RootEnum_Nft_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Enum_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Providers_Order_By>>;
  where?: InputMaybe<Enum_Providers_Bool_Exp>;
};

export type Query_RootEnum_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Providers_Order_By>>;
  where?: InputMaybe<Enum_Providers_Bool_Exp>;
};

export type Query_RootEnum_Providers_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Sale_KindArgs = {
  distinct_on?: InputMaybe<Array<Enum_Sale_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Sale_Kind_Order_By>>;
  where?: InputMaybe<Enum_Sale_Kind_Bool_Exp>;
};

export type Query_RootEnum_Sale_Kind_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Sale_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Sale_Kind_Order_By>>;
  where?: InputMaybe<Enum_Sale_Kind_Bool_Exp>;
};

export type Query_RootEnum_Sale_Kind_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Storage_Object_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Storage_Object_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Storage_Object_Type_Order_By>>;
  where?: InputMaybe<Enum_Storage_Object_Type_Bool_Exp>;
};

export type Query_RootEnum_Storage_Object_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Storage_Object_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Storage_Object_Type_Order_By>>;
  where?: InputMaybe<Enum_Storage_Object_Type_Bool_Exp>;
};

export type Query_RootEnum_Storage_Object_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEnum_Store_ThemeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Store_Theme_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Store_Theme_Order_By>>;
  where?: InputMaybe<Enum_Store_Theme_Bool_Exp>;
};

export type Query_RootEnum_Store_Theme_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Store_Theme_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Store_Theme_Order_By>>;
  where?: InputMaybe<Enum_Store_Theme_Bool_Exp>;
};

export type Query_RootEnum_Store_Theme_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootNft_TokensArgs = {
  distinct_on?: InputMaybe<Array<Nft_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nft_Tokens_Order_By>>;
  where?: InputMaybe<Nft_Tokens_Bool_Exp>;
};

export type Query_RootNft_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nft_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nft_Tokens_Order_By>>;
  where?: InputMaybe<Nft_Tokens_Bool_Exp>;
};

export type Query_RootNft_Tokens_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootNft_Tokens_CreatedArgs = {
  distinct_on?: InputMaybe<Array<Nft_Tokens_Created_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nft_Tokens_Created_Order_By>>;
  where?: InputMaybe<Nft_Tokens_Created_Bool_Exp>;
};

export type Query_RootNft_Tokens_Created_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nft_Tokens_Created_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nft_Tokens_Created_Order_By>>;
  where?: InputMaybe<Nft_Tokens_Created_Bool_Exp>;
};

export type Query_RootNft_Tokens_Created_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootStorage_ObjectsArgs = {
  distinct_on?: InputMaybe<Array<Storage_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Storage_Objects_Order_By>>;
  where?: InputMaybe<Storage_Objects_Bool_Exp>;
};

export type Query_RootStorage_Objects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Storage_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Storage_Objects_Order_By>>;
  where?: InputMaybe<Storage_Objects_Bool_Exp>;
};

export type Query_RootStorage_Objects_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootUser_Linked_Nft_TokenArgs = {
  distinct_on?: InputMaybe<Array<User_Linked_Nft_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Linked_Nft_Token_Order_By>>;
  where?: InputMaybe<User_Linked_Nft_Token_Bool_Exp>;
};

export type Query_RootUser_Linked_Nft_Token_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Linked_Nft_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Linked_Nft_Token_Order_By>>;
  where?: InputMaybe<User_Linked_Nft_Token_Bool_Exp>;
};

export type Query_RootUser_Linked_Nft_Token_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootUser_LinksArgs = {
  distinct_on?: InputMaybe<Array<User_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Links_Order_By>>;
  where?: InputMaybe<User_Links_Bool_Exp>;
};

export type Query_RootUser_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Links_Order_By>>;
  where?: InputMaybe<User_Links_Bool_Exp>;
};

export type Query_RootUser_Links_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootUser_Nft_TokensArgs = {
  distinct_on?: InputMaybe<Array<User_Nft_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Nft_Tokens_Order_By>>;
  where?: InputMaybe<User_Nft_Tokens_Bool_Exp>;
};

export type Query_RootUser_Nft_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Nft_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Nft_Tokens_Order_By>>;
  where?: InputMaybe<User_Nft_Tokens_Bool_Exp>;
};

export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Query_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootWallet_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Providers_Order_By>>;
  where?: InputMaybe<Wallet_Providers_Bool_Exp>;
};

export type Query_RootWallet_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Providers_Order_By>>;
  where?: InputMaybe<Wallet_Providers_Bool_Exp>;
};

export type Query_RootWallet_Providers_By_PkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "storage_objects" */
export type Storage_Objects = {
  __typename?: 'storage_objects';
  /** An object relationship */
  account: Accounts;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  /** An object relationship */
  enum_storage_object_type: Enum_Storage_Object_Type;
  id: Scalars['uuid'];
  metadata?: Maybe<Scalars['jsonb']>;
  object_type: Enum_Storage_Object_Type_Enum;
  storage_url: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  user_links: Array<User_Links>;
  /** An aggregate relationship */
  user_links_aggregate: User_Links_Aggregate;
  /** An array relationship */
  users: Array<Users>;
  /** An array relationship */
  usersByCoverObjectId: Array<Users>;
  /** An aggregate relationship */
  usersByCoverObjectId_aggregate: Users_Aggregate;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
};

/** columns and relationships of "storage_objects" */
export type Storage_ObjectsMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "storage_objects" */
export type Storage_ObjectsUser_LinksArgs = {
  distinct_on?: InputMaybe<Array<User_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Links_Order_By>>;
  where?: InputMaybe<User_Links_Bool_Exp>;
};

/** columns and relationships of "storage_objects" */
export type Storage_ObjectsUser_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Links_Order_By>>;
  where?: InputMaybe<User_Links_Bool_Exp>;
};

/** columns and relationships of "storage_objects" */
export type Storage_ObjectsUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** columns and relationships of "storage_objects" */
export type Storage_ObjectsUsersByCoverObjectIdArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** columns and relationships of "storage_objects" */
export type Storage_ObjectsUsersByCoverObjectId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** columns and relationships of "storage_objects" */
export type Storage_ObjectsUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** aggregated selection of "storage_objects" */
export type Storage_Objects_Aggregate = {
  __typename?: 'storage_objects_aggregate';
  aggregate?: Maybe<Storage_Objects_Aggregate_Fields>;
  nodes: Array<Storage_Objects>;
};

/** aggregate fields of "storage_objects" */
export type Storage_Objects_Aggregate_Fields = {
  __typename?: 'storage_objects_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Storage_Objects_Max_Fields>;
  min?: Maybe<Storage_Objects_Min_Fields>;
};

/** aggregate fields of "storage_objects" */
export type Storage_Objects_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Storage_Objects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "storage_objects" */
export type Storage_Objects_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Storage_Objects_Max_Order_By>;
  min?: InputMaybe<Storage_Objects_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Storage_Objects_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "storage_objects" */
export type Storage_Objects_Arr_Rel_Insert_Input = {
  data: Array<Storage_Objects_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Storage_Objects_On_Conflict>;
};

/** Boolean expression to filter rows from the table "storage_objects". All fields are combined with a logical 'AND'. */
export type Storage_Objects_Bool_Exp = {
  _and?: InputMaybe<Array<Storage_Objects_Bool_Exp>>;
  _not?: InputMaybe<Storage_Objects_Bool_Exp>;
  _or?: InputMaybe<Array<Storage_Objects_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  enum_storage_object_type?: InputMaybe<Enum_Storage_Object_Type_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  object_type?: InputMaybe<Enum_Storage_Object_Type_Enum_Comparison_Exp>;
  storage_url?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_links?: InputMaybe<User_Links_Bool_Exp>;
  users?: InputMaybe<Users_Bool_Exp>;
  usersByCoverObjectId?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "storage_objects" */
export enum Storage_Objects_Constraint {
  /** unique or primary key constraint on columns "id" */
  StorageObjectsPkey = 'storage_objects_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Storage_Objects_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Storage_Objects_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Storage_Objects_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "storage_objects" */
export type Storage_Objects_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  enum_storage_object_type?: InputMaybe<Enum_Storage_Object_Type_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  object_type?: InputMaybe<Enum_Storage_Object_Type_Enum>;
  storage_url?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_links?: InputMaybe<User_Links_Arr_Rel_Insert_Input>;
  users?: InputMaybe<Users_Arr_Rel_Insert_Input>;
  usersByCoverObjectId?: InputMaybe<Users_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Storage_Objects_Max_Fields = {
  __typename?: 'storage_objects_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  storage_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "storage_objects" */
export type Storage_Objects_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  storage_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Storage_Objects_Min_Fields = {
  __typename?: 'storage_objects_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  storage_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "storage_objects" */
export type Storage_Objects_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  storage_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "storage_objects" */
export type Storage_Objects_Mutation_Response = {
  __typename?: 'storage_objects_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Storage_Objects>;
};

/** input type for inserting object relation for remote table "storage_objects" */
export type Storage_Objects_Obj_Rel_Insert_Input = {
  data: Storage_Objects_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Storage_Objects_On_Conflict>;
};

/** on_conflict condition type for table "storage_objects" */
export type Storage_Objects_On_Conflict = {
  constraint: Storage_Objects_Constraint;
  update_columns?: Array<Storage_Objects_Update_Column>;
  where?: InputMaybe<Storage_Objects_Bool_Exp>;
};

/** Ordering options when selecting data from "storage_objects". */
export type Storage_Objects_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  enum_storage_object_type?: InputMaybe<Enum_Storage_Object_Type_Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  object_type?: InputMaybe<Order_By>;
  storage_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_links_aggregate?: InputMaybe<User_Links_Aggregate_Order_By>;
  usersByCoverObjectId_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
  users_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
};

/** primary key columns input for table: storage_objects */
export type Storage_Objects_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Storage_Objects_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "storage_objects" */
export enum Storage_Objects_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  ObjectType = 'object_type',
  /** column name */
  StorageUrl = 'storage_url',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "storage_objects" */
export type Storage_Objects_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  object_type?: InputMaybe<Enum_Storage_Object_Type_Enum>;
  storage_url?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "storage_objects" */
export enum Storage_Objects_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  ObjectType = 'object_type',
  /** column name */
  StorageUrl = 'storage_url',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** An array relationship */
  account_providers: Array<Account_Providers>;
  /** An aggregate relationship */
  account_providers_aggregate: Account_Providers_Aggregate;
  /** fetch data from the table: "account_providers" using primary key columns */
  account_providers_by_pk?: Maybe<Account_Providers>;
  /** fetch data from the table: "accounts" */
  accounts: Array<Accounts>;
  /** fetch aggregated fields from the table: "accounts" */
  accounts_aggregate: Accounts_Aggregate;
  /** fetch data from the table: "accounts" using primary key columns */
  accounts_by_pk?: Maybe<Accounts>;
  /** fetch data from the table: "combined_nft_tokens" */
  combined_nft_tokens: Array<Combined_Nft_Tokens>;
  /** fetch aggregated fields from the table: "combined_nft_tokens" */
  combined_nft_tokens_aggregate: Combined_Nft_Tokens_Aggregate;
  /** fetch data from the table: "created_nft_tokens" */
  created_nft_tokens: Array<Created_Nft_Tokens>;
  /** fetch aggregated fields from the table: "created_nft_tokens" */
  created_nft_tokens_aggregate: Created_Nft_Tokens_Aggregate;
  /** fetch data from the table: "enum_blockchain_name" */
  enum_blockchain_name: Array<Enum_Blockchain_Name>;
  /** fetch aggregated fields from the table: "enum_blockchain_name" */
  enum_blockchain_name_aggregate: Enum_Blockchain_Name_Aggregate;
  /** fetch data from the table: "enum_blockchain_name" using primary key columns */
  enum_blockchain_name_by_pk?: Maybe<Enum_Blockchain_Name>;
  /** fetch data from the table: "enum_display_location" */
  enum_display_location: Array<Enum_Display_Location>;
  /** fetch aggregated fields from the table: "enum_display_location" */
  enum_display_location_aggregate: Enum_Display_Location_Aggregate;
  /** fetch data from the table: "enum_display_location" using primary key columns */
  enum_display_location_by_pk?: Maybe<Enum_Display_Location>;
  /** fetch data from the table: "enum_marketplace" */
  enum_marketplace: Array<Enum_Marketplace>;
  /** fetch aggregated fields from the table: "enum_marketplace" */
  enum_marketplace_aggregate: Enum_Marketplace_Aggregate;
  /** fetch data from the table: "enum_marketplace" using primary key columns */
  enum_marketplace_by_pk?: Maybe<Enum_Marketplace>;
  /** fetch data from the table: "enum_nft_type" */
  enum_nft_type: Array<Enum_Nft_Type>;
  /** fetch aggregated fields from the table: "enum_nft_type" */
  enum_nft_type_aggregate: Enum_Nft_Type_Aggregate;
  /** fetch data from the table: "enum_nft_type" using primary key columns */
  enum_nft_type_by_pk?: Maybe<Enum_Nft_Type>;
  /** fetch data from the table: "enum_providers" */
  enum_providers: Array<Enum_Providers>;
  /** fetch aggregated fields from the table: "enum_providers" */
  enum_providers_aggregate: Enum_Providers_Aggregate;
  /** fetch data from the table: "enum_providers" using primary key columns */
  enum_providers_by_pk?: Maybe<Enum_Providers>;
  /** fetch data from the table: "enum_sale_kind" */
  enum_sale_kind: Array<Enum_Sale_Kind>;
  /** fetch aggregated fields from the table: "enum_sale_kind" */
  enum_sale_kind_aggregate: Enum_Sale_Kind_Aggregate;
  /** fetch data from the table: "enum_sale_kind" using primary key columns */
  enum_sale_kind_by_pk?: Maybe<Enum_Sale_Kind>;
  /** fetch data from the table: "enum_storage_object_type" */
  enum_storage_object_type: Array<Enum_Storage_Object_Type>;
  /** fetch aggregated fields from the table: "enum_storage_object_type" */
  enum_storage_object_type_aggregate: Enum_Storage_Object_Type_Aggregate;
  /** fetch data from the table: "enum_storage_object_type" using primary key columns */
  enum_storage_object_type_by_pk?: Maybe<Enum_Storage_Object_Type>;
  /** fetch data from the table: "enum_store_theme" */
  enum_store_theme: Array<Enum_Store_Theme>;
  /** fetch aggregated fields from the table: "enum_store_theme" */
  enum_store_theme_aggregate: Enum_Store_Theme_Aggregate;
  /** fetch data from the table: "enum_store_theme" using primary key columns */
  enum_store_theme_by_pk?: Maybe<Enum_Store_Theme>;
  /** An array relationship */
  nft_tokens: Array<Nft_Tokens>;
  /** An aggregate relationship */
  nft_tokens_aggregate: Nft_Tokens_Aggregate;
  /** fetch data from the table: "nft_tokens" using primary key columns */
  nft_tokens_by_pk?: Maybe<Nft_Tokens>;
  /** fetch data from the table: "nft_tokens_created" */
  nft_tokens_created: Array<Nft_Tokens_Created>;
  /** fetch aggregated fields from the table: "nft_tokens_created" */
  nft_tokens_created_aggregate: Nft_Tokens_Created_Aggregate;
  /** fetch data from the table: "nft_tokens_created" using primary key columns */
  nft_tokens_created_by_pk?: Maybe<Nft_Tokens_Created>;
  /** An array relationship */
  storage_objects: Array<Storage_Objects>;
  /** An aggregate relationship */
  storage_objects_aggregate: Storage_Objects_Aggregate;
  /** fetch data from the table: "storage_objects" using primary key columns */
  storage_objects_by_pk?: Maybe<Storage_Objects>;
  /** fetch data from the table: "user_linked_nft_token" */
  user_linked_nft_token: Array<User_Linked_Nft_Token>;
  /** fetch aggregated fields from the table: "user_linked_nft_token" */
  user_linked_nft_token_aggregate: User_Linked_Nft_Token_Aggregate;
  /** fetch data from the table: "user_linked_nft_token" using primary key columns */
  user_linked_nft_token_by_pk?: Maybe<User_Linked_Nft_Token>;
  /** An array relationship */
  user_links: Array<User_Links>;
  /** An aggregate relationship */
  user_links_aggregate: User_Links_Aggregate;
  /** fetch data from the table: "user_links" using primary key columns */
  user_links_by_pk?: Maybe<User_Links>;
  /** fetch data from the table: "user_nft_tokens" */
  user_nft_tokens: Array<User_Nft_Tokens>;
  /** fetch aggregated fields from the table: "user_nft_tokens" */
  user_nft_tokens_aggregate: User_Nft_Tokens_Aggregate;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** An array relationship */
  wallet_providers: Array<Wallet_Providers>;
  /** An aggregate relationship */
  wallet_providers_aggregate: Wallet_Providers_Aggregate;
  /** fetch data from the table: "wallet_providers" using primary key columns */
  wallet_providers_by_pk?: Maybe<Wallet_Providers>;
};

export type Subscription_RootAccount_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Account_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Providers_Order_By>>;
  where?: InputMaybe<Account_Providers_Bool_Exp>;
};

export type Subscription_RootAccount_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Providers_Order_By>>;
  where?: InputMaybe<Account_Providers_Bool_Exp>;
};

export type Subscription_RootAccount_Providers_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAccountsArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};

export type Subscription_RootAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};

export type Subscription_RootAccounts_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCombined_Nft_TokensArgs = {
  distinct_on?: InputMaybe<Array<Combined_Nft_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Combined_Nft_Tokens_Order_By>>;
  where?: InputMaybe<Combined_Nft_Tokens_Bool_Exp>;
};

export type Subscription_RootCombined_Nft_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Combined_Nft_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Combined_Nft_Tokens_Order_By>>;
  where?: InputMaybe<Combined_Nft_Tokens_Bool_Exp>;
};

export type Subscription_RootCreated_Nft_TokensArgs = {
  distinct_on?: InputMaybe<Array<Created_Nft_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Created_Nft_Tokens_Order_By>>;
  where?: InputMaybe<Created_Nft_Tokens_Bool_Exp>;
};

export type Subscription_RootCreated_Nft_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Created_Nft_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Created_Nft_Tokens_Order_By>>;
  where?: InputMaybe<Created_Nft_Tokens_Bool_Exp>;
};

export type Subscription_RootEnum_Blockchain_NameArgs = {
  distinct_on?: InputMaybe<Array<Enum_Blockchain_Name_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Blockchain_Name_Order_By>>;
  where?: InputMaybe<Enum_Blockchain_Name_Bool_Exp>;
};

export type Subscription_RootEnum_Blockchain_Name_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Blockchain_Name_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Blockchain_Name_Order_By>>;
  where?: InputMaybe<Enum_Blockchain_Name_Bool_Exp>;
};

export type Subscription_RootEnum_Blockchain_Name_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Display_LocationArgs = {
  distinct_on?: InputMaybe<Array<Enum_Display_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Display_Location_Order_By>>;
  where?: InputMaybe<Enum_Display_Location_Bool_Exp>;
};

export type Subscription_RootEnum_Display_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Display_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Display_Location_Order_By>>;
  where?: InputMaybe<Enum_Display_Location_Bool_Exp>;
};

export type Subscription_RootEnum_Display_Location_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_MarketplaceArgs = {
  distinct_on?: InputMaybe<Array<Enum_Marketplace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Marketplace_Order_By>>;
  where?: InputMaybe<Enum_Marketplace_Bool_Exp>;
};

export type Subscription_RootEnum_Marketplace_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Marketplace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Marketplace_Order_By>>;
  where?: InputMaybe<Enum_Marketplace_Bool_Exp>;
};

export type Subscription_RootEnum_Marketplace_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Nft_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Nft_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Nft_Type_Order_By>>;
  where?: InputMaybe<Enum_Nft_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Nft_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Nft_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Nft_Type_Order_By>>;
  where?: InputMaybe<Enum_Nft_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Nft_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Enum_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Providers_Order_By>>;
  where?: InputMaybe<Enum_Providers_Bool_Exp>;
};

export type Subscription_RootEnum_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Providers_Order_By>>;
  where?: InputMaybe<Enum_Providers_Bool_Exp>;
};

export type Subscription_RootEnum_Providers_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Sale_KindArgs = {
  distinct_on?: InputMaybe<Array<Enum_Sale_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Sale_Kind_Order_By>>;
  where?: InputMaybe<Enum_Sale_Kind_Bool_Exp>;
};

export type Subscription_RootEnum_Sale_Kind_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Sale_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Sale_Kind_Order_By>>;
  where?: InputMaybe<Enum_Sale_Kind_Bool_Exp>;
};

export type Subscription_RootEnum_Sale_Kind_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Storage_Object_TypeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Storage_Object_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Storage_Object_Type_Order_By>>;
  where?: InputMaybe<Enum_Storage_Object_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Storage_Object_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Storage_Object_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Storage_Object_Type_Order_By>>;
  where?: InputMaybe<Enum_Storage_Object_Type_Bool_Exp>;
};

export type Subscription_RootEnum_Storage_Object_Type_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootEnum_Store_ThemeArgs = {
  distinct_on?: InputMaybe<Array<Enum_Store_Theme_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Store_Theme_Order_By>>;
  where?: InputMaybe<Enum_Store_Theme_Bool_Exp>;
};

export type Subscription_RootEnum_Store_Theme_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enum_Store_Theme_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enum_Store_Theme_Order_By>>;
  where?: InputMaybe<Enum_Store_Theme_Bool_Exp>;
};

export type Subscription_RootEnum_Store_Theme_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootNft_TokensArgs = {
  distinct_on?: InputMaybe<Array<Nft_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nft_Tokens_Order_By>>;
  where?: InputMaybe<Nft_Tokens_Bool_Exp>;
};

export type Subscription_RootNft_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nft_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nft_Tokens_Order_By>>;
  where?: InputMaybe<Nft_Tokens_Bool_Exp>;
};

export type Subscription_RootNft_Tokens_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootNft_Tokens_CreatedArgs = {
  distinct_on?: InputMaybe<Array<Nft_Tokens_Created_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nft_Tokens_Created_Order_By>>;
  where?: InputMaybe<Nft_Tokens_Created_Bool_Exp>;
};

export type Subscription_RootNft_Tokens_Created_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nft_Tokens_Created_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Nft_Tokens_Created_Order_By>>;
  where?: InputMaybe<Nft_Tokens_Created_Bool_Exp>;
};

export type Subscription_RootNft_Tokens_Created_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootStorage_ObjectsArgs = {
  distinct_on?: InputMaybe<Array<Storage_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Storage_Objects_Order_By>>;
  where?: InputMaybe<Storage_Objects_Bool_Exp>;
};

export type Subscription_RootStorage_Objects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Storage_Objects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Storage_Objects_Order_By>>;
  where?: InputMaybe<Storage_Objects_Bool_Exp>;
};

export type Subscription_RootStorage_Objects_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootUser_Linked_Nft_TokenArgs = {
  distinct_on?: InputMaybe<Array<User_Linked_Nft_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Linked_Nft_Token_Order_By>>;
  where?: InputMaybe<User_Linked_Nft_Token_Bool_Exp>;
};

export type Subscription_RootUser_Linked_Nft_Token_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Linked_Nft_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Linked_Nft_Token_Order_By>>;
  where?: InputMaybe<User_Linked_Nft_Token_Bool_Exp>;
};

export type Subscription_RootUser_Linked_Nft_Token_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootUser_LinksArgs = {
  distinct_on?: InputMaybe<Array<User_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Links_Order_By>>;
  where?: InputMaybe<User_Links_Bool_Exp>;
};

export type Subscription_RootUser_Links_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Links_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Links_Order_By>>;
  where?: InputMaybe<User_Links_Bool_Exp>;
};

export type Subscription_RootUser_Links_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootUser_Nft_TokensArgs = {
  distinct_on?: InputMaybe<Array<User_Nft_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Nft_Tokens_Order_By>>;
  where?: InputMaybe<User_Nft_Tokens_Bool_Exp>;
};

export type Subscription_RootUser_Nft_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Nft_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Nft_Tokens_Order_By>>;
  where?: InputMaybe<User_Nft_Tokens_Bool_Exp>;
};

export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootWallet_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Providers_Order_By>>;
  where?: InputMaybe<Wallet_Providers_Bool_Exp>;
};

export type Subscription_RootWallet_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Providers_Order_By>>;
  where?: InputMaybe<Wallet_Providers_Bool_Exp>;
};

export type Subscription_RootWallet_Providers_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Timestamptz_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _cast?: InputMaybe<Timestamptz_Cast_Exp>;
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "user_linked_nft_token" */
export type User_Linked_Nft_Token = {
  __typename?: 'user_linked_nft_token';
  /** An object relationship */
  account: Accounts;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  enum_nft_type: Enum_Nft_Type;
  id: Scalars['uuid'];
  nft_type: Enum_Nft_Type_Enum;
  owned_by: Scalars['uuid'];
  token_address: Scalars['String'];
  token_id: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  visible: Scalars['Boolean'];
  /** An object relationship */
  wallet_provider: Wallet_Providers;
  wallet_provider_id: Scalars['uuid'];
};

/** aggregated selection of "user_linked_nft_token" */
export type User_Linked_Nft_Token_Aggregate = {
  __typename?: 'user_linked_nft_token_aggregate';
  aggregate?: Maybe<User_Linked_Nft_Token_Aggregate_Fields>;
  nodes: Array<User_Linked_Nft_Token>;
};

/** aggregate fields of "user_linked_nft_token" */
export type User_Linked_Nft_Token_Aggregate_Fields = {
  __typename?: 'user_linked_nft_token_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Linked_Nft_Token_Max_Fields>;
  min?: Maybe<User_Linked_Nft_Token_Min_Fields>;
};

/** aggregate fields of "user_linked_nft_token" */
export type User_Linked_Nft_Token_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Linked_Nft_Token_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_linked_nft_token" */
export type User_Linked_Nft_Token_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Linked_Nft_Token_Max_Order_By>;
  min?: InputMaybe<User_Linked_Nft_Token_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_linked_nft_token" */
export type User_Linked_Nft_Token_Arr_Rel_Insert_Input = {
  data: Array<User_Linked_Nft_Token_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Linked_Nft_Token_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_linked_nft_token". All fields are combined with a logical 'AND'. */
export type User_Linked_Nft_Token_Bool_Exp = {
  _and?: InputMaybe<Array<User_Linked_Nft_Token_Bool_Exp>>;
  _not?: InputMaybe<User_Linked_Nft_Token_Bool_Exp>;
  _or?: InputMaybe<Array<User_Linked_Nft_Token_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  enum_nft_type?: InputMaybe<Enum_Nft_Type_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nft_type?: InputMaybe<Enum_Nft_Type_Enum_Comparison_Exp>;
  owned_by?: InputMaybe<Uuid_Comparison_Exp>;
  token_address?: InputMaybe<String_Comparison_Exp>;
  token_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  visible?: InputMaybe<Boolean_Comparison_Exp>;
  wallet_provider?: InputMaybe<Wallet_Providers_Bool_Exp>;
  wallet_provider_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_linked_nft_token" */
export enum User_Linked_Nft_Token_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserLinkedNftTokenPkey = 'user_linked_nft_token_pkey',
  /** unique or primary key constraint on columns "wallet_provider_id", "nft_type", "token_address", "token_id" */
  UserLinkedNftTokenTokenIdTokenAddressWalletProviderId = 'user_linked_nft_token_token_id_token_address_wallet_provider_id',
}

/** input type for inserting data into table "user_linked_nft_token" */
export type User_Linked_Nft_Token_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  enum_nft_type?: InputMaybe<Enum_Nft_Type_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  nft_type?: InputMaybe<Enum_Nft_Type_Enum>;
  owned_by?: InputMaybe<Scalars['uuid']>;
  token_address?: InputMaybe<Scalars['String']>;
  token_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visible?: InputMaybe<Scalars['Boolean']>;
  wallet_provider?: InputMaybe<Wallet_Providers_Obj_Rel_Insert_Input>;
  wallet_provider_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Linked_Nft_Token_Max_Fields = {
  __typename?: 'user_linked_nft_token_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  owned_by?: Maybe<Scalars['uuid']>;
  token_address?: Maybe<Scalars['String']>;
  token_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wallet_provider_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_linked_nft_token" */
export type User_Linked_Nft_Token_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owned_by?: InputMaybe<Order_By>;
  token_address?: InputMaybe<Order_By>;
  token_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  wallet_provider_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Linked_Nft_Token_Min_Fields = {
  __typename?: 'user_linked_nft_token_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  owned_by?: Maybe<Scalars['uuid']>;
  token_address?: Maybe<Scalars['String']>;
  token_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wallet_provider_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_linked_nft_token" */
export type User_Linked_Nft_Token_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owned_by?: InputMaybe<Order_By>;
  token_address?: InputMaybe<Order_By>;
  token_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  wallet_provider_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_linked_nft_token" */
export type User_Linked_Nft_Token_Mutation_Response = {
  __typename?: 'user_linked_nft_token_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Linked_Nft_Token>;
};

/** on_conflict condition type for table "user_linked_nft_token" */
export type User_Linked_Nft_Token_On_Conflict = {
  constraint: User_Linked_Nft_Token_Constraint;
  update_columns?: Array<User_Linked_Nft_Token_Update_Column>;
  where?: InputMaybe<User_Linked_Nft_Token_Bool_Exp>;
};

/** Ordering options when selecting data from "user_linked_nft_token". */
export type User_Linked_Nft_Token_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  created_at?: InputMaybe<Order_By>;
  enum_nft_type?: InputMaybe<Enum_Nft_Type_Order_By>;
  id?: InputMaybe<Order_By>;
  nft_type?: InputMaybe<Order_By>;
  owned_by?: InputMaybe<Order_By>;
  token_address?: InputMaybe<Order_By>;
  token_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  visible?: InputMaybe<Order_By>;
  wallet_provider?: InputMaybe<Wallet_Providers_Order_By>;
  wallet_provider_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_linked_nft_token */
export type User_Linked_Nft_Token_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user_linked_nft_token" */
export enum User_Linked_Nft_Token_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NftType = 'nft_type',
  /** column name */
  OwnedBy = 'owned_by',
  /** column name */
  TokenAddress = 'token_address',
  /** column name */
  TokenId = 'token_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Visible = 'visible',
  /** column name */
  WalletProviderId = 'wallet_provider_id',
}

/** input type for updating data in table "user_linked_nft_token" */
export type User_Linked_Nft_Token_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  nft_type?: InputMaybe<Enum_Nft_Type_Enum>;
  owned_by?: InputMaybe<Scalars['uuid']>;
  token_address?: InputMaybe<Scalars['String']>;
  token_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  visible?: InputMaybe<Scalars['Boolean']>;
  wallet_provider_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_linked_nft_token" */
export enum User_Linked_Nft_Token_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NftType = 'nft_type',
  /** column name */
  OwnedBy = 'owned_by',
  /** column name */
  TokenAddress = 'token_address',
  /** column name */
  TokenId = 'token_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Visible = 'visible',
  /** column name */
  WalletProviderId = 'wallet_provider_id',
}

/** columns and relationships of "user_links" */
export type User_Links = {
  __typename?: 'user_links';
  /** An object relationship */
  account: Accounts;
  created_at: Scalars['timestamptz'];
  display_location: Enum_Display_Location_Enum;
  /** An object relationship */
  enum_display_location: Enum_Display_Location;
  /** An object relationship */
  icon_object?: Maybe<Storage_Objects>;
  icon_object_id?: Maybe<Scalars['uuid']>;
  icon_preset?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  link_name: Scalars['String'];
  link_url: Scalars['String'];
  owned_by: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "user_links" */
export type User_Links_Aggregate = {
  __typename?: 'user_links_aggregate';
  aggregate?: Maybe<User_Links_Aggregate_Fields>;
  nodes: Array<User_Links>;
};

/** aggregate fields of "user_links" */
export type User_Links_Aggregate_Fields = {
  __typename?: 'user_links_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Links_Max_Fields>;
  min?: Maybe<User_Links_Min_Fields>;
};

/** aggregate fields of "user_links" */
export type User_Links_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Links_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_links" */
export type User_Links_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Links_Max_Order_By>;
  min?: InputMaybe<User_Links_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_links" */
export type User_Links_Arr_Rel_Insert_Input = {
  data: Array<User_Links_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Links_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_links". All fields are combined with a logical 'AND'. */
export type User_Links_Bool_Exp = {
  _and?: InputMaybe<Array<User_Links_Bool_Exp>>;
  _not?: InputMaybe<User_Links_Bool_Exp>;
  _or?: InputMaybe<Array<User_Links_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  display_location?: InputMaybe<Enum_Display_Location_Enum_Comparison_Exp>;
  enum_display_location?: InputMaybe<Enum_Display_Location_Bool_Exp>;
  icon_object?: InputMaybe<Storage_Objects_Bool_Exp>;
  icon_object_id?: InputMaybe<Uuid_Comparison_Exp>;
  icon_preset?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  link_name?: InputMaybe<String_Comparison_Exp>;
  link_url?: InputMaybe<String_Comparison_Exp>;
  owned_by?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_links" */
export enum User_Links_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserLinksPkey = 'user_links_pkey',
}

/** input type for inserting data into table "user_links" */
export type User_Links_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  display_location?: InputMaybe<Enum_Display_Location_Enum>;
  enum_display_location?: InputMaybe<Enum_Display_Location_Obj_Rel_Insert_Input>;
  icon_object?: InputMaybe<Storage_Objects_Obj_Rel_Insert_Input>;
  icon_object_id?: InputMaybe<Scalars['uuid']>;
  icon_preset?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  link_name?: InputMaybe<Scalars['String']>;
  link_url?: InputMaybe<Scalars['String']>;
  owned_by?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type User_Links_Max_Fields = {
  __typename?: 'user_links_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  icon_object_id?: Maybe<Scalars['uuid']>;
  icon_preset?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  link_name?: Maybe<Scalars['String']>;
  link_url?: Maybe<Scalars['String']>;
  owned_by?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "user_links" */
export type User_Links_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  icon_object_id?: InputMaybe<Order_By>;
  icon_preset?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link_name?: InputMaybe<Order_By>;
  link_url?: InputMaybe<Order_By>;
  owned_by?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Links_Min_Fields = {
  __typename?: 'user_links_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  icon_object_id?: Maybe<Scalars['uuid']>;
  icon_preset?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  link_name?: Maybe<Scalars['String']>;
  link_url?: Maybe<Scalars['String']>;
  owned_by?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "user_links" */
export type User_Links_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  icon_object_id?: InputMaybe<Order_By>;
  icon_preset?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link_name?: InputMaybe<Order_By>;
  link_url?: InputMaybe<Order_By>;
  owned_by?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_links" */
export type User_Links_Mutation_Response = {
  __typename?: 'user_links_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Links>;
};

/** on_conflict condition type for table "user_links" */
export type User_Links_On_Conflict = {
  constraint: User_Links_Constraint;
  update_columns?: Array<User_Links_Update_Column>;
  where?: InputMaybe<User_Links_Bool_Exp>;
};

/** Ordering options when selecting data from "user_links". */
export type User_Links_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  created_at?: InputMaybe<Order_By>;
  display_location?: InputMaybe<Order_By>;
  enum_display_location?: InputMaybe<Enum_Display_Location_Order_By>;
  icon_object?: InputMaybe<Storage_Objects_Order_By>;
  icon_object_id?: InputMaybe<Order_By>;
  icon_preset?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link_name?: InputMaybe<Order_By>;
  link_url?: InputMaybe<Order_By>;
  owned_by?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_links */
export type User_Links_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user_links" */
export enum User_Links_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayLocation = 'display_location',
  /** column name */
  IconObjectId = 'icon_object_id',
  /** column name */
  IconPreset = 'icon_preset',
  /** column name */
  Id = 'id',
  /** column name */
  LinkName = 'link_name',
  /** column name */
  LinkUrl = 'link_url',
  /** column name */
  OwnedBy = 'owned_by',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "user_links" */
export type User_Links_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  display_location?: InputMaybe<Enum_Display_Location_Enum>;
  icon_object_id?: InputMaybe<Scalars['uuid']>;
  icon_preset?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  link_name?: InputMaybe<Scalars['String']>;
  link_url?: InputMaybe<Scalars['String']>;
  owned_by?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "user_links" */
export enum User_Links_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayLocation = 'display_location',
  /** column name */
  IconObjectId = 'icon_object_id',
  /** column name */
  IconPreset = 'icon_preset',
  /** column name */
  Id = 'id',
  /** column name */
  LinkName = 'link_name',
  /** column name */
  LinkUrl = 'link_url',
  /** column name */
  OwnedBy = 'owned_by',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** columns and relationships of "user_nft_tokens" */
export type User_Nft_Tokens = {
  __typename?: 'user_nft_tokens';
  asset_org_url?: Maybe<Scalars['String']>;
  asset_thumbnail_url?: Maybe<Scalars['String']>;
  asset_type?: Maybe<Scalars['String']>;
  asset_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current_price?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  marketplace?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['jsonb']>;
  name?: Maybe<Scalars['String']>;
  order_type?: Maybe<Scalars['String']>;
  owned_by?: Maybe<Scalars['uuid']>;
  owner_address?: Maybe<Scalars['String']>;
  sale_type?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  token_address?: Maybe<Scalars['String']>;
  token_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
  visible?: Maybe<Scalars['Boolean']>;
  wallet_address?: Maybe<Scalars['String']>;
  wallet_provider_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "user_nft_tokens" */
export type User_Nft_TokensMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "user_nft_tokens" */
export type User_Nft_Tokens_Aggregate = {
  __typename?: 'user_nft_tokens_aggregate';
  aggregate?: Maybe<User_Nft_Tokens_Aggregate_Fields>;
  nodes: Array<User_Nft_Tokens>;
};

/** aggregate fields of "user_nft_tokens" */
export type User_Nft_Tokens_Aggregate_Fields = {
  __typename?: 'user_nft_tokens_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Nft_Tokens_Max_Fields>;
  min?: Maybe<User_Nft_Tokens_Min_Fields>;
};

/** aggregate fields of "user_nft_tokens" */
export type User_Nft_Tokens_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Nft_Tokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_nft_tokens". All fields are combined with a logical 'AND'. */
export type User_Nft_Tokens_Bool_Exp = {
  _and?: InputMaybe<Array<User_Nft_Tokens_Bool_Exp>>;
  _not?: InputMaybe<User_Nft_Tokens_Bool_Exp>;
  _or?: InputMaybe<Array<User_Nft_Tokens_Bool_Exp>>;
  asset_org_url?: InputMaybe<String_Comparison_Exp>;
  asset_thumbnail_url?: InputMaybe<String_Comparison_Exp>;
  asset_type?: InputMaybe<String_Comparison_Exp>;
  asset_url?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  current_price?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  marketplace?: InputMaybe<String_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order_type?: InputMaybe<String_Comparison_Exp>;
  owned_by?: InputMaybe<Uuid_Comparison_Exp>;
  owner_address?: InputMaybe<String_Comparison_Exp>;
  sale_type?: InputMaybe<String_Comparison_Exp>;
  symbol?: InputMaybe<String_Comparison_Exp>;
  token_address?: InputMaybe<String_Comparison_Exp>;
  token_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
  visible?: InputMaybe<Boolean_Comparison_Exp>;
  wallet_address?: InputMaybe<String_Comparison_Exp>;
  wallet_provider_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type User_Nft_Tokens_Max_Fields = {
  __typename?: 'user_nft_tokens_max_fields';
  asset_org_url?: Maybe<Scalars['String']>;
  asset_thumbnail_url?: Maybe<Scalars['String']>;
  asset_type?: Maybe<Scalars['String']>;
  asset_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current_price?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  marketplace?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order_type?: Maybe<Scalars['String']>;
  owned_by?: Maybe<Scalars['uuid']>;
  owner_address?: Maybe<Scalars['String']>;
  sale_type?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  token_address?: Maybe<Scalars['String']>;
  token_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
  wallet_address?: Maybe<Scalars['String']>;
  wallet_provider_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Nft_Tokens_Min_Fields = {
  __typename?: 'user_nft_tokens_min_fields';
  asset_org_url?: Maybe<Scalars['String']>;
  asset_thumbnail_url?: Maybe<Scalars['String']>;
  asset_type?: Maybe<Scalars['String']>;
  asset_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current_price?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  marketplace?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order_type?: Maybe<Scalars['String']>;
  owned_by?: Maybe<Scalars['uuid']>;
  owner_address?: Maybe<Scalars['String']>;
  sale_type?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  token_address?: Maybe<Scalars['String']>;
  token_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
  wallet_address?: Maybe<Scalars['String']>;
  wallet_provider_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "user_nft_tokens". */
export type User_Nft_Tokens_Order_By = {
  asset_org_url?: InputMaybe<Order_By>;
  asset_thumbnail_url?: InputMaybe<Order_By>;
  asset_type?: InputMaybe<Order_By>;
  asset_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_price?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  marketplace?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order_type?: InputMaybe<Order_By>;
  owned_by?: InputMaybe<Order_By>;
  owner_address?: InputMaybe<Order_By>;
  sale_type?: InputMaybe<Order_By>;
  symbol?: InputMaybe<Order_By>;
  token_address?: InputMaybe<Order_By>;
  token_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
  visible?: InputMaybe<Order_By>;
  wallet_address?: InputMaybe<Order_By>;
  wallet_provider_id?: InputMaybe<Order_By>;
};

/** select columns of table "user_nft_tokens" */
export enum User_Nft_Tokens_Select_Column {
  /** column name */
  AssetOrgUrl = 'asset_org_url',
  /** column name */
  AssetThumbnailUrl = 'asset_thumbnail_url',
  /** column name */
  AssetType = 'asset_type',
  /** column name */
  AssetUrl = 'asset_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentPrice = 'current_price',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Marketplace = 'marketplace',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  OrderType = 'order_type',
  /** column name */
  OwnedBy = 'owned_by',
  /** column name */
  OwnerAddress = 'owner_address',
  /** column name */
  SaleType = 'sale_type',
  /** column name */
  Symbol = 'symbol',
  /** column name */
  TokenAddress = 'token_address',
  /** column name */
  TokenId = 'token_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username',
  /** column name */
  Visible = 'visible',
  /** column name */
  WalletAddress = 'wallet_address',
  /** column name */
  WalletProviderId = 'wallet_provider_id',
}

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  /** An object relationship */
  account: Accounts;
  /** An object relationship */
  avatar_object?: Maybe<Storage_Objects>;
  avatar_object_id?: Maybe<Scalars['uuid']>;
  bio?: Maybe<Scalars['String']>;
  /** An object relationship */
  cover_object?: Maybe<Storage_Objects>;
  cover_object_id?: Maybe<Scalars['uuid']>;
  display_name: Scalars['String'];
  /** An object relationship */
  enum_store_theme: Enum_Store_Theme;
  id: Scalars['uuid'];
  metadata?: Maybe<Scalars['jsonb']>;
  store_theme: Enum_Store_Theme_Enum;
  username?: Maybe<Scalars['String']>;
};

/** columns and relationships of "users" */
export type UsersMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Max_Order_By>;
  min?: InputMaybe<Users_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Users_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  avatar_object?: InputMaybe<Storage_Objects_Bool_Exp>;
  avatar_object_id?: InputMaybe<Uuid_Comparison_Exp>;
  bio?: InputMaybe<String_Comparison_Exp>;
  cover_object?: InputMaybe<Storage_Objects_Bool_Exp>;
  cover_object_id?: InputMaybe<Uuid_Comparison_Exp>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  enum_store_theme?: InputMaybe<Enum_Store_Theme_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  store_theme?: InputMaybe<Enum_Store_Theme_Enum_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey',
  /** unique or primary key constraint on columns "username" */
  UsersUsernameKey = 'users_username_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Users_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Users_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Users_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  avatar_object?: InputMaybe<Storage_Objects_Obj_Rel_Insert_Input>;
  avatar_object_id?: InputMaybe<Scalars['uuid']>;
  bio?: InputMaybe<Scalars['String']>;
  cover_object?: InputMaybe<Storage_Objects_Obj_Rel_Insert_Input>;
  cover_object_id?: InputMaybe<Scalars['uuid']>;
  display_name?: InputMaybe<Scalars['String']>;
  enum_store_theme?: InputMaybe<Enum_Store_Theme_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  store_theme?: InputMaybe<Enum_Store_Theme_Enum>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  avatar_object_id?: Maybe<Scalars['uuid']>;
  bio?: Maybe<Scalars['String']>;
  cover_object_id?: Maybe<Scalars['uuid']>;
  display_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  username?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  avatar_object_id?: InputMaybe<Order_By>;
  bio?: InputMaybe<Order_By>;
  cover_object_id?: InputMaybe<Order_By>;
  display_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  avatar_object_id?: Maybe<Scalars['uuid']>;
  bio?: Maybe<Scalars['String']>;
  cover_object_id?: Maybe<Scalars['uuid']>;
  display_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  username?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  avatar_object_id?: InputMaybe<Order_By>;
  bio?: InputMaybe<Order_By>;
  cover_object_id?: InputMaybe<Order_By>;
  display_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  avatar_object?: InputMaybe<Storage_Objects_Order_By>;
  avatar_object_id?: InputMaybe<Order_By>;
  bio?: InputMaybe<Order_By>;
  cover_object?: InputMaybe<Storage_Objects_Order_By>;
  cover_object_id?: InputMaybe<Order_By>;
  display_name?: InputMaybe<Order_By>;
  enum_store_theme?: InputMaybe<Enum_Store_Theme_Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  store_theme?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Users_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  AvatarObjectId = 'avatar_object_id',
  /** column name */
  Bio = 'bio',
  /** column name */
  CoverObjectId = 'cover_object_id',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  StoreTheme = 'store_theme',
  /** column name */
  Username = 'username',
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  avatar_object_id?: InputMaybe<Scalars['uuid']>;
  bio?: InputMaybe<Scalars['String']>;
  cover_object_id?: InputMaybe<Scalars['uuid']>;
  display_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  store_theme?: InputMaybe<Enum_Store_Theme_Enum>;
  username?: InputMaybe<Scalars['String']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  AvatarObjectId = 'avatar_object_id',
  /** column name */
  Bio = 'bio',
  /** column name */
  CoverObjectId = 'cover_object_id',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  StoreTheme = 'store_theme',
  /** column name */
  Username = 'username',
}

export type Uuid_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _cast?: InputMaybe<Uuid_Cast_Exp>;
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type Verify_Session_Input = {
  account_address: Scalars['String'];
  session_id: Scalars['String'];
};

export type Verify_Session_Output = {
  __typename?: 'verify_session_output';
  account_id: Scalars['String'];
  jwt_token: Scalars['String'];
};

/** columns and relationships of "wallet_providers" */
export type Wallet_Providers = {
  __typename?: 'wallet_providers';
  /** An object relationship */
  account: Accounts;
  /** An object relationship */
  account_provider?: Maybe<Account_Providers>;
  account_provider_id?: Maybe<Scalars['uuid']>;
  blockchain_name: Enum_Blockchain_Name_Enum;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  enum_blockchain_name: Enum_Blockchain_Name;
  id: Scalars['uuid'];
  metadata?: Maybe<Scalars['jsonb']>;
  owned_by: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  user_linked_nft_tokens: Array<User_Linked_Nft_Token>;
  /** An aggregate relationship */
  user_linked_nft_tokens_aggregate: User_Linked_Nft_Token_Aggregate;
  wallet_address: Scalars['String'];
};

/** columns and relationships of "wallet_providers" */
export type Wallet_ProvidersMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "wallet_providers" */
export type Wallet_ProvidersUser_Linked_Nft_TokensArgs = {
  distinct_on?: InputMaybe<Array<User_Linked_Nft_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Linked_Nft_Token_Order_By>>;
  where?: InputMaybe<User_Linked_Nft_Token_Bool_Exp>;
};

/** columns and relationships of "wallet_providers" */
export type Wallet_ProvidersUser_Linked_Nft_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Linked_Nft_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Linked_Nft_Token_Order_By>>;
  where?: InputMaybe<User_Linked_Nft_Token_Bool_Exp>;
};

/** aggregated selection of "wallet_providers" */
export type Wallet_Providers_Aggregate = {
  __typename?: 'wallet_providers_aggregate';
  aggregate?: Maybe<Wallet_Providers_Aggregate_Fields>;
  nodes: Array<Wallet_Providers>;
};

/** aggregate fields of "wallet_providers" */
export type Wallet_Providers_Aggregate_Fields = {
  __typename?: 'wallet_providers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Wallet_Providers_Max_Fields>;
  min?: Maybe<Wallet_Providers_Min_Fields>;
};

/** aggregate fields of "wallet_providers" */
export type Wallet_Providers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wallet_Providers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "wallet_providers" */
export type Wallet_Providers_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Wallet_Providers_Max_Order_By>;
  min?: InputMaybe<Wallet_Providers_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Wallet_Providers_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "wallet_providers" */
export type Wallet_Providers_Arr_Rel_Insert_Input = {
  data: Array<Wallet_Providers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Wallet_Providers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "wallet_providers". All fields are combined with a logical 'AND'. */
export type Wallet_Providers_Bool_Exp = {
  _and?: InputMaybe<Array<Wallet_Providers_Bool_Exp>>;
  _not?: InputMaybe<Wallet_Providers_Bool_Exp>;
  _or?: InputMaybe<Array<Wallet_Providers_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  account_provider?: InputMaybe<Account_Providers_Bool_Exp>;
  account_provider_id?: InputMaybe<Uuid_Comparison_Exp>;
  blockchain_name?: InputMaybe<Enum_Blockchain_Name_Enum_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  enum_blockchain_name?: InputMaybe<Enum_Blockchain_Name_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  owned_by?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_linked_nft_tokens?: InputMaybe<User_Linked_Nft_Token_Bool_Exp>;
  wallet_address?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "wallet_providers" */
export enum Wallet_Providers_Constraint {
  /** unique or primary key constraint on columns "owned_by", "wallet_address" */
  WalletProvidersOwnedByWalletAddressKey = 'wallet_providers_owned_by_wallet_address_key',
  /** unique or primary key constraint on columns "id" */
  WalletProvidersPkey = 'wallet_providers_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Wallet_Providers_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Wallet_Providers_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Wallet_Providers_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "wallet_providers" */
export type Wallet_Providers_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  account_provider?: InputMaybe<Account_Providers_Obj_Rel_Insert_Input>;
  account_provider_id?: InputMaybe<Scalars['uuid']>;
  blockchain_name?: InputMaybe<Enum_Blockchain_Name_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  enum_blockchain_name?: InputMaybe<Enum_Blockchain_Name_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  owned_by?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_linked_nft_tokens?: InputMaybe<User_Linked_Nft_Token_Arr_Rel_Insert_Input>;
  wallet_address?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Wallet_Providers_Max_Fields = {
  __typename?: 'wallet_providers_max_fields';
  account_provider_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  owned_by?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wallet_address?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "wallet_providers" */
export type Wallet_Providers_Max_Order_By = {
  account_provider_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owned_by?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  wallet_address?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Wallet_Providers_Min_Fields = {
  __typename?: 'wallet_providers_min_fields';
  account_provider_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  owned_by?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wallet_address?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "wallet_providers" */
export type Wallet_Providers_Min_Order_By = {
  account_provider_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owned_by?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  wallet_address?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "wallet_providers" */
export type Wallet_Providers_Mutation_Response = {
  __typename?: 'wallet_providers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wallet_Providers>;
};

/** input type for inserting object relation for remote table "wallet_providers" */
export type Wallet_Providers_Obj_Rel_Insert_Input = {
  data: Wallet_Providers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Wallet_Providers_On_Conflict>;
};

/** on_conflict condition type for table "wallet_providers" */
export type Wallet_Providers_On_Conflict = {
  constraint: Wallet_Providers_Constraint;
  update_columns?: Array<Wallet_Providers_Update_Column>;
  where?: InputMaybe<Wallet_Providers_Bool_Exp>;
};

/** Ordering options when selecting data from "wallet_providers". */
export type Wallet_Providers_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  account_provider?: InputMaybe<Account_Providers_Order_By>;
  account_provider_id?: InputMaybe<Order_By>;
  blockchain_name?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  enum_blockchain_name?: InputMaybe<Enum_Blockchain_Name_Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  owned_by?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_linked_nft_tokens_aggregate?: InputMaybe<User_Linked_Nft_Token_Aggregate_Order_By>;
  wallet_address?: InputMaybe<Order_By>;
};

/** primary key columns input for table: wallet_providers */
export type Wallet_Providers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Wallet_Providers_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "wallet_providers" */
export enum Wallet_Providers_Select_Column {
  /** column name */
  AccountProviderId = 'account_provider_id',
  /** column name */
  BlockchainName = 'blockchain_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  OwnedBy = 'owned_by',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WalletAddress = 'wallet_address',
}

/** input type for updating data in table "wallet_providers" */
export type Wallet_Providers_Set_Input = {
  account_provider_id?: InputMaybe<Scalars['uuid']>;
  blockchain_name?: InputMaybe<Enum_Blockchain_Name_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  owned_by?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  wallet_address?: InputMaybe<Scalars['String']>;
};

/** update columns of table "wallet_providers" */
export enum Wallet_Providers_Update_Column {
  /** column name */
  AccountProviderId = 'account_provider_id',
  /** column name */
  BlockchainName = 'blockchain_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  OwnedBy = 'owned_by',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WalletAddress = 'wallet_address',
}

export type GetUserNftsQueryVariables = Types.Exact<{
  username: Types.Scalars['String'];
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  token_address?: Types.InputMaybe<Types.Order_By>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;

export type GetUserNftsQuery = {
  __typename?: 'query_root';
  user_nft_tokens: Array<{
    __typename?: 'user_nft_tokens';
    asset_org_url?: string | null;
    asset_thumbnail_url?: string | null;
    asset_type?: string | null;
    asset_url?: string | null;
    current_price?: string | null;
    description?: string | null;
    id?: any | null;
    marketplace?: string | null;
    name?: string | null;
    metadata?: any | null;
    owned_by?: any | null;
    owner_address?: string | null;
    sale_type?: string | null;
    symbol?: string | null;
    token_address?: string | null;
    token_id?: string | null;
    visible?: boolean | null;
    wallet_address?: string | null;
    order_type?: string | null;
    wallet_provider_id?: any | null;
  }>;
};

export type GetUserNftsByOwnerIdQueryVariables = Types.Exact<{
  owned_by: Types.Scalars['uuid'];
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  token_address?: Types.InputMaybe<Types.Order_By>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;

export type GetUserNftsByOwnerIdQuery = {
  __typename?: 'query_root';
  user_nft_tokens: Array<{
    __typename?: 'user_nft_tokens';
    asset_org_url?: string | null;
    asset_thumbnail_url?: string | null;
    asset_type?: string | null;
    asset_url?: string | null;
    current_price?: string | null;
    description?: string | null;
    id?: any | null;
    marketplace?: string | null;
    name?: string | null;
    metadata?: any | null;
    owned_by?: any | null;
    owner_address?: string | null;
    sale_type?: string | null;
    symbol?: string | null;
    token_address?: string | null;
    token_id?: string | null;
    visible?: boolean | null;
    wallet_address?: string | null;
    order_type?: string | null;
    wallet_provider_id?: any | null;
  }>;
};

export type GetNftTokensCreatedSubscriptionVariables = Types.Exact<{
  username?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type GetNftTokensCreatedSubscription = {
  __typename?: 'subscription_root';
  created_nft_tokens: Array<{
    __typename?: 'created_nft_tokens';
    asset_org_url?: string | null;
    asset_thumbnail_url?: string | null;
    asset_type?: string | null;
    asset_url?: string | null;
    current_price?: string | null;
    description?: string | null;
    id?: any | null;
    marketplace?: string | null;
    name?: string | null;
    metadata?: any | null;
    owned_by?: any | null;
    owner_address?: string | null;
    symbol?: string | null;
    token_address?: string | null;
    token_id?: string | null;
    visible?: boolean | null;
    wallet_address?: string | null;
    order_type?: string | null;
    wallet_provider_id?: any | null;
    sale_type?: string | null;
  }>;
};

export type GetNftTokensCreatedVisibleAggregateSubscriptionVariables =
  Types.Exact<{
    username?: Types.InputMaybe<Types.Scalars['String']>;
  }>;

export type GetNftTokensCreatedVisibleAggregateSubscription = {
  __typename?: 'subscription_root';
  created_nft_tokens_aggregate: {
    __typename?: 'created_nft_tokens_aggregate';
    aggregate?: {
      __typename?: 'created_nft_tokens_aggregate_fields';
      count: number;
    } | null;
  };
};

export type RefreshCreatedNftMutationVariables = Types.Exact<{
  username: Types.Scalars['String'];
}>;

export type RefreshCreatedNftMutation = {
  __typename?: 'mutation_root';
  refresh_created_nfts?: {
    __typename?: 'RefreshCreatedNFTOutput';
    status?: boolean | null;
  } | null;
};

export type SaveUserLinkedNftTokenMutationVariables = Types.Exact<{
  object: Types.User_Linked_Nft_Token_Insert_Input;
}>;

export type SaveUserLinkedNftTokenMutation = {
  __typename?: 'mutation_root';
  insert_user_linked_nft_token_one?: {
    __typename?: 'user_linked_nft_token';
    id: any;
    visible: boolean;
  } | null;
};

export const GetUserNftsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserNfts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'username' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '0' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token_address' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'order_by' },
          },
          defaultValue: { kind: 'EnumValue', value: 'asc' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '50' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_nft_tokens' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'username' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'username' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'token_address' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'token_address' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'asset_org_url' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'asset_thumbnail_url' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'asset_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'asset_url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'current_price' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'marketplace' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
                { kind: 'Field', name: { kind: 'Name', value: 'owned_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'owner_address' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sale_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'token_address' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'token_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'visible' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'wallet_address' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'order_type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'wallet_provider_id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetUserNftsQuery__
 *
 * To run a query within a React component, call `useGetUserNftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserNftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserNftsQuery({
 *   variables: {
 *      username: // value for 'username'
 *      offset: // value for 'offset'
 *      token_address: // value for 'token_address'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetUserNftsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserNftsQuery,
    GetUserNftsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserNftsQuery, GetUserNftsQueryVariables>(
    GetUserNftsDocument,
    options
  );
}
export function useGetUserNftsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserNftsQuery,
    GetUserNftsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserNftsQuery, GetUserNftsQueryVariables>(
    GetUserNftsDocument,
    options
  );
}
export type GetUserNftsQueryHookResult = ReturnType<typeof useGetUserNftsQuery>;
export type GetUserNftsLazyQueryHookResult = ReturnType<
  typeof useGetUserNftsLazyQuery
>;
export type GetUserNftsQueryResult = Apollo.QueryResult<
  GetUserNftsQuery,
  GetUserNftsQueryVariables
>;
export const GetUserNftsByOwnerIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserNftsByOwnerId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'owned_by' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '0' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token_address' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'order_by' },
          },
          defaultValue: { kind: 'EnumValue', value: 'asc' },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '50' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_nft_tokens' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'owned_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'owned_by' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'token_address' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'token_address' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'asset_org_url' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'asset_thumbnail_url' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'asset_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'asset_url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'current_price' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'marketplace' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
                { kind: 'Field', name: { kind: 'Name', value: 'owned_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'owner_address' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sale_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'token_address' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'token_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'visible' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'wallet_address' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'order_type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'wallet_provider_id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetUserNftsByOwnerIdQuery__
 *
 * To run a query within a React component, call `useGetUserNftsByOwnerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserNftsByOwnerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserNftsByOwnerIdQuery({
 *   variables: {
 *      owned_by: // value for 'owned_by'
 *      offset: // value for 'offset'
 *      token_address: // value for 'token_address'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetUserNftsByOwnerIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserNftsByOwnerIdQuery,
    GetUserNftsByOwnerIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserNftsByOwnerIdQuery,
    GetUserNftsByOwnerIdQueryVariables
  >(GetUserNftsByOwnerIdDocument, options);
}
export function useGetUserNftsByOwnerIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserNftsByOwnerIdQuery,
    GetUserNftsByOwnerIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserNftsByOwnerIdQuery,
    GetUserNftsByOwnerIdQueryVariables
  >(GetUserNftsByOwnerIdDocument, options);
}
export type GetUserNftsByOwnerIdQueryHookResult = ReturnType<
  typeof useGetUserNftsByOwnerIdQuery
>;
export type GetUserNftsByOwnerIdLazyQueryHookResult = ReturnType<
  typeof useGetUserNftsByOwnerIdLazyQuery
>;
export type GetUserNftsByOwnerIdQueryResult = Apollo.QueryResult<
  GetUserNftsByOwnerIdQuery,
  GetUserNftsByOwnerIdQueryVariables
>;
export const GetNftTokensCreatedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getNftTokensCreated' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'username' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'created_nft_tokens' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'username' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'username' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'token_address' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'asset_org_url' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'asset_thumbnail_url' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'asset_type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'asset_url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'current_price' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'marketplace' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'metadata' } },
                { kind: 'Field', name: { kind: 'Name', value: 'owned_by' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'owner_address' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'symbol' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'token_address' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'token_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'visible' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'wallet_address' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'order_type' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'sale_type' },
                  name: { kind: 'Name', value: 'sale_kind' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'wallet_provider_id' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetNftTokensCreatedSubscription__
 *
 * To run a query within a React component, call `useGetNftTokensCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetNftTokensCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNftTokensCreatedSubscription({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetNftTokensCreatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetNftTokensCreatedSubscription,
    GetNftTokensCreatedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetNftTokensCreatedSubscription,
    GetNftTokensCreatedSubscriptionVariables
  >(GetNftTokensCreatedDocument, options);
}
export type GetNftTokensCreatedSubscriptionHookResult = ReturnType<
  typeof useGetNftTokensCreatedSubscription
>;
export type GetNftTokensCreatedSubscriptionResult =
  Apollo.SubscriptionResult<GetNftTokensCreatedSubscription>;
export const GetNftTokensCreatedVisibleAggregateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'getNftTokensCreatedVisibleAggregate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'username' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'created_nft_tokens_aggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'username' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'username' },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'visible' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetNftTokensCreatedVisibleAggregateSubscription__
 *
 * To run a query within a React component, call `useGetNftTokensCreatedVisibleAggregateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetNftTokensCreatedVisibleAggregateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNftTokensCreatedVisibleAggregateSubscription({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetNftTokensCreatedVisibleAggregateSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetNftTokensCreatedVisibleAggregateSubscription,
    GetNftTokensCreatedVisibleAggregateSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetNftTokensCreatedVisibleAggregateSubscription,
    GetNftTokensCreatedVisibleAggregateSubscriptionVariables
  >(GetNftTokensCreatedVisibleAggregateDocument, options);
}
export type GetNftTokensCreatedVisibleAggregateSubscriptionHookResult =
  ReturnType<typeof useGetNftTokensCreatedVisibleAggregateSubscription>;
export type GetNftTokensCreatedVisibleAggregateSubscriptionResult =
  Apollo.SubscriptionResult<GetNftTokensCreatedVisibleAggregateSubscription>;
export const RefreshCreatedNftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'refreshCreatedNFT' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'username' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'refresh_created_nfts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'username' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'username' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type RefreshCreatedNftMutationFn = Apollo.MutationFunction<
  RefreshCreatedNftMutation,
  RefreshCreatedNftMutationVariables
>;

/**
 * __useRefreshCreatedNftMutation__
 *
 * To run a mutation, you first call `useRefreshCreatedNftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshCreatedNftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshCreatedNftMutation, { data, loading, error }] = useRefreshCreatedNftMutation({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useRefreshCreatedNftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshCreatedNftMutation,
    RefreshCreatedNftMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefreshCreatedNftMutation,
    RefreshCreatedNftMutationVariables
  >(RefreshCreatedNftDocument, options);
}
export type RefreshCreatedNftMutationHookResult = ReturnType<
  typeof useRefreshCreatedNftMutation
>;
export type RefreshCreatedNftMutationResult =
  Apollo.MutationResult<RefreshCreatedNftMutation>;
export type RefreshCreatedNftMutationOptions = Apollo.BaseMutationOptions<
  RefreshCreatedNftMutation,
  RefreshCreatedNftMutationVariables
>;
export const SaveUserLinkedNftTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'saveUserLinkedNftToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'object' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'user_linked_nft_token_insert_input',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_user_linked_nft_token_one' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'object' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'object' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'on_conflict' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'constraint' },
                      value: {
                        kind: 'EnumValue',
                        value:
                          'user_linked_nft_token_token_id_token_address_wallet_provider_id',
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'update_columns' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'EnumValue', value: 'visible' }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'visible' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SaveUserLinkedNftTokenMutationFn = Apollo.MutationFunction<
  SaveUserLinkedNftTokenMutation,
  SaveUserLinkedNftTokenMutationVariables
>;

/**
 * __useSaveUserLinkedNftTokenMutation__
 *
 * To run a mutation, you first call `useSaveUserLinkedNftTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserLinkedNftTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserLinkedNftTokenMutation, { data, loading, error }] = useSaveUserLinkedNftTokenMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useSaveUserLinkedNftTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveUserLinkedNftTokenMutation,
    SaveUserLinkedNftTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveUserLinkedNftTokenMutation,
    SaveUserLinkedNftTokenMutationVariables
  >(SaveUserLinkedNftTokenDocument, options);
}
export type SaveUserLinkedNftTokenMutationHookResult = ReturnType<
  typeof useSaveUserLinkedNftTokenMutation
>;
export type SaveUserLinkedNftTokenMutationResult =
  Apollo.MutationResult<SaveUserLinkedNftTokenMutation>;
export type SaveUserLinkedNftTokenMutationOptions = Apollo.BaseMutationOptions<
  SaveUserLinkedNftTokenMutation,
  SaveUserLinkedNftTokenMutationVariables
>;
