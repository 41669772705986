import { Badge, Button, Typography } from 'antd';
import { SetStateAction } from 'react';
import { NFT } from '../../../../../types';
import { useStore } from '../../../../contexts/store.context';
import { getEllipsisTxt } from '../../../../helpers/formatter.helper';
import { EthereumLogo } from '../../../icons/chain-icons';
import { NFTViewer } from '../../nft-viewer';

export interface NFTDetailsViewProps {
  setCurrentStep: (value: SetStateAction<number>) => void;
  nft: NFT;
}

export function NFTDetailsView({ setCurrentStep, nft }: NFTDetailsViewProps) {
  const { usdPrice } = useStore();
  const price = parseInt(nft?.current_price as string) / 1e18;
  return (
    <div className="flex flex-col items-center px-5 pt-5 gap-6">
      <Badge.Ribbon text={`${price} ETH`}>
        <NFTViewer nft={nft} width={286} height={300} />
      </Badge.Ribbon>
      <div className="flex flex-col justify-center items-center w-full">
        <Typography.Text className="text-lg font-medium">
          {nft.name}
        </Typography.Text>
        <div className="flex justify-between items-center w-full">
          <Typography.Text strong>Token Id:</Typography.Text>
          <Typography.Text strong>
            {(nft?.token_id as string)?.length > 15
              ? getEllipsisTxt(nft?.token_id as string, 10)
              : nft.token_id}
          </Typography.Text>
        </div>
        <div className="flex justify-between items-center w-full">
          <Typography.Text strong>Price</Typography.Text>
          <div className="flex justify-center items-center gap-1">
            <EthereumLogo className="w-3" />
            <Typography.Text className="font-medium">
              {price} ETH ($
              {String((usdPrice?.ethereum?.usd as number) * price).slice(0, 5)})
            </Typography.Text>
          </div>
        </div>
      </div>
      <div>
        <Button
          type="primary"
          size="large"
          className="rounded-md"
          onClick={() => setCurrentStep(1)}
        >
          Proceed to Checkout
        </Button>
      </div>
    </div>
  );
}
